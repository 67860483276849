<template>
  <div>
    <trieste-card title="Payment Details" :collapsable="true" :collapsed="collapsed">
      <div class="p-4">
        <el-form
          ref="paymentForm"
          :model="link"
          label-width="165px"
          label-position="left"
          :rules="rules.create_payment"
          :disabled="!canEditPayment"
        >
          <el-form-item
            label="Price"
            prop="price"
          >
            <el-input
              v-model="link.price"
            />
          </el-form-item>
          <el-form-item
            label="Currency"
            prop="price_currency"
          >
            <el-select v-model="link.price_currency" class="w-full">
              <el-option value="USD" label="USD" />
              <el-option value="EUR" label="EURO" />
              <el-option value="GBP" label="GBP" />
              <el-option value="CAD" label="CAD" />
            </el-select>
          </el-form-item>
          <el-form-item label="Transaction ID" :prop="canProcessPayment ? 'paypal_tx_id' : ''">
            <el-input type="text"  v-model="link.paypal_tx_id" :disabled="!canProcessPayment" @keyup.native="transIDKeyUp" @paste.native="transIDKeyUp" />
          </el-form-item>
          <el-form-item label="Payment date" :prop="canProcessPayment ? 'date_paid' : ''">
            <el-date-picker type="date" v-model="link.date_paid"  class="w-full" format="dd MMM yyyy" :clearable="false" value-format="yyyy-MM-dd" :disabled="!canProcessPayment" />
            <div class="inline-block ml-4">
              <el-button size="medium" type="primary" @click="setTimeToToday" v-if="canProcessPayment">Make today</el-button>
            </div>
          </el-form-item>
          <el-form-item label="Paypal account" prop="paypal_account">
            <el-input type="text"  v-model="link.paypal_account" />
          </el-form-item>
          <el-form-item
            label="Additional Instructions"
            prop="payment_additional_instructions"
          >
            <el-input
              v-model="link.payment_additional_instructions"
            />
          </el-form-item>
          <span class="text-xs font-medium text-gray-700 mb-4 block" v-if="false">
          A payment has not been made for this lead. You can make a payment by completing the form. Payments can only be made once per lead and
          are sent from PayPal immediately. Therefore, it is important that you double check information such as price and receiver address before completing the payment form.
          Once a payment has been made, you will only be able to update its status, date and transaction ID.
        </span>
        </el-form>
      </div>
      <template v-slot:footer>
        <div class="text-right">
          <el-button type="success" icon="el-icon-edit-outline" @click="updatePayment" size="small" v-if="canEditPayment">
            Save
          </el-button>
        </div>
      </template>
    </trieste-card>
  </div>
</template>
<script>
  import Moment from 'moment'
  export default {
    props: {
      value: {
        type: Object,
        default: () => {}
      },
      collapsed: {
        type: Boolean,
        default: false
      }
    },
    data() {
      const priceValidator = (rule, value, callback) => {
        if(!parseFloat(value)) {
          return callback(new Error('Please enter a valid price'))
        }
        return callback();
      };

      return {
        link: Object.assign({}, this.value, {
          DatePaid: this.value.date_paid ? Moment(this.value.date_paid).format('YYYY-MM-DD') : null
        }),
        isNew: !!!this.value.payment_status,
        new_payment: {
          address: null,
          price: null,
          site_link_id: this.value.id,
          currency: null,
          email_subject: null,
          message: null,
          personal_note: null
        },
        manual_payment: false,
        rules: {
          create_payment: {
            Price: [
              { validator: priceValidator, trigger: 'blur'}
            ],
            PayPalAccount: [
              { type: 'email', required: true, message: 'Please enter a valid paypal email'}
            ],
            PriceCurrency: [
              { required: true, message: 'Please select a currency'}
            ],
            PaymentStatus: [
              { required: true, message: 'Please select a currency'}
            ],
            PaypalTxId: [
              { required: true, message: 'Please select a TX ID'}
            ],
            DatePaid: [
              { required: true, message: 'Please enter a payment date'}
            ],
          },
        }
      }
    },
    computed: {
      canEditPayment () {
        return (!this.isNew && this.$store.getters['auth/hasPermission']('payment.update')) ||
        (this.isNew && this.$store.getters['auth/hasPermission']('payment.create'))
      },
      canProcessPayment() {
        return this.$store.getters['auth/hasPermission']('payment.process')
      }
    },
    methods: {
      createPayment() {
        this.$refs.paymentForm.validate(valid => {
          if(valid) {
            //console.log(valid)
            this.$confirm(`Are you sure you want to make this payment to ${this.link.paypal_account} for ${this.link.price}(${this.link.price_currency})? Once it is made it can't be reversed.`, 'Are you sure?')
              .then(() => {
                this.$http.post(`/v1/companies/${this.$store.state.company.company.id}/payment`, {
                  address: this.link.paypal_account,
                  price: this.link.price,
                  site_link_id: this.link.id,
                  currency: this.link.price_currency
                })
                  .then(() => {
                    this.$message.success('The payment has successfully been made.')
                    if(!this.manual_payment) {
                      this.link.payment_status = 'processing';
                    }
                    this.$emit('update:input');
                    this.$emit('on-update');
                    this.$refs.paymentForm.clearValidate();
                  })
                  .catch(e => {
                    console.error(e)
                    this.$message.error('An error occurred making the payment')
                  })
              })
              .catch(() => {})
          }
        })
      },
      updatePayment() {
        this.$refs.paymentForm.validate(valid => {
          if(valid) {
            this.$emit('input', this.link);
            this.$emit('update');
            this.$emit('on-update')
          }
        })
      },
      setTimeToToday() {
        this.link.date_paid = Moment().utc().local().format('YYYY-MM-DD')
      },
      transIDKeyUp() {
        const match = (this.link.paypal_tx_id || '').match(/https:\/\/(?:www\.)?paypal\.co.*?\/([A-Z0-9]+)/)
        if(match) this.link.paypal_tx_id = match[1]
      }
    },
    watch: {
      value(val) {
        this.link = Object.assign({}, val, {
          DatePaid: this.value.date_paid ? Moment(val.date_paid).format('YYYY-MM-DD') : null
        });
      }
    }
  }
</script>
