<template>
<div>
  <trieste-card title="Auto-followup" :collapsable="true" :collapsed="collapsed">
    <div class="w-full flex flex-wrap gap-3 p-4" v-loading="isLoading || loading">

      <div class="w-full" v-if="settings.has_reply">
        <el-alert  type="warning" :closable="false">
          Auto-followup is not enabled as you have received a reply
        </el-alert>
      </div>
      <div v-else class="w-full">
        <div>



          <div class="w-full" v-if="settings.enabled">

            <div class="flex mb-4 justify-end">
              <el-switch
                :disabled="settings.has_error"
                v-model="settings.enabled_for_link"
                active-text="Enabled"
                active-color="#13ce66"
                inactive-color="#ff4949"
                inactive-text='Disabled'>
              </el-switch>
            </div>



            <div v-if="settings.enabled_for_link">
              <el-alert
                v-if="settings.has_error"
                type="error" :closable="false">
                Auto-follow contains errors and is disabled. "{{ settings.error_message }}" Revisit the settings in the batch to fix.
              </el-alert>
              <div v-else-if="!settings.has_sent_initial_email">
                <el-alert type="error" :closable="false">
                  Auto-followup wont operate until an initial email has been sent.
                </el-alert>
              </div>
              <div v-else-if="!settings.has_contact">
                <el-alert type="error" :closable="false">
                  Auto-followup can't operate as no contact has been set.
                </el-alert>
              </div>
              <div v-else>
                <el-alert  type="info"  :closable="false">
                  Auto-followup is enabled and currently running! Sending emails manually before a reply may cause confusion.
                  Auto-followup email will be sent to <strong>{{ settings.contact_email }}</strong>
                  <span v-if="settings.contact_email_cc?.length > 0"> CC: {{ settings.contact_email_cc.join(', ')  }}</span>.
                </el-alert>
              </div>
            </div>

            <div v-if="!settings.enabled_for_link && settings.enabled">
              <el-alert  type="warning" :closable="false">
                Auto-followup has been disabled for this link.
              </el-alert>
            </div>

          </div>


          <div v-else>
            <el-alert  type="warning" :closable="false">
              Auto-followup is not enabled for this batch.
            </el-alert>
          </div>


          <div v-if="settings.enabled && settings.enabled_for_link">
            <div class="w-full mt-3">
              <table class="w-full border border-gray-200 rounded">
                <thead>
                <tr>
                  <th class="!text-xs p-1 w-20 text-left">Status</th>
                  <th class="!text-xs p-1 w-40 text-left">Link Status</th>
                  <th class="!text-xs p-1 text-left">Subject</th>
                  <th class="!text-xs w-20 text-left p-1">Date</th>
                  <th class="!text-xs w-20 p-1"></th>
                </tr>
                </thead>
                <tbody>
                <tr
                  :key="'schedule-' + i"
                  v-for="(x, i) of getSequenceMinusInitial">
                  <td class="p-1">
                    Waiting
                  </td>
                  <td class="p-1">
                    <span class="block w-40 truncate">{{ getCleanStatus(x?.send_status) }}</span>
                  </td>
                  <td class="p-1">
                    <strong
                      style="max-width: auto"
                      class="block truncate">{{ x.subject }}</strong>
                  </td>
                  <td class="p-1 text-xs leading-4">
              <span v-if="settings.has_sent_initial_email">
                   <span class="block">Not Sent</span>
              </span>
                    <span v-else>
                Initial email not sent
              </span>
                  </td>
                  <td class="p-1">
                    <el-button
                      @click="$emit('append', {subject: x.subject, body: x.body})"
                      type="primary" size="mini">Append</el-button>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

    </div>
  </trieste-card>
</div>
</template>

<script>

import status from "@/utils/link/status.json"

export default {
  name: "AutoResponderSettings",
  props: {
    loading: {
      type: Boolean,
      default(){
        return false
      }
    },
    value: {
      type: Object,
      default(){
        return {
          enabled: false
        }
      }
    },
    link: {
      type: Object,
      default(){
        return {}
      }
    }
  },
  computed: {
    getSequenceMinusInitial(){
      return this.settings.sequence.filter((x) => x.type !== 'initial')
    }
  },
  watch: {
    'settings.enabled_for_link'(){
      this.toggleLinkEnabled();
    }
  },
  methods: {
    getCleanStatus(value){
      let f = status.find((x) => x.value === value);
      return f?.label || value;
    },
    toggleLinkEnabled(){
      const vm = this;
      vm.isLoading = true;
      this.$http.put(`/v1/companies/${this.$store.state.company.company.id}/links/${this.link?.id}/auto-responder`, {
        enabled: this.settings.enabled_for_link
      }).then(() => {
        vm.isLoading = false;
        //todo: need to update link when a contact is added
        this.$message.success('Successfully created the contact form.');
        this.$emit('refresh')
      })
        .catch(() => {
          vm.isLoading = false;
        })
    }
  },
  data(){
    return {
      collapsed: false,
      isLoading: this.loading,
      settings: {
        sequence: this.value?.sequence && Array.isArray(this.value.sequence) ? this.value.sequence : [],
        contact_email: this.value?.contact_email  ? this.value.contact_email : null,
        contact_email_cc: this.value?.contact_email_cc && Array.isArray(this.value.contact_email_cc) ? this.value.contact_email_cc : [],
        has_contact: this.value?.has_contact  ? this.value.has_contact === true : false,
        has_sent_initial_email: this.value?.has_sent_initial_email ? this.value.has_sent_initial_email === true : false,
        enabled: this.value?.enabled ? this.value.enabled === true : false,
        error_message: this.value?.error_message ? this.value.error_message : null,
        has_error: this.value?.has_error ? this.value.has_error === true : false,
        has_reply: this.value?.has_reply ? this.value.has_reply === true : false,
        enabled_for_link:  this.value?.enabled_for_link ? this.value.enabled_for_link === true : false,
      }
    }
  }
}
</script>

<style scoped>

</style>
