<template>
  <div v-loading="loading">
    <div class="flex flex-row justify-end mb-2">
      <a href="/link-building/billing">
        <el-button size="mini" icon="el-icon-money" type="primary">Go to Billing</el-button>
      </a>
    </div>
    <div class="page-title">
      <h1>
        Link Building
      </h1>
      <div class="flex flex-col items-end">
        <div class="flex flex-row items-center">
          <span class="font-bold text-blue-900 mr-2">Client:</span>
          <client-selector v-model="$store.state.client.selected_billing_client" @change="setClientData" :billable="show_billable_clients_only" />
        </div>
        <div class="mt-2">
          <el-switch active-text="Show billable clients only" v-model="show_billable_clients_only" class="mr-4"/>
          <el-switch active-text="Show billable links only" v-model="show_billable_only" />
        </div>
      </div>
    </div>
    <el-tabs class="custom-tabs-v1" :key="tab_key" v-model="tab_name">
      <el-tab-pane name="approval" label="Links for approval" v-if="$store.getters['auth/hasPermission']('lead.read')">
        <links :show-title="false" :active-only="true" :is-billed="false" ref="links_approval"
               include-exchange-prices
               include-billing-history :custom-filters="filters.slice(0, -1)"
               custom-title="Links for approval" custom-item-type="links"
               :custom-fields="getCustomFields(true, '390px')" export-mode="LINKBUILDING"
               export-name="approved linkbuilding links" sort-store-name="links-billing-for-approval"
               :custom-params="{ billing: true, client_id: $store.state.client.selected_billing_client, billable_only: show_billable_only }"
               default-sort-prop="acquired_date" :allow-mass-lead-update="false" allow-selection
               :selected-rows.sync="for_approval_selection"
               :show-pagination-options="true"
        >
          <template v-slot:header v-if="for_approval_selection && for_approval_selection.length">
            <el-button type="success" icon="el-icon-check" size="mini"
                       @click="massUpdateLinkStatus('APPROVED')"></el-button>
            <el-button type="danger" icon="el-icon-close" size="mini"
                       @click="massUpdateLinkStatus('REJECTED')"></el-button>
            <el-button type="warning" icon="el-icon-video-pause" size="mini"
                       @click="massUpdateLinkStatus('IN_CORRECTION')"></el-button>
            <div class="mr-3"></div>
          </template>
          <template v-slot:custom-actions="props">
            <el-button type="success" icon="el-icon-check" size="mini" @click="updateLinkStatus(props.row, 'APPROVED')">
              Approve
            </el-button>
            <el-button type="danger" icon="el-icon-close" size="mini" @click="updateLinkStatus(props.row, 'REJECTED')">
              Reject
            </el-button>
            <el-button type="warning" icon="el-icon-video-pause" size="mini"
                       @click="updateLinkStatus(props.row, 'IN_CORRECTION')">Correction
            </el-button>
          </template>
        </links>
      </el-tab-pane>
      <el-tab-pane name="all" label="All links" v-if="$store.getters['auth/hasPermission']('lead.read')">
        <links :show-title="false" :active-only="true" ref="links_all_leads" custom-title="All links"
               include-exchange-prices include-billing-history
               custom-item-type="links" :custom-fields="getCustomFields(true, '400px')" :custom-filters="filters"
               export-mode="LINKBUILDING"
               export-name="all linkbuilding links" sort-store-name="links-linkbuilding-all"
               :custom-params="{ billing: true, client_id: $store.state.client.selected_billing_client, billable_only: show_billable_only }"
               default-sort-prop="acquired_date" :allow-mass-lead-update="false" :show-pagination-options="true">
          <template v-slot:custom-actions="props">
            <div v-if="!['POSTPONED', 'BILLED'].includes(props.row.billing_status)">
              <el-button type="success" icon="el-icon-check" size="mini" @click="updateLinkStatus(props.row, 'APPROVED')" v-if="props.row.billing_status !== 'APPROVED'">
                Approve
              </el-button>
              <el-button type="danger" icon="el-icon-close" size="mini" @click="updateLinkStatus(props.row, 'REJECTED')" v-if="props.row.billing_status !== 'REJECTED'">
                Rejected
              </el-button>
              <el-button type="warning" icon="el-icon-video-pause" size="mini"
                         @click="updateLinkStatus(props.row, 'IN_CORRECTION')" v-if="props.row.billing_status !== 'IN_CORRECTION'">In. Corr
              </el-button>
              <el-button type="plain" icon="el-icon-video-pause" size="mini"
                         @click="updateLinkStatus(props.row, 'PENDING')">PENDING
              </el-button>
            </div>
          </template>
        </links>
      </el-tab-pane>
      <el-tab-pane name="correction" label="links for correction"
                   v-if="$store.getters['auth/hasPermission']('lead.read')">
        <links :show-title="false" :active-only="true" billing-status="IN_CORRECTION" ref="links_correction"
               custom-title="Links for correction" include-exchange-prices include-billing-history
               custom-item-type="links" :custom-fields="getCustomFields(true, '300px')" export-mode="LINKBUILDING"
               :custom-filters="filters.slice(0, -1)" sort-store-name="links-linkbuilding-correction"
               :allow-mass-lead-update="false"
               export-name="correction linkbuilding links"
               :custom-params="{ billing: true, client_id: $store.state.client.selected_billing_client, billable_only: show_billable_only }"
               default-sort-prop="acquired_date" :show-pagination-options="true">
          <template v-slot:custom-actions="props">
            <el-button type="success" icon="el-icon-check" size="mini" @click="updateLinkStatus(props.row, 'PENDING')">
              Corrected
            </el-button>
            <el-button type="danger" icon="el-icon-close" size="mini" @click="updateLinkStatus(props.row, 'REJECTED')">
              Can't Correct
            </el-button>
          </template>
        </links>
      </el-tab-pane>
      <el-tab-pane name="rejected" label="Rejected links" v-if="$store.getters['auth/hasPermission']('lead.read')">
        <links :show-title="false" :active-only="true" billing-status="REJECTED" ref="links_rejected"
               :custom-fields="getCustomFields(true, '300px')" include-exchange-prices include-billing-history
               custom-title="Rejected links" custom-item-type="links" export-mode="LINKBUILDING"
               export-name="rejected linkbuilding links" :allow-mass-lead-update="false"
               sort-store-name="links-linkbuilding-rejected" :custom-filters="filters.slice(0, -1)"
               :custom-params="{ billing: true, client_id: $store.state.client.selected_billing_client, billable_only: show_billable_only }"
               default-sort-prop="acquired_date" :show-pagination-options="true">
          <template v-slot:custom-actions="props">
            <el-button type="success" icon="el-icon-check" size="mini" @click="updateLinkStatus(props.row, 'APPROVED')">
              Approve
            </el-button>
            <el-button type="warning" icon="el-icon-video-pause" size="mini"
                       @click="updateLinkStatus(props.row, 'IN_CORRECTION')">Correction
            </el-button>
          </template>
        </links>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import ClientSelector from '@/components/views/client/Selector.vue';
import Links from "../Links/Index";

export default {
  mounted() {
    if (!this.$store.getters['auth/hasPermission']('linkbuilding.read')) {
      this.$message.error('You do not have permissions to view this resource.');
      this.$router.push('/');
    }
    this.$store.commit('global/setBreadcrumbs', [
      {
        path: '/',
        label: this.$store.state.company.company.name
      },
      {
        label: 'Link building'
      }
    ])
  },
  components: {Links, ClientSelector},
  data: function () {
    return {
      currentSubscriptionId: null,
      showWidget: false,
      loading: false,
      tab_key: 0,
      tab_name: "approval",
      for_approval_selection: [],
      show_billable_only: true,
      show_billable_clients_only: true,
      query: {
        rpp: 20,
        page: 1
      },
      base_fields: [
        {
          title: 'Acquired on',
          name: 'acquired_date',
          sortField: 'acquired_date',
          dateField: true
        },
        {
          title: 'URL',
          name: 'url-slot',
          sortField: 'from_url',
          width: '200px'
        },
        {
          title: 'Paypal Account',
          name: 'paypal-account-slot',
          sortField: 'paypal_account',
          width: '200px'
        },
        {
          title: 'Anchor Text',
          name: 'anchor-text',
          formatter: (c, v) => v ? (v?.link_monitoring?.target_anchor) : 'N/A',
          sortField: 'anchor_text'
        },
        {
          title: 'Anchor URL',
          name: 'anchor-url',
          width: '200px',
          urlField: true,
          sortField: 'anchor_url'
        },
        {
          title: 'TF',
          name: 'trust_flow',
          sortField: 'trust_flow',
          numberField: true
        },
        {
          title: 'CF',
          name: 'citation_flow',
          sortField: 'citation_flow'
        },
        {
          title: 'TF/CF',
          name: 'tf_cf',
          sortField: 'tf_cf',
          formatter(value) {
            return value ? value.toFixed(2) : 0
          }
        },
        {
          title: 'DR',
          name: 'ahrefs_domain_rating',
          sortField: 'ahrefs_domain_rating'
        },
        {
          title: 'A-traffic',
          name: 'ahref_traffic',
          formatter: (c, v) => Math.max(v.ahref_traffic, v.host_ahref_traffic, 0)
        },
        // {
        //   title: 'DA',
        //   name: 'domain_authority',
        //   sortField: 'domain_authority',
        // },

        // {
        //   title: 'S-TS US',
        //   name: 'semrush_traffic_score',
        //   numberField: true
        // },
        // {
        //   title: 'S-TS UK',
        //   name: 'semrush_traffic_score_uk',
        //   numberField: true
        // },
        // {
        //   title: 'S-TS Global',
        //   name: 'semrush_traffic_score_global',
        //   numberField: true
        // },
        {
          title: 'Type',
          name: 'link_type',
          formatter: (val) => val.replace(/_/g, ' ')
        },
        {
          title: 'Project',
          type: 'project',
          multiple: true,
          name: 'project',
          billable_only: true
        },
        {
          title: 'Client',
          name: 'Client'
        },
        {
          title: 'status',
          name: 'billing_status',
          formatter: (val) => (!val || val === 'FOR_REVIEW' ? "PENDING" : val).toLowerCase().replace(/_/g, ' ')
        },
        {
          title: 'price',
          formatter: (v, row) => row.price ? `${row.price} ${row.price_currency}` : 'N/A',
          sortField: 'price',
          name: 'price'
        },
        {
          title: 'ID',
          name: 'id',
          width: '150px'
        },
        {
          title: 'Site',
          name: 'Site'
        }

        // {
        //   title: 'Target',
        //   name: 'target-slot',
        //   sortField: 'site_target_id',
        // },
      ],
      filters: [
        {
          label: 'ID',
          name: 'id'
        },
        {
          label: 'Anchor Text',
          name: 'anchor_text'
        },
        {
          label: 'Paypal Account',
          name: 'paypal_account'
        },
        {
          label: 'Project',
          type: 'project',
          multiple: true,
          name: 'project',
          billable: true
        },
        {
          label: 'Anchor URL',
          name: 'anchor_url'
        },
        {
          label: 'Acquired on',
          name: 'acquired_on',
          type: 'date',
          dateType: 'daterange'
        },
        {
          label: 'Url'
        },
        // {
        //   label: 'Client',
        //   type: 'client',
        //   multiple: true
        // },
        {
          label: 'Site',
          type: 'site',
          multiple: true
        },
        {label: 'TF', type: 'number', max: 2},
        {label: 'CF', type: 'number', max: 2},
        {label: 'TF/CF', name: 'tf_cf', type: 'number', max: 2},
        {label: 'Ref Domains', name: 'ref_domains', type: 'number', max: 2},
        {label: 'DR', type: 'number', max: 2},
        {label: 'DA', type: 'number', max: 2},
        {label: 'Type', type: 'select', multiple: true, options: this.$store.state.link.types},
        {
          type: 'user',
          multiple: true,
          label: 'Owner',
          name: 'owner_id'
        },
        /*{
          type: 'user-group',
          multiple: true,
          label: 'User group',
          name: 'user_group'
        },*/
        {
          label: 'Batch date',
          name: 'created_at',
          type: 'date',
          dateType: 'daterange'
        },
        {
          label: 'Approved Date',
          name: 'built_date',
          type: 'date',
          dateType: 'daterange'
        },
        {
          label: 'Type',
          type: 'select',
          multiple: true,
          options: this.$store.state.link.types
        },
        {
          label: 'Billing State',
          name: 'billing_status',
          type: 'select',
          multiple: true,
          options: [
            {
              value: 'PENDING',
              label: 'Pending'
            },
            {
              value: 'APPROVED',
              label: 'Approved'
            },
            {
              value: 'REJECTED',
              label: 'Rejected'
            },
            {
              value: 'POSTPONED',
              label: 'Postponed'
            },
            {
              value: 'BILLED',
              label: 'Billed'
            },
            {
              value: 'IN_CORRECTION',
              label: 'In correction'
            },
            {
              value: 'FOR_REVIEW',
              label: 'For review'
            },
            {
              value: 'DEAD',
              label: 'Dead'
            }
          ]
        }
      ]
    }
  },
  computed: {
    getFilterCount() {
      let count = 0;
      Object.keys(this.search_params).forEach(k => {
        if (this.search_params[k].length > 0) {
          count++;
        }
      })
      return count;
    }
  },
  methods: {
    getCustomFields(hasActions, actionsWidth = '110px') {
      if (hasActions === true) {
        return [].concat(this.base_fields, {
          title: '',
          name: 'actions-slot',
          titleClass: 'text-left',
          dataClass: 'text-left',
          width: actionsWidth,
          fixed: 'right'
        })
      }

      return this.base_fields
    },
    massUpdateLinkStatus(status) {
      const total = this.for_approval_selection.length
      const action = (value) => {
        if (total) {
          this.loading = true;
          this.$http.put(`/v1/companies/${this.$store.state.company.company.id}/links/mass-update`, {
            ids: this.for_approval_selection.map(s => s.id),
            billing_status: status,
            billing_status_note: value
          }).then(() => {
            this.$message.success(`Successfully updated ${total} links to ${status.toLowerCase()}`)
            setTimeout(() => {
              this.refreshTables()
            }, 400)
          }).catch((e) => {
            this.$message.error(`Failed to update ${total} links to ${status.toLowerCase()}`)
            console.error(e)
          })
            .finally(() => {
              this.loading = false
            })
        }
      }

      if (status !== 'APPROVED') {
        this.$prompt('Please enter a note for this state change', `Why are you updating ${total} links to  ${status.toLowerCase().replace(/_/g, ' ')}`, {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel'
        }).then(({value}) => action(value)).catch(() => {
        });
      } else {
        action()
      }
    },
    updateLinkStatus(link, status) {
      const action = (value) => {
        this.loading = true;
        this.$http.put(`/v1/companies/${link.company_id}/links/${link.id}`, {
          billing_status: status,
          billing_status_note: value
        }).then(() => {
          this.$message.success('Successfully updated the link.');
          setTimeout(() => {
            this.refreshTables()
          }, 600)
        })
          .catch((e) => {
            this.$message.error('Failed to update the link. Please try again later.')
            console.error('the erorr', e)
          })
          .finally(() => {
            this.loading = false;
          })
      }

      if (status !== 'APPROVED') {
        this.$prompt('Please enter a note for this state change', 'Why are you updating this link to ' + status.toLowerCase().replace(/_/g, ' '), {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel'
        }).then(({value}) => action(value)).catch(() => {
        });
      } else {
        action()
      }
    },
    setClientData(val) {
      this.$store.commit('client/update', ['selected_billing_client', val])
    },
    refreshTables() {
      console.log('Hi', this.$refs, Object.values(this.$refs))
      for (const ref of Object.values(this.$refs)) {
        if(ref && typeof ref.refresh === 'function') {
          ref.refresh()
        }
      }
    }
  }
}
</script>
