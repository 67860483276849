<template>
  <div ref="top"
       class="flex flex-wrap w-full gap-2" v-loading="loadingEmails">
    <div class="w-full"  v-if="emails.length === 0" >
      <el-alert
        title="No emails have been found"
        type="warning"
        description="No emails have been sent or received for this site link."
        :closable="false"
        show-icon />
    </div>
    <div class="w-full">
      <trieste-card title="Communications" :collapsable="true" :collapsed="collapsed">
        <template v-slot:header-right>
          <div class="mr-1 inline-block">
            <el-select v-model="type" size="small" @change="fetchEmails(true)">
              <el-option value="all" label="All" />
              <el-option value="regular" label="Regular" />
              <el-option value="contact_form" label="Contact form" />
              <el-option value="automated" label="Automated" />
            </el-select>
          </div>
          <el-button
            size="small"
            icon="el-icon-s-promotion"
            type="primary"
            @click="sendEmail"
            v-if="showSendEmailButton"
          >
            Send email
          </el-button>
        </template>
        <div v-if="!loadingEmails && !emails.length">
          <p class="text-sm text-gray-500 p-4">
            No emails have been sent or received for this site link.
          </p>
        </div>
        <div class="block" v-loading="loadingEmails" ref="emailList">
          <el-collapse
            v-model="activeNames"
            class="block--emails"
            @change="openEmail"
          >
            <el-collapse-item
              title="Consistency"
              v-for="(e, i) in emails"
              :key="i"
              :name="e.id"
            >
              <template #title>
                <div class="flex-grow truncate w-full p-3 flex flex-row justify-between items-center w-full flex-wrap"
                     :class="{'is-on-behalf_container': isOnBehalfEmail(e)}">
                  <div>
                    <el-alert type="error" class="w-full" :closable="false" v-if="e.error">
                      {{ e.error }}
                    </el-alert>
                    <h5 class="py-2 font-bold truncate">
                      {{ e.subject }}
                    </h5>
                    <div class="text-xs">
                      <div class="" v-if="!e.contact_form">
                        {{ e.from }}
                        <template v-if="e.incoming > 0">
                          <span class="text-red-800 font-bold text-xs" v-if="e.contact_blacklisted">(This contact is blocked)</span>
                          <a href="#" v-else-if="allowBlock" class="text-red-800 font-bold text-xs" @click="blockEmail(e.from)">(block)</a>
                        </template>
                        -> {{ e.to }}
                        <template v-if="!e.incoming">
                          <span class="text-red-800 font-bold text-xs" v-if="e.contact_blacklisted">(This contact is blocked)</span>
                          <a href="#" v-else-if="allowBlock" class="text-red-800 font-bold text-xs" @click="blockEmail(e.to)">(block)</a>
                        </template>
                        <template v-if="e.cc && e.cc.length">
                          <template v-for="(cc, i) in e.cc">
                          <span  :key="i">, {{cc.email}}
                            <span class="text-red-800 font-bold text-xs" v-if="cc.blocked">(This contact is blocked)</span>
                            <a href="#" v-else-if="allowBlock" class="text-red-800 font-bold text-xs" @click="blockEmail(cc.email)">(block)</a>
                          </span>
                          </template>
                        </template>
                      </div>
                      <div class="text-gray-600 text-xs mt-1">
                        {{ e.date | momentLocal("ddd MMMM DD YYYY hh:mm A Z") }}
                      </div>
                      <div class="mt-2 mb-2">
                        <div v-if="e.contact_form" class="mr-1 inline-block">
                          <el-tag size="mini" type="primary" effect="dark">Contact Form</el-tag>
                        </div>
                        <el-tag size="mini" type="danger" effect="dark" v-if="e.incoming === -1">Failed!</el-tag>
                        <el-tag size="mini" :type="e.incoming > 0 ? 'success' :  'primary'" effect="dark" v-else>{{ e.incoming > 0 ? 'Incoming' : 'Outgoing' }}</el-tag>
                        <div class="ml-1 inline-block" v-if="e.attachment && e.attachment.length">
                          <el-tag size="mini" :type="e.incoming > 0 ? 'success' :  'primary'" effect="dark"><i class="el-icon-paperclip"></i></el-tag>
                        </div>
                        <div v-if="isOnBehalfEmail(e)" class="mt-2">
                          <el-tag size-="mini">
                            {{ e.user ? `${e.user.firstname} ${e.user.lastname}` : 'Unknown' }} replied on your behalf
                          </el-tag>
                        </div>
                        <div class="inline-block ml-1" v-if="e.incoming > 0 && !e.read"><el-tag size="mini" type="warning" effect="dark">New</el-tag></div>
                        <div v-if="e.incoming > 0" class="inline-block ml-2 underline">
                          <a href="#" @click.prevent="(ev) => { markAsReadOrUnread(e, ev) }">{{ e.read ? 'Mark as unread' : 'Mark as read' }}</a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-if="e.incoming > 0">
                    <el-button size="small" type="primary" @click.prevent="(a) => { setUpEmailReply(e, false, a) }">Reply</el-button>
                  </div>
                  <div v-else-if="!e.contact_form">
                    <el-button size="small" type="primary" @click.prevent="(a) => { setUpEmailReply(e, true, a) }">Retry</el-button>
                  </div>
                </div>
              </template>
              <div v-if="e.attachment && e.attachment.length" class="p-4 pb-0">
                <h5 class="py-2 font-bold truncate">
                  Attachments
                </h5>
                <el-button size="small" type="primary" v-for="(k, i) in getEmailAttachments(e)" :key="'email_attachment_' + i" @click="downloadAttachment(e, k.path)">
                  {{ niceEmailTitle(k.label) }}
                </el-button>
                <el-button size="small" type="primary"  @click="downloadAttachment(e)">
                  Download All
                </el-button>
              </div>
              <div v-if="hasBothTextAndHtmlContent(e.id)" class="p-4 text-right">
                View as: <el-switch inactive-text="text" active-text="html" v-model="e.show_html_version" size="small" />
              </div>
              <div class="p-3 pb-0 border-t border-gray-300 text-xs" v-html="renderEmailContent(e.id)" v-if="!hasBothTextAndHtmlContent(e.id)"></div>
              <div class="p-3 pb-0 border-t border-gray-300 text-xs" v-html="renderEmailContent(e.id, 2, 'text')" v-else-if="!e.show_html_version"></div>
              <div class="p-3 pb-0 border-t border-gray-300 text-xs" v-html="renderEmailContent(e.id, 2, 'html')" v-else></div>
            </el-collapse-item>
          </el-collapse>
          <div class="p-4 flex flex-row justify-between items-center" v-if="email_pagination.total > email_pagination.rpp">
            <small>Showing page {{email_pagination.page}} of {{email_pagination.last_page}}. Total results {{email_pagination.total}}</small>
            <el-pagination
              background
              layout="prev, pager, next"
              :total="email_pagination.total"
              :page-size.sync="email_pagination.rpp"
              :current-page="email_pagination.page"
              @current-change="emailPaginationChange"
            >
            </el-pagination>
          </div>
        </div >
      </trieste-card>
    </div>
  </div>
</template>
<script>
  const FileDownload = require('js-file-download');
  import { decode } from 'html-entities';
  export default {
    props: {
      id: {
        required: true,
        type: [String, Number]
      },
      showSendEmailButton: {
        type: Boolean,
        default: true
      },
      collapsed: {
        type: Boolean,
        default: false
      },
      allowBlock: {
        type: Boolean,
        default: true
      },
      ownerId: {
        type: Number,
        default: null
      }
    },
    data() {
      return {
        emails: [],
        firstload: true,
        loadingEmails: false,
        activeNames: ['1'],
        email_contents: {},
        email_pagination: {
          from: 1,
          last_page: 1,
          next_page_url: null,
          page: 1,
          prev_page_url: null,
          rpp: 20,
          to: 0,
          total: 0
        },
        email: {
          subject: null,
          from: null,
          to: null,
          in_reply_to: null,
          cc: []
        },
        type: 'all'
      }
    },
    mounted() {
      this.fetchEmails()
    },
    methods: {
      async fetchEmails(resetPage) {
        let vm = this;
        vm.loadingEmails = true;
        if(resetPage === true) {
          vm.email_pagination.page = 1;
        }
        vm.$http.get('/v1/companies/' + vm.$store.state.company.company.id + '/links/' + vm.id + '/emails', {
          params: {
            rpp: vm.email_pagination.rpp,
            page: vm.email_pagination.page,
            type: vm.type
          }
        })
          .then(async (response) => {
            vm.emails = response.data.data;
            vm.email_pagination = response.data.pagination;
            vm.loadingEmails = false;
            if(this.firstload) {
              await this.setDefaultToAndFrom();
              this.firstload = false;
            }
          })
          .catch((e) => {
            console.warn("e", e)
          })
      },
      async openEmail(data, callback) {
        const d = [].concat(data);
        d.shift()
        if(d.length > 0) {
          const notFoundEmailCalls = [];
          d.forEach(emailId => {
            if(!this.email_contents[emailId]) {
              const email = this.emails.find(i => i.id === emailId)
              notFoundEmailCalls.push(
                this.$http.get(`/v1/companies/${this.$store.state.company.company.id}/email-accounts/${email.email_account_id}/emails/${emailId}`)
              )
            }
          });
          if(notFoundEmailCalls.length > 0) {
            this.loadingEmails = true;
            this.$http.all(notFoundEmailCalls).then(this.$http.spread((...responses) => {
              responses.forEach((response) => {
                if(response.status === 200) {
                  this.email_contents[response.data.data.id] = {
                    htmlContent: response.data.data.htmlContent,
                    textContent: response.data.data.textContent,
                  }
                  this.emails.find(i => i.id === response.data.data.id).read = true;
                }
              })
            })).finally(() => {
              if(typeof callback === "function") {
                callback()
              }
              this.loadingEmails = false;
            })
          } else {
            if(typeof callback === "function") {
              callback()
            }
          }
        } else {
          if(typeof callback === "function") {
            callback()
          }
        }
        return true;
      },
      async setDefaultToAndFrom() {
        const nonContactFormEmails = this.emails.filter(e => !e.contact_form)
        //console.log('filtered', nonContactFormEmails)
        if(nonContactFormEmails.length) {
          const lastEmail = nonContactFormEmails[0];
          console.log('lastEmail', lastEmail)
          let to = lastEmail.incoming === true ? lastEmail.from : lastEmail.to
          const from = lastEmail.incoming === true ? lastEmail.to : lastEmail.from
          const lastReceivedEmail = nonContactFormEmails[0]
          //console.log('The last received email', lastReceivedEmail)
         // console.log(this.)
          if(lastReceivedEmail) {
            //to = lastReceivedEmail.from
            await this.openEmail([null, lastReceivedEmail.id], () => {
              lastReceivedEmail.content = this.email_contents[lastReceivedEmail.id].textContent || this.email_contents[lastReceivedEmail.id].htmlContent;

              this.$emit('default-to-and-from', to, from, lastReceivedEmail);
            })
          } else {
            this.$emit('default-to-and-from', to, from, null);
          }
        }
      },
      getDecodedEmailContent(id, force_type) {
        if(force_type === 'html') return this.email_contents[id].htmlContent
        if(force_type === 'text') return this.email_contents[id].textContent
        return decode(this.email_contents[id].textContent || this.email_contents[id].htmlContent || '')
      },
      hasBothTextAndHtmlContent(id) {
        return this.email_contents[id] && this.email_contents[id].htmlContent && this.email_contents[id].textContent
      },
      renderEmailContent(id, indication = 2, force_type) {
        if(this.email_contents[id]) {
          const content = this.getDecodedEmailContent(id, force_type)

          let reg_str = '[\\n|\\r|>|\\s]*'
          while(indication--) reg_str += '> '
          reg_str = reg_str.trimEnd()
          reg_str += ' on .*? wrote:'
          const limit = content.match(new RegExp(reg_str, 'i'))

          return (limit ? content.slice(0, limit.index) : content).replace(/\n/g, '<br />');
        }

        return '';
      },
      sendEmail() {
        this.$emit('send-email', true);
      },
      isOnBehalfEmail (e) {
        return this.ownerId && !e.incoming && e.user_id && e.user_id !== this.ownerId
      },
      emailPaginationChange(e) {
        this.email_pagination.page = e;
        this.fetchEmails();
        this.$scrollTo(this.$refs.top);
      },
      getEmailAttachments(email) {
        const emailId = email.id;
        const results = [];
        email.attachment.forEach(attachment => {
          results.push({
            label: attachment.substring(attachment.indexOf(emailId) + emailId.length + 1),
            path: attachment
          })
        })

        return results;
      },
      ResendEmail(e, a) {
        a.stopPropagation();
        a.preventDefault();
        console.error('The email', e)
        this.$confirm('Are you sure you want to resend this email?').then(() => {
          this.loadingEmails = true
          this.$http.post('/v1/companies/' + this.$store.state.company.company.id + '/resend-email', {
            email_id: e.id
          }).then(() => {
            this.$message.success('Successfully resent the email')
          })
          .catch(() => {
            this.$message.error('Failed to resend the email. Please try again later.')
          })
          .finally(() => {
            this.loadingEmails = false
          })
        }).catch(() => {})
      },
      async setUpEmailReply(email, isForward, a) {
        a.stopPropagation();
        a.preventDefault();
        // we are going to prepopulate the form for where the email show be going.
        const e = {}
        const is_failed = email.incoming === -1
        e.id = email.id
        e.is_failed = is_failed
        e.subject = is_failed ? email.subject : (isForward ? 'Fwd: ' : 'Re: ') + (email.subject || '');
        e.to = isForward ? email.to : email.from;
        e.from = email.email_account_id;
        e.from_email = email.from;
        e.in_reply_to = email.message_id;
        e.cc = [];
        e.date = email.date;
        if(email.cc && email.cc.length) {
          e.cc = email.cc;
        }

        //console.log('About to send', e)

        await this.openEmail([null, e.id], () => {
          e.content = is_failed ? this.getDecodedEmailContent(e.id).replace(/<br\s*\/?>/g, '\n') : this.renderEmailContent(e.id, 0);
          this.$emit('reply-to', e)
        })
      },
      markAsReadOrUnread(email, ev) {
        if(ev) {
          ev.stopPropagation();
          ev.preventDefault();
        }
        this.loadingEmails = true
        this.$http.put(`/v1/companies/${this.$store.state.company.company.id}/email-accounts/${email.email_account_id}/emails/${email.id}/read`, {
          read: !email.read
        }).then(() => {
          this.fetchEmails()
        })
        .finally(() => {
          this.loadingEmails = false
        })
      },
      niceEmailTitle(text) {
        let lm
        if((lm = text.match(/(legacy:\d+):(.*)/))) {
          return `${lm[2]} (${lm[1]})`
        }
        lm = text.match(/_(.*)/)
        return lm ? lm[1] : text
      },
      downloadAttachment(email, path) {
        const url = `/v1/companies/${this.$store.state.company.company.id}/email-accounts/${email.email_account_id}/emails/${email.id}/download`
        const options = {
          responseType: 'blob'
        };

        let downloadName = email.id + '.zip';
        if(path) {
          options.params = { path }
          downloadName = path.substring(path.indexOf(email.id) + email.id.length + 1)
        }
        this.loadingEmails = true;
        this.$http.get(url, options).then(r => {
          if(r.headers['content-disposition'] && r.headers['content-disposition'].indexOf('filename') > -1) {
            const m = r.headers['content-disposition'].match(/filename="?(.*?)"?$/)
            if(m[1]) {
              downloadName = m[1]
            }
          }

          FileDownload(r.data, downloadName)
        })
          .finally(() => {
            this.loadingEmails = false;
          })
        return true;
      },
      blockEmail(email) {
        this.$emit('block-email', email)
      }
    },
  }
</script>
<style lang="scss" scoped>
  .block--emails {
    ::v-deep {
      .el-collapse-item__header {
        line-height: 1rem;
        height: auto;
      }
    }
    .el-collapse-item__content {
      a {
        color: blue;
      }
    }
  }
  .el-select-dropdown__item {
    height: auto;
  }
  .is-on-behalf_container {
    border-left: 4px solid #2c5282;
  }
</style>
<style lang="scss">
.block--emails {
  .el-collapse-item__content {
    a {
      @apply text-blue-800 underline;
    }
  }
}
</style>
