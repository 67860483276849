export default class {
  constructor (mapping = {}, prefix = '<<<', suffix = '>>>') {
    this.prefix = prefix
    this.suffix = suffix
    this.mapping = mapping
    this.remove_not_found_tags = true
  }

  setMapping (mapping) {
    this.mapping = mapping
  }

  setPrefix (prefix) {
    this.prefix = prefix
  }

  setSuffix (suffix) {
    this.suffix = suffix
  }

  parse (content) {
    Object.keys(this.mapping).forEach(key => {
      content = this.match(content, key, this.mapping[key] ? this.replaceLineBreaks(this.mapping[key]) : '')
    })

    content = this.match(content, '.*?', '')
    return content
  }

  replaceLineBreaks (content) {
    return typeof content === 'string' ? content.replace(/\n/g, '<br/>') : content
  }

  parseIfStatments (content) {
    this.extractIfStatementBlocks(content).forEach(statment => {
      const statementParts = statment.match(/{%\s?if(.*?)\s?%}(.*?)(?:{%\s?else\s?%}(.*?))?{%\s?endif\s?%}/m)
      if (statementParts) {
        const fullReference = statementParts[0]
        const ifPart = statementParts[1].trim()
        const replacement = statementParts[2]
        const elsePart = statementParts[3] || ''

        // atm just support direct if entity exists. will come back for comparison alternatives
        if (this.mapping[ifPart]) {
          content = content.replace(fullReference, replacement)
        } else {
          content = content.replace(fullReference, elsePart)
        }
      }
    })

    return content
  }

  extractIfStatementBlocks (content) {
    return content.match(/{%\s?if(.*?)\s?%}(.*?){%\s?endif\s?%}/gm) || []
  }

  match (string, match, replacement) {
    const regex = `${this.prefix}${match}${this.suffix}`

    if (string.match(regex)) {
      const finalReplacement = replacement.match && replacement.match(/<<<.*?>>>/) ? this.parse(replacement) : replacement
      return string.replace(new RegExp(regex, 'g'), finalReplacement)
    }

    return string;
  }

  // Keep this for now, but they do not want html emails. Prefer regular content sent out.
  convertToHtml(content) {
    return content;
    // const text = `
    //   <mjml>
    //     <mj-body>
    //         <mj-section>
    //             <mj-column>
    //                 <mj-text>${content.split(/\n|<br\s?\/?>/).join('</mj-text><mj-text>')}</mj-text>
    //             </mj-column>
    //         </mj-section>
    //     </mj-body>
    // </mjml>
    // `
    // return (mjml(text)).html
  }

  convertHtmlToPlainText (html) {
    return (convert(html)).trim();
  }
}
