<template>
  <div>
    <div v-if="!invisible">
      <el-button type="success" size="mini" @click="openBulkForm" :loading="loading">{{count}} {{ getSuffix() }}</el-button>
    </div>
    <trieste-dialog :show.sync="show_bulk_form" title="Start Bulk Emailing" append-to-body width="500px">
      <div class="p-6 pb-2">
        <el-form :rules="form_rules" @change="formChanged" :model="form" label-width="120px" label-position="left" ref="bulkForm" v-loading="loading">
          <el-form-item label="status" prop="status">
            <el-select class="w-full" @change="formChanged" v-model="form.status" filterable multiple clearable>
              <el-option v-for="(o, i) in $store.state.link.statuses" :key="i" :value="o.value" :label="o.label" />
            </el-select>
          </el-form-item>
          <el-form-item label="Type" prop="type">
            <el-select class="w-full" @change="formChanged" v-model="form.type" filterable multiple clearable>
              <el-option v-for="(o, i) in $store.state.link.types" :key="i" :value="o.value" :label="o.label" />
            </el-select>
          </el-form-item>
          <el-form-item label="Projects" prop="project_ids" v-if="false">
            <el-select class="w-full" @change="formChanged" v-model="form.project_ids"  filterable multiple clearable>
              <el-option v-for="(o, i) in projects" :key="i" :value="o.id" :label="o.name"/>
            </el-select>
          </el-form-item>
          <el-form-item label="Clients" prop="client_ids" v-if="false">
            <el-select class="w-full" @change="formChanged" v-model="form.client_ids"  filterable multiple clearable>
              <el-option v-for="(o, i) in clients" :key="i" :value="o.id" :label="o.name"/>
            </el-select>
          </el-form-item>
          <el-form-item label="Sites" prop="site_ids">
            <el-select class="w-full" @change="formChanged" v-model="form.site_ids"  filterable multiple clearable>
              <el-option v-for="(o, i) in allowedSites" :key="i" :value="o.id" :label="o.domain"/>
            </el-select>
          </el-form-item>
          <el-form-item label="Targets" prop="target_ids">
            <el-select class="w-full" @change="formChanged" v-model="form.target_ids"  filterable multiple clearable>
              <el-option v-for="(o, i) in allowedTargets" :key="i" :value="o.id" :label="o.name"/>
            </el-select>
          </el-form-item>
          <el-form-item label="Campaigns" prop="campaign_ids" v-if="false">
            <el-select class="w-full" @change="formChanged" v-model="form.campaign_ids"  filterable multiple clearable>
              <el-option v-for="(o, i) in campaigns" :key="i" :value="o.id" :label="o.name"/>
            </el-select>
          </el-form-item>
          <el-form-item label="Batches" prop="batch_ids" v-if="false">
            <el-select class="w-full" @change="formChanged" v-model="form.batch_ids"  filterable multiple clearable>
              <el-option v-for="(o, i) in batches" :key="i" :value="o.id" :label="o.name"/>
            </el-select>
          </el-form-item>
          <el-form-item label="Email accounts" prop="email_account_ids">
            <el-select class="w-full" @change="formChanged" v-model="form.email_account_ids"  filterable multiple clearable>
              <el-option v-for="(o, i) in allowedEmailAccounts" :key="i" :value="o.id" :label="`${o.email}${o.status === 'failed' ? ' (failed)' : ''}`"/>
            </el-select>
          </el-form-item>
          <el-form-item label="Template" prop="template_id">
            <el-select class="w-full" v-model="form.template_id" filterable multiple>
              <el-option v-for="(v, i) in allowedTemplates" :label="v.name" :value="v.id" :key="i" />
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <template v-slot:footer-left>
        <el-button type="danger" @click="show_bulk_form = false" size="small">Close</el-button>
      </template>
      <template v-slot:footer-right>
        <el-button type="success" size="small" :loading="bulkConfiguration.loading" icon="el-icon-edit" @click="startBulk" :disabled="!bulkConfiguration.count || bulkConfiguration.loading">
          <span v-if="bulkConfiguration.count">{{ `Bulk email ${bulkConfiguration.count} links.` }}</span>
          <span v-else>No links to bulk email.</span>
        </el-button>
      </template>
    </trieste-dialog>
  </div>
</template>
<script>
export default {
  props: {
    clientId: {
      type: [String, Array],
      default: null
    },
    projectId: {
      type: [String, Array],
      default: null
    },
    invisible: {
      type: Boolean,
      default: false,
    },
    siteId: {
      type: [String, Array],
      default: null
    },
    campaignId: {
      type: [String, Array],
      default: null
    },
    targetId: {
      type: [String, Array],
      default: null
    },
    batchId: {
      type: [String, Array],
      default: null
    },
    status: {
      type: Array,
      default: () => ['waiting_for_initial_reply']
    },
    dateTo: {
      type: [String, Date],
      default: null
    },
    suffix: {
      type: String,
      default: null
    },
    defaultSite: {
      type: [String, Array, Number],
      default: () => []
    },
    defaultProject: {
      type: [String, Array, Number],
      default: () => []
    }
  },
  data() {
    return {
      show: false,
      count: 0,
      loading: false,
      templates: [],
      clients: [],
      batches: [],
      projects: [],
      sites: [],
      targets: [],
      campaigns: [],
      email_accounts: [],
      filtered_email_account_ids: null,
      filtered_email_template_ids: null,
      resources_loaded: false,
      show_bulk_form: false,
      form: {
        template_id: null,
        status: this.status,
        project_ids: this.defaultProject ? (Array.isArray(this.defaultProject) ? this.defaultProject : [this.defaultProject]) : [],
        target_ids: [],
        site_ids: this.defaultSite ? (Array.isArray(this.defaultSite) ? this.defaultSite : [this.defaultSite]) : [],
        campaign_ids: [],
        batch_ids: [],
        email_account_ids: [],
        client_ids: [],
        type: []
      },
      form_rules: {
        template_id: [
          { required: true, message: 'Please select a template' }
        ]
      },
      bulkConfiguration: {
        count: null,
        link: null,
        next_link: null,
        loading: false
      }
    }
  },
  mounted() {
    this.loading = true
    this.getCount()
    // this.loadResources()
  },
  computed: {
    allowedTargets () {
      if(!this.form.project_ids.length && !this.form.site_ids.length) {
        return this.targets
      }

      const allowedSites = this.form.site_ids.length ? this.form.site_ids : this.allowedSites
      return this.targets.filter(t => allowedSites.includes(t.site_id))
    },
    allowedSites () {
      if(!this.form.project_ids.length) {
        return this.sites
      }

      const allowedSiteIds = this.projects.filter(p => this.form.project_ids.includes(p.id))
        .map(p => p.site_targets.map(st => st.site_target_id))
        .reduce((a,b) => [...a, ...b], [])

      return this.sites.filter(s => allowedSiteIds.includes(s.id))
    },
    allowedTemplateIDs() {
      if(!this.form.email_account_ids || !this.form.email_account_ids.length) return []
      const allowedTemplateIds = this.email_accounts.filter(ea => this.form.email_account_ids.includes(ea.id))
        .map(ea => ea.allowed_templates)
        .reduce((a,b) => [...a, ...b], [])
      if(this.filtered_email_template_ids) {
        return allowedTemplateIds.filter(id => this.filtered_email_template_ids.includes(id))
      }
      return allowedTemplateIds
    },
    allowedTemplates() {
      const allowed_ids = this.allowedTemplateIDs
      return this.templates.filter(t => allowed_ids.includes(t.id))
    },
    allowedEmailAccounts() {
      if (!Array.isArray(this.filtered_email_account_ids)) return this.email_accounts
      if (!this.filtered_email_account_ids.length) return [];
      return this.email_accounts.filter(ea => this.filtered_email_account_ids.includes(ea.id))
    }
  },
  methods: {
    getSuffix() {
      if(this.suffix) return this.suffix
      if(this.status && this.status[0]) {
        switch (this.status[0]) {
          case 'waiting_for_initial_reply':
            return 'ready for bulk 2nd initial';
          default:
            return 'past ' + this.status[0].replace(/_/g, ' ')
        }
      }
      return ''
    },
    getCount() {
      this.$http.get(`/v1/companies/${this.$store.state.company.company.id}/links/bulk-status/counter`, {
        params: {
          site_ids: this.defaultSite,
          project_ids: this.defaultProject,
          owner_id: this.$store.state.auth.user.id
        }
      }).then((r) => {
        this.count = r.data.count
        if(this.count > 0) {
          this.show = true
        }
      }).finally(() => {
        this.loading = false
      })
    },
    getConfigCount() {
      const params = {
        status: this.form.status.length ? this.form.status : undefined,
        type: this.form.type.length ? this.form.type : undefined,
        client_ids: this.form.client_ids.length ? this.form.client_ids : undefined,
        site_ids: this.form.site_ids.length ? this.form.site_ids : undefined,
        project_ids: this.form.project_ids.length ? this.form.project_ids : undefined,
        target_ids: this.form.target_ids.length ? this.form.target_ids : undefined,
        campaign_ids: this.form.campaign_ids.length ? this.form.campaign_ids : undefined,
        batch_ids: this.form.batch_ids.length ? this.form.batch_ids : undefined,
        email_accounts: this.form.email_account_ids.length ? this.form.email_account_ids : undefined,
        owner_id: this.$store.state.auth.user.id,
        include_email_account_ids: !this.form.email_account_ids.length
      }
      this.bulkConfiguration.loading = true
      this.bulkConfiguration.count = 0
      this.$http.get(`/v1/companies/${this.$store.state.company.company.id}/links/bulk-status/counter`, {
        params
      }).then((r) => {
        this.bulkConfiguration.count = r.data.count
        this.bulkConfiguration.link = r.data.first_matching_link_id
        this.bulkConfiguration.next_link = r.data.next_matching_link_id
        this.filtered_email_account_ids = r.data.email_account_ids || null
        this.filtered_email_template_ids = r.data.email_template_ids || null
      }).finally(() => {
        this.bulkConfiguration.loading = false
      })
    },
    openBulkForm() {
      this.show_bulk_form = true
      if(!this.resources_loaded) {
        this.loadResources(() => {
          this.show_bulk_form = true
          this.getConfigCount()
        })
      } else {
        this.show_bulk_form = true
        this.getConfigCount()
      }
    },
    formChanged() {
      this.getConfigCount()
    },
    loadResources(callback) {
      const resources = [
        this.$http.get(`/v1/companies/${this.$store.state.company.company.id}/email-templates`, {
          params: {
            all: true,
            in_outreach_group: true
          }
        }),
        // this.$http.get(`/v1/companies/${this.$store.state.company.company.id}/projects`, {
        //   params: {
        //     all: true
        //   }
        // }),
         this.$http.get(`/v1/companies/${this.$store.state.company.company.id}/targets`, {
          params: {
            all: true
          }
        }),

        // this.$http.get(`/v1/companies/${this.$store.state.company.company.id}/clients`, {
        //   params: {
        //     all: true
        //   }
        // }),
        // this.$http.get(`/v1/companies/${this.$store.state.company.company.id}/batches`, {
        //   params: {
        //     all: true,
        //     include_reviewed_status: false
        //   }
        // }),
        this.$http.get(`/v1/companies/${this.$store.state.company.company.id}/user/email-accounts`, {
          params: {
            all: true,
            include_allowed_templates: true
          }
        }),
        this.$http.get(`/v1/companies/${this.$store.state.company.company.id}/sites`, {
          params: {
            all: true
          }
        })
      ]
      this.loading = true
      this.$http.all(resources).then(this.$http.spread((templates, /* projects, */ targets, /*clients, batches,*/ emailAccounts, sites) => {
        this.templates = templates.data.data
        // this.projects = projects.data.data
        // this.clients = clients.data.data
        // this.batches = batches.data.data
        this.email_accounts = emailAccounts.data.data
        this.sites = sites.data.data
        // const campaign_ids = []
        // for (const b of this.batches) {
        //   if(b.campaign) {
        //     if (!campaign_ids.includes(b.campaign.id)) {
        //       this.campaigns.push(b.campaign)
        //       campaign_ids.push(b.campaign.id)
        //     }
        //   }
        // }

        // const st_ids = []
        // this.projects.forEach(p => {
        //   if(p.site_targets) {
        //     p.site_targets.forEach(st => {
        //       if(st.site_target && !st_ids.includes(st.site_target.id)) {
        //         st.site_target.project_id = p.id
        //         this.targets.push(st.site_target)
        //         st_ids.push(st.site_target.id)
        //       }
        //     })
        //   }
        // })

        this.targets = targets.data.data

        this.resources_loaded = true
        if(typeof callback === 'function') {
          callback()
        }
      })).finally(() => {
        this.loading = false
      })
    },
    startBulk() {

      this.$refs.bulkForm.validate(valid => {
        if(valid) {
          let base = `/company/${this.$route.params.companyId}/leads/${this.bulkConfiguration.link.id}`

          const queryParams = {
            bulk: true,
            template_id: this.form.template_id,
            bulk_status: this.form.status.length ? this.form.status : undefined,
            bulk_type: this.form.type.length ? this.form.type : undefined,
            bulk_client_ids: this.form.client_ids.length ? this.form.client_ids : undefined,
            bulk_site_ids: this.form.site_ids.length ? this.form.site_ids : undefined,
            bulk_project_ids: this.form.project_ids.length ? this.form.project_ids : undefined,
            bulk_target_ids: this.form.target_ids.length ? this.form.target_ids : undefined,
            bulk_campaign_ids: this.form.campaign_ids.length ? this.form.campaign_ids : undefined,
            bulk_batch_ids: this.form.batch_ids.length ? this.form.batch_ids : undefined,
            bulk_email_accounts: this.form.email_account_ids.length ? this.form.email_account_ids : undefined,
            owner_id: this.$store.state.auth.user.id
          }

          for(const k in queryParams) {
            if(!queryParams[k]) delete queryParams[k]
          }

          if(Object.keys(queryParams).length) {
            base += '?' + (new URLSearchParams(queryParams)).toString();
          }

          window.location = base
        }
      })
    }
  }
}
</script>
