<template>
  <div v-loading="loading" class="lead-info-component">
    <div v-if="activeSameDomains.length" class="text-xs text-red-700 font-bold mb-4">
      Warning! There are already Active/Existing site links for this domain: <a v-for="(i, k) in activeSameDomains" class="font-bold text-blue-800" :key="k" :href="`/company/70/leads/${i.id}`">#{{ i.id }}&nbsp;</a>
    </div>
    <trieste-card
      v-loading="loading_resources"
      title="Settings" :show-title="false">
      <div class="p-4 pb-0">
        <el-form
          ref="urlForm"
          :model="url_form.form"
          :rules="url_form.rules"
          label-width="150px"
          label-position="left"
          @submit="openLinkTab"
        >
          <el-form-item prop="url" v-show="false">
            <el-input v-model="url_form.form.url" />
          </el-form-item>
          <el-form-item
            label="From URL"
            prop="url"
          >
            <div class="columns">
              <div class="column w-full">
                <div class="flex flex-row">
                  <el-input
                    v-model="url_form.form.url"
                    class="w-full"
                    :loading="loading_resources"
                    :disabled="loading_resources"
                    @keyup.enter.native="updateUrl"
                  />
                  <div>
                    <div class="actions-box">
                      <a class="action" :href="url_form.form.url" @click.prevent="openLinkTab">VISIT</a><br />
                      <a class="action" href="#" @click.prevent="updateUrl">UPDATE</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </el-form-item>
        </el-form>
        <el-form
          ref="settingsForm"
          :model="settings"
          :rules="rules.settings"
          label-width="150px"
          label-position="left"
        >
          <project-and-target-form
            :target-id.sync="link.site_target_id"
            :site-id="link.site_id"
            :project-id.sync="link.project_id"
            @target-updated="() => updateLink({ site_target_id: link.site_target_id })"
            @project-updated="() => updateLink({ project_id: link.project_id })"
            form-label-width="150px"
            :active-only="false"
          >
            <template v-slot:target-slot="props">
              <div>
                <div class="actions-box">
                  <a class="action" href="#" @click.prevent="openTargetTab(props.targets)" v-if="link.site_target_id">
                    VISIT
                  </a><br/>
                  <a class="action" href="#" @click.prevent="show_project_move_dialog = true; load_move_form = true">
                    MOVE
                  </a>
                  <div class="actions-dropdown mt-1">
                    <a class="toggle action" href="#">ACTIONS</a>
                    <div class="list">
                      <ul>
                        <li><a href="#" @click.prevent="openTargetWindow(link.site_target_id)" v-if="link.site_target_id">View Target</a></li>
                        <li><a href="#" @click.prevent="openTargetContentWindow(link.site_target_id)" v-if="link.site_target_id">Content</a></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <template v-slot:project-slot v-if="false">
              <div class="ml-2">
                <el-button size="medium" icon="el-icon-arrow-right" type="primary" @click="show_project_move_dialog = true; load_move_form = true">Move</el-button>
              </div>
            </template>
          </project-and-target-form>
          <div class="text-sm font-bold text-red-700 -mt-3 mb-2 ml-40" v-if="assignProjectWarning">
            Please select a project for this link. This link will not be billed unless it is assigned to a project.
          </div>
          <el-form-item prop="urgent" label="Urgent">
            <el-switch v-model="link.urgent" @change="(v) => { updateLink({ urgent: v }) }" :disabled="!canUpdateStatus || loading_resources" :loading="loading_resources" />
          </el-form-item>
          <el-form-item label="Owner">
            <el-select v-model="link.owner_id"
                       filterable class="w-full" @change="(v) => { updateLink({ owner_id: v }) }" :disabled="!canUpdateOwner || loading_resources" :loading="loading_resources">
              <el-option
                v-for="(cu, cui) in company_users"
                :key="'cu_'+cui"
                :value="cu.id"
                :label="`${cu.firstname} ${cu.lastname} (${cu.email})`"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="Created By">
            {{ getCreatedBy }} on {{ link.created_at | momentLocal("dddd MMMM DD YYYY hh:mm A Z") }}
          </el-form-item>
          <el-form-item label="Acquired by" v-if="getAcquiredBy">
            {{ getAcquiredBy }} on {{ link.acquired_date | momentLocal("dddd MMMM DD YYYY hh:mm A Z") }}
          </el-form-item>
          <el-form-item label="Updated By" v-if="getUpdatedBy && link.updated_at">
            {{ getUpdatedBy }} on {{ link.updated_at | momentLocal("dddd MMMM DD YYYY hh:mm A Z") }}
          </el-form-item>
          <el-form-item
            label="Contact Form URL"
            prop="contact_form_url"
          >
            <div class="columns">
              <div class="column w-full">
                <div class="flex flex-row">
                  <el-input
                    v-model="link.contact_form_url"
                    class="w-full"
                    :loading="loading_resources"
                    :disabled="loading_resources"
                  />
                  <div>
                    <div class="actions-box">
                      <a class="action" href="#" @click.prevent="openContentTab(link.contact_form_url)">UPDATE</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </el-form-item>
          <el-form-item
            label="Parent ID"
            prop="parent_id"
          >
            <div class="columns">
              <div class="column w-full">
                <div class="flex flex-row">
                  <el-input
                    :loading="loading_resources"
                    :disabled="loading_resources"
                    v-model="link.parent_id"
                    class="w-full"
                  />
                  <div>
                    <div class="actions-box">
                      <a class="action" href="#" v-if="link.parent_id" @click.prevent="visitParentId">VISIT</a><br/>
                      <a class="action" href="#" @click.prevent="updateParentId">UPDATE</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </el-form-item>
          <el-form-item
            label="Client note"
            prop="client_note"
            v-if="link.status === 'awaiting_client_approval'"
          >
            <div class="columns">
              <div class="column w-full">
                <div class="flex flex-row">
                  <el-input type="textarea" v-model="link.client_note" resize="none" rows="5" />
                  <div>
                    <div class="actions-box">
                      <a class="action" href="#" @click.prevent="updateClientNote">UPDATE</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </el-form-item>
          <el-form-item
            label="Declined reason"
            prop="client_declined_reason"
            v-if="link.client_declined_reason"
          >
            {{ link.client_declined_reason }}
          </el-form-item>
          <el-form-item label="Reject Reason" prop="RejectReason" v-if="[/*'dropped', */'rejected'].includes(link.status)">
            <el-select v-model="link.rejected_reason" class="w-full" @change="updateRejectReason">
              <el-option :value="null">None</el-option>
              <el-option v-for="(opt, i) in link_rejected_reasons" :key="i" :value="opt.value" :label="opt.label" />
            </el-select>
          </el-form-item>
        </el-form>
      </div>
    </trieste-card>
    <trieste-card title="Project requirements" collapsable collapsed>
      <requirements :project="getProject" :site-target="getTarget" />
    </trieste-card>
    <site-requirements :site-id="value.site_id" v-if="value.site_id" />
    <trieste-dialog title="Move site / target" :show.sync="show_project_move_dialog">
      <div class="p-4">
        <project-and-target-form
          v-if="load_move_form"
          show-site-select
          :target-site-id.sync="project_move_config.site_id"
          :project-id.sync="project_move_config.project_id"
          :target-id.sync="project_move_config.target_id"
          :show-project-select="false"
          form-label-width="120px"
          allow-suspended-selection
        />
      </div>
      <template v-slot:footer-right>
        <el-button type="danger" size="small" icon="el-icon-close" @click="closeMoveDialog">Close</el-button>
        <el-button
          v-if="project_move_config.site_id && project_move_config.target_id"
          type="primary" size="small" icon="el-icon-arrow-right" @click="moveLink" :disabled="loading">Move</el-button>
      </template>
    </trieste-dialog>
  </div>
</template>
<script>
  import Requirements from "@/components/views/links/Requirements";
  import SiteRequirements from "@/components/views/links/SiteRequirements";
  import RejectReasons from '@/utils/link/reject_reasons.json';
  import ProjectAndTargetForm from '@/components/views/global/ProjectAndTargetForm';
  const psl = require('psl');
  export default {
    components: {SiteRequirements, Requirements, ProjectAndTargetForm},
    props: {
      value: {
        type: Object
      },
      activeSameDomains: {
        type: Array,
        default: () => []
      }
    },
    mounted() {
      this.getResources()
    },
    data() {
      return {
        loading_resources: true,
        company_users: [],
        site_targets: [],
        link_rejected_reasons: RejectReasons,
        projects: [],
        all_projects: [],
        all_site_targets: [],
        project_sites: [],
        content_items: [],
        loading: false,
        load_move_form: false,
        show_project_move_dialog: false,
        project_move_config: {
          site_id: this.value.site_id,
          project_id: this.value.project_id,
          target_id: this.value.site_target_id
        },
        show_contact_form: false,
        updatingTarget: false,
        show_create_content_dialog: false,
        site_target_id: this.value.site_target_id,
        link: this.value,
        rules: {
          settings: {
            target: [
              {required: true, message: 'Please select a target', trigger: 'blur'},
              {required: true, message: 'Please select a target', trigger: 'focus'}
            ],
            from_url: [
              {required: true, message: 'Please enter a from URL', trigger: 'change'},
              {required: true, message: 'Please enter a from URL', trigger: 'blur'},
              {required: true, message: 'Please enter a from URL', trigger: 'focus'}
            ]
          },
        },
        settings: {
          from_url: this.value.from_url,
          target: null
        },
        url_form: {
          form: {
            url: this.value.from_url
          },
          rules: {
            url: [
              { required: true, type: 'url', message: 'Please enter a valid site URL.' }
            ]
          }
        }
      }
    },
    computed: {
      assignProjectWarning() {
        return this.link && !this.link.project_id && ['awaiting_fulfillment', 'active', 'waiting_for_tier_3_approval'].includes(this.link.status)
      },
      getCreatedBy() {
        const owner = this.company_users.find(u => u.id === this.value.created_by_id)
        if(owner) {
          return `${owner.firstname} ${owner.lastname}`;
        }
        return 'N/A';
      },
      getAcquiredBy() {
        if(this.link.status === 'active' || this.link.status === 'existing') {
          const owner = this.company_users.find(u => u.id === this.value.acquired_by_id)
          if (owner) {
            return `${owner.firstname} ${owner.lastname}`;
          }
        }
        return false;
      },
      getUpdatedBy() {
        const owner = this.company_users.find(u => u.id === this.value.UpdatedBy)
        if(owner) {
          return `${owner.firstname} ${owner.lastname}`;
        }
        return null;
      },
      availableSiteTargets() {
        if (this.link.project_id) {
          return this.site_targets.filter(st => st.project.id === this.link.project_id)
        }
        return this.site_targets;
      },
      allowedProjects() {
        return this.link.project_id ? this.projects.filter(p => p.id === this.link.project_id) : []
      },
      getProject() {
        if(this.link.project_id) {
          return this.projects.find(p => p.id === this.link.project_id)
        }
        return null
      },
      getTarget() {
        if(this.link.site_target_id) {
          return this.site_targets.find(s => s.id === this.link.site_target_id)
        }
        return null
      },
      canUpdateStatus() {
        return !!(this.$store.getters['auth/hasPermission']('other.lead.status.update') ||
          (this.$store.getters['auth/hasPermission']('own.lead.status.update') && this.$store.state.auth.user.id === this.link.owner_id));
      },
      canUpdateOwner() {
        return !!(this.$store.getters['auth/hasPermission']('other.lead.owner.update') ||
          (this.$store.getters['auth/hasPermission']('own.lead.owner.update') && this.$store.state.auth.user.id === this.link.owner_id));
      },
      allowedMoveProjectList() {
        if(!this.project_move_config.site_id) {
          return []
        }

        return  this.all_projects.filter(p => p.sites.includes(this.project_move_config.site_id))
      },
      allowedMoveTargetList() {
        if(!this.project_move_config.project_id) {
          return []
        }

        return  this.all_site_targets.filter(st => st.project_id === this.project_move_config.project_id && st.site_target)
      }
    },
    methods: {
      openLinkTab() {
        this.openTab(this.url_form.form.url)
      },
      openContentTab() {
        if(this.canOpenTab(this.link.contact_form_url)) {
          this.updateLink({
            contact_form_url: this.link.contact_form_url
          })
          this.$emit('contact-form-updated', this.link.contact_form_url)
        }
      },
      canOpenTab(location) {
        try {
          psl.parse(new URL(location).host)
          return true;
        } catch(e) {
          this.$message.error('Please provide a valid URL.')
          return false
        }
      },
      openWhoisTab(location) {
        try {
          const url = new URL(location)
          window.open('https://who.is/whois/' + url.hostname)
        } catch(e) {
          this.$message.error('Please provide a valid URL.')
        }
      },
      openTargetTab(targets) {
        if(!this.link.site_target_id) {
          return this.$message.error('Please select a target')
        }
        const target = (targets || this.all_site_targets).find(i => i.id === this.link.site_target_id);
        if(target) {
          this.openTab(target.to_url)
        }
      },
      openTargetWindow() {
        if(!this.link.site_target_id) {
          return this.$message.error('Please select a target')
        }
        window.open(`/company/${this.$route.params.companyId}/site-targets/${this.link.site_target_id}`);
      },
      openTargetContentWindow() {
        if(!this.link.site_target_id) {
          return this.$message.error('Please select a target')
        }
        window.open(`/company/${this.$route.params.companyId}/site-targets/${this.link.site_target_id}`);
      },
      updateSiteTarget() {
        this.updatingTarget = true;
        this.$http.put(
          `/v1/companies/${this.$store.state.company.company.id}/links/${this.$route.params.linkId}`,
          {
            status: this.link.status,
            target: this.link.site_target_id
          }).then(() => {
          this.$message.success('Succesfully updated the target')
          this.site_target_id = this.link.site_target_id
        })
          .catch(() => {
            this.$message.error('An error occurred updating the site target.')
          })
          .finally(() => {
            this.updatingTarget = false;
          })
      },
      updateRejectReason() {
        return this.updateLink({
          reject_reason: this.link.rejected_reason
        })
      },
      updateLink(params) {
        this.loading = true;
        params = params || {}
        this.$http.put(
          `/v1/companies/${this.$store.state.company.company.id}/links/${this.$route.params.linkId}`, params
        ).then(() => {
          this.$message.success('Successfully updated the link.');
          this.$emit('on-update')
        })
          .catch((e) => {
            this.$message.error('An error occurred updating the link.');
          })
          .finally(() => {
            this.loading = false
          })
      },
      updateParentId() {
        return this.updateLink({
          parent_id: this.link.parent_id
        })
      },
      visitParentId() {
        if(this.link.parent_id) {
          window.open(`/company/${this.$route.params.companyId}/links/${this.link.parent_id}`)
        }
      },
      getResources() {
        this.loading_resources = true;
        const requests = [
          this.$http.get('/v1/companies/' + this.$store.state.company.company.id + '/projects', {
            params: {
              /* client_id: this.value.client_id,*/
              all: true,
              active_only: true
            }
          }),
        ]
        this.$store.dispatch('users/loadAll', {}).then(r => {
          this.company_users = r
        }).finally(() => {
          this.$http.all(requests).then(this.$http.spread((projects) => {
            this.site_targets = []
            this.all_site_targets = []
            this.projects = []
            this.all_projects = []
            const foundProjects = []
            projects.data.data.forEach(p => {
              let project = this.all_projects.find(proj => proj.id === p.id)
              if(!project) {
                project = Object.assign({}, p)
                project.sites = []
                this.all_projects.push(project)
              }

              this.all_site_targets.push(...p.site_targets)
              p.site_targets.forEach(pts => {
                const st = Object.assign({}, pts.site_target)
                st.project = p
                this.site_targets.push(st)
                if(st.site) {
                  if(!project.sites.includes(st.site.id)) {
                    project.sites.push(st.site.id)
                  }
                  if(!this.project_sites.find(s => s.id === st.site.id)) {
                    this.project_sites.push(st.site)
                  }
                }
                if(!foundProjects.includes(p.id)) {
                  this.projects.push(st.project)
                  foundProjects.push(p.id)
                }
              })
            })
            this.loading_resources = false;
          }))
        })
      },
      moveSiteChanged() {
        const p = this.project_move_config.project_id ? this.all_projects.find(p => p.id === this.project_move_config.project_id) : null
        if(!p || !p.sites.includes(this.project_move_config.site_id)) {
          const projects = this.allowedMoveProjectList
          //console.log('Eh', projects)
          if(projects.length) {
            this.project_move_config.project_id = projects[0].id
            this.moveProjectSelect()
          } else {
            this.project_move_config.project_id = null
            this.project_move_config.target_id = null
          }
        }
      },
      moveProjectSelect() {
        const t = this.project_move_config.target_id ? this.all_site_targets.find(i => i.site_target.id === this.project_move_config.target_id) : null
        if(!t || t.project_id !== this.project_move_config.project_id) {
          const targets = this.allowedMoveTargetList
          if(targets.length) {
            this.project_move_config.target_id = targets[0].site_target.id
          } else {
            this.project_move_config.target_id = null
          }
        }
      },
      closeMoveDialog() {
        this.show_project_move_dialog = false
        this.project_move_config.site_id = this.value.site_id
        this.project_move_config.project_id = this.value.project_id
        this.project_move_config.target_id = this.value.site_target_id
      },
      moveLink() {
        if(
          this.value.site_id !== this.project_move_config.site_id ||
          this.value.project_id !== this.project_move_config.project_id ||
          this.value.site_target_id !== this.project_move_config.target_id
        ) {
          this.loading = true;
          this.$http.post(`/v1/companies/${this.$store.state.company.company.id}/links/${this.value.id}/move`, this.project_move_config).then(() => {
            this.$message.success('Successfully moved the site link to the new project / site. The page will now refresh to load changes.')
            this.closeMoveDialog()
            setTimeout(() => {
              window.location.reload()
            }, 1200)
          })
          .catch(e => {
            console.error(e)
            this.$message.error('An error occurred trying to move the link.')
          })
          .finally(() => {
            this.loading = false
          })
        } else {
          this.$message.info('No changes have been detected.')
        }
      },
      updateClientNote() {
        return this.updateLink({
          client_note:  this.link.client_note
        });
      },
      updateUrl() {
        this.$refs.urlForm.validate(valid => {
          if(valid) {
            // const before = psl.parse(new URL(this.value.from_url).host).domain
            const after = psl.parse(new URL(this.url_form.form.url).host).domain
            if(!after) {
              return this.$message.error('The url does not look to have a valid domain. Please check the url and try again.')
            }
            return this.updateLink({
              new_from_url:  this.url_form.form.url
            });
          }
        })
      },
      openTab(url) {
        window.open(this.getOutboundSafeUrl(url))
      }
    },
    watch: {
      'value.updated_at': function() {
        this.link = this.value
      },
      'link.project_id': function (v) {
        //console.log('I have been changed?', v)
      }
    }
  }
</script>
<style lang="scss">
  .el-select-dropdown__item {
    height: auto;
  }
</style>
