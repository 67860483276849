<template>
  <div v-loading="loading" class="search-module">
    <div>
      <el-radio label="lead" v-model="params.entity" class="bigger">Leads</el-radio>
      <el-radio label="link" v-model="params.entity" class="bigger">Links</el-radio>
      <el-radio label="client" v-model="params.entity" class="bigger">Clients</el-radio>
      <el-radio label="project" v-model="params.entity" class="bigger">Projects</el-radio>
      <el-radio label="batch" v-model="params.entity" class="bigger">Batches</el-radio>
    </div>
    <div class="my-4 flex">
      <input
        class="border border-gray-400 text-gray-800 px-3 p-2 rounded w-full"
        @keypress.enter="searchFromPage1()"
              type="text" v-model="params.term" />
      <el-button @click="searchFromPage1" slot="append" type="primary">Search</el-button>
    </div>
    <div class="results-table overflow-y-auto" v-if="search_performed" ref="resultsTable">
      <div v-if="results.length">
        <ul>
          <li v-for="(result, i) in results" :key="i" class="my-2 pb-4 mb-4 text-blue-800 font-medium text-sm border-b-2 border-gray-200">
            <a :href="renderLink(result)" v-if="!['link', 'lead'].includes(result.type)" class="hover:underline">
              <div :class="getTableRowClass(result.type)">
                <div :class="getTableButtonClass(result.type)" v-if="false">
                  <el-button size="mini" type="primary">{{ result.type }}</el-button>
                </div>
                <div v-html="result.match" class="w-full"></div>
              </div>
            </a>
            <div v-else>
              <div class="text-gray-600 font-normal hover:underline"><a :href="renderLink(result)">{{ result.match.id }}</a></div>
              <div class="mt-1"><b>{{ result.match.url }}</b></div>
              <div>
                <ul class="flex flex-row mt-2">
                  <li>Status: <b>{{result.match.status}}</b></li>
                  <li class="ml-4 border-l border-gray-500 pl-4" v-if="result.type === 'lead'">Owner: <b>{{result.match.owner}}</b></li>
                  <li class="ml-4 border-l border-gray-500 pl-4" v-if="result.type === 'lead' && result.match.project">Project: <b>{{result.match.project}}</b></li>
                </ul>
              </div>
            </div>
          </li>
        </ul>
        <div class="p-4 flex flex-col items-end pt-0" v-if="pagination.total > pagination.rpp">
          <small class="mb-4  ">Showing page {{pagination.page}} of {{pagination.total_pages}}. Total results {{pagination.total}}</small>
          <el-pagination
            background
            layout="prev, pager, next"
            :total="pagination.total"
            :page-size.sync="pagination.rpp"
            :current-page="pagination.page"
            @current-change="onPaginationChange"
          >
          </el-pagination>
        </div>
      </div>
      <div v-else>
        <p class="text-red-800">No results were found.</p>
      </div>
    </div>
    <div class="mt-auto border-t border-gray-300 pt-4">
      <a href="#" class="text-gray-600 font-medium" @click.prevent="$emit('update:visible', false)"><i class="el-icon-close font-bold mr-1"></i>Close</a>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'search',
    props: {
      visible: {
        type: Boolean,
        default: null
      }
    },
    data: function() {
      return {
        params: {
          term: '',
          client: false,
          project: false,
          lead: false,
          link: false,
          batch: false,
          entity: 'lead'
        },
        loading: false,
        results: [],
        search_performed: false,
        pagination: {
          rpp: 25,
          page: 1,
          total: 0,
          total_pages: 0
        }
      }
    },
    methods: {
      searchFromPage1() {
        this.pagination.page = 1;
        this.search()
      },
      search() {
        if(this.params.term.trim().length < 1) {
          return this.$message.error('Please enter a search term with one or more characters.')
        }
        this.loading = true
        this.$http.get(`/v1/companies/${this.$store.state.company.company.id}/search`, {
          params: Object.assign({}, this.params, this.pagination)
        }).then(results => {
          this.results = results.data.data
          this.pagination.total = results.data.total
          this.pagination.total_pages = results.data.total_pages
          this.search_performed = true
        })
        .catch(() => {
          this.$message.error('Failed to perform the search')
        })
        .finally(() => {
          this.loading = false
        })
      },
      onPaginationChange(page) {
        this.pagination.page = page
        this.search()
        this.$refs.resultsTable.scroll(0, 0)
      },
      renderLink(result) {
        const companyId = this.$route.params.companyId || this.$store.state.company.company.id
        let baseLink = `/company/${companyId}`;
        switch(result.type) {
          case "client": baseLink += `/clients/${result.data.id}`; break;
          case "project": baseLink += `/projects/${result.data.id}`; break;
          case "batch": baseLink += `/batch/${result.data.id}`; break;
          case "lead":
            baseLink += `/leads/${result.data.id}`; break;
          case "link":
            baseLink += `/links/${result.data.id}`; break;
        }

        return baseLink
      },
      getTableRowClass(type) {
        return ['link', 'lead'].includes(type) ? 'flex flex-row items-start' : 'flex flex-row items-center';
      },
      getTableButtonClass(type) {
        return ['link', 'lead'].includes(type) ? 'mr-2 mt-3' : 'mr-2 mt-1';
      }
    }
  }
</script>
<style lang="scss" scoped>
  .search-module {
    @apply flex flex-col h-full;
    .results-table {
      .el-button {
        @apply rounded-full;
      }
    }
  }
</style>
