<template>
  <div v-loading="loading">


    <div v-if="!ready" class="h-40">
    </div>

    <div v-if="isInRedAlertMode" class="mb-4">
      <el-alert type="error" dark :closable="false">
        {{ this.$store.state.site.site.domain.toUpperCase() }} IS IN RED ALERT MODE
      </el-alert>
    </div>
    <div v-if="assignProjectWarning" class="mb-4">
    <el-alert type="error" dark :closable="false">
      <span class="font-bold">Please select a project for this link. This link will not be billed unless it is assigned to a project.</span>
    </el-alert>
    </div>
    <div v-show="ready">
      <div class="mb-4 flex justify-end items-end" v-if="!link.failed">
        <div class="flex-col flex items-end" v-if="canReview">

        <div v-if="batch_email_stats && batch_email_stats.awaiting && batch_email_stats.awaiting > 0">
          <el-switch v-model="bulk_emailing_state" active-text="Bulk emailing" @change="updateBulkMailingState" />
          <span
            class="text-xs"
            >
        {{
              `${batch_email_stats.sent.toLocaleString()} / ${batch_email_stats.total.toLocaleString()} initial email's have been sent.`
            }}
        </span>
            </div>


          <div class="text-right text-xs">
            <a :href="`/company/${this.$route.params.companyId}/leads/${prev_email_link.id}`" v-if="prev_email_link" class="font-bold text-blue-800">Previous lead</a>
            <span v-if="prev_email_link && next_email_link"> / </span>
            <a :href="`/company/${this.$route.params.companyId}/leads/${next_email_link.id}`" v-if="next_email_link" class="font-bold text-blue-800">Next lead</a>
          </div>

        </div>
        <div class="flex-col flex items-end ml-8" v-if="canReview && !isLead">
          <el-switch v-model="$store.state.batch.automatically_go_to_next_link" @change="updateAutoNextLink"
                     active-text="Go to next lead after status update"/>
          <div class="my-2" v-if="link && link.lead_status === 'not_reviewed' && false">
            <el-switch v-model="$store.state.batch.show_contact_form_on_accept" @change="updateAddContactForm"
                       active-text="Show 'add contact' on accept"/>
          </div>
          <span
            class="text-xs"
            v-if="batch_review_status.pending_review !== undefined">
        {{
              `${batch_review_status.reviewed.toLocaleString()} / ${batch_review_status.total.toLocaleString()} have been reviewed in this batch.
          ${batch_review_status.pending_review.toLocaleString()} are left.`
            }}
      </span>
        </div>
      </div>
      <div class="columns items-center pb-3">
        <div class="page-title w-full">
          <h1 v-if="!isLead">
            <logo
              v-model="link.from_url"
              class="mr-2"
              :size="30"
            />
            <span>{{ link.from_url ? link.from_url : '#' }}<span class="faded-text"><span
              class="faded-strike">/</span>#{{ link.id }}</span></span>
          </h1>
          <div v-else>
          <h1>
            <logo
              v-model="link.from_url"
              class="mr-2"
              :size="30"
            />
            <span>#{{ link.id }}</span>
          </h1>
          <div class="-mt-4 block text-xs font-bold text-gray-400"><span>{{ link.from_url ? link.from_url : '#' }}</span></div>
          </div>
          <div class="controls">
            <div class="columns">
              <div class="column" v-if="!link.failed">
                <div v-if="isLead && $store.getters['auth/hasPermission']('lead.update')">
                  <div class="columns">
                    <div class="column">
                      <div class="el--form-item">
                        <label>Status</label>
                        <el-select
                          v-model="link.status"
                          size="small"
                          filterable
                          @change="checkStatusBeforeUpdate"
                          :disabled="!canUpdateStatus"
                        >
                          <el-option
                            v-for="(o, i) in $store.state.link.statuses"
                            :key="i"
                            :value="o.value"
                            :label="o.label"
                            :disabled="canSetThisStatus(o.value)"
                          />
                        </el-select>
                      </div>
                    </div>
                    <div class="column">
                      <div class="el--form-item">
                        <label>Type</label>
                        <el-select
                          v-model="link.link_type"
                          size="small"
                          filterable
                          @change="updateLink"
                          :disabled="!canUpdateStatus"
                        >
                          <el-option
                            v-for="(o, i) in $store.state.link.types"
                            :key="i"
                            :value="o.value"
                            :label="o.label"
                          />
                        </el-select>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="flex"
                     v-else-if="lead_status !== 'declined' && $store.getters['auth/hasPermission']('link.update') && canReview">
                  <div class="flex ml-auto">
                    <el-button
                      type="info"
                      size="mini"
                      v-if="lead_status !== 'deferred'"
                      @click="() => {link.lead_status = 'deferred'; this.updateLink() }"
                    >
                      (D)efer
                    </el-button>
                    <el-button
                      type="primary"
                      size="mini"
                      v-if="lead_status !== 'declined_parked'"
                      @click="() => {link.lead_status = 'declined_parked'; this.updateLink() }"
                    >
                      (P)ark
                    </el-button>
                    <el-button
                      size="mini"
                      type="danger"
                      v-if="lead_status !== 'declined'"
                      @click="() => {link.lead_status = 'declined'; this.updateLink() }"
                    >
                      (R)eject
                    </el-button>
                    <el-button
                      size="mini"
                      type="success"
                      @click="() => {link.lead_status = 'accepted'; this.openAcceptPrompt() }"
                    >
                      (A)pprove
                    </el-button>
                  </div>
                </div>
              </div>
              <div class="column">
                <a v-if="link.bulk_link_leads_batch_id"
                   :href="`/company/${$route.params.companyId}/batch/${link.bulk_link_leads_batch_id}`">
                  <el-button
                    type="primary"
                    size="mini"
                    icon="el-icon-back">
                    Batch
                  </el-button>
                </a>
                <a v-if="previous_link" class="inline-block ml-2"
                   :href="generateNavigationLinks(previous_link.id)">
                  <el-button
                    type="primary"
                    size="mini"
                    icon="el-icon-back">
                    Prev {{ isLead ? 'lead' : 'link' }}
                  </el-button>
                </a>
                <a v-if="next_link" class="inline-block ml-2"
                   :href="generateNavigationLinks(next_link.id)">
                  <el-button
                    type="primary"
                    size="mini"
                    icon="el-icon-right">
                    Next {{ isLead ? 'lead' : 'link' }}
                  </el-button>
                </a>
              </div>
            </div>
            <div class="columns justify-end">
              <div class="column text-right">
                <div>
            <span
                class="text-xs"
                v-if="$route.query.bulk && batch_review_status && typeof batch_review_status === 'number'">
        {{
                `${batch_review_status.toLocaleString()} links left matching the bulk query.`
              }}
      </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="!first_load">
        <lead v-model="link"
              v-if="isLead"
              @update="updateLink"
              @reload="getLink" :next-email-link="next_email_link"/>
        <preview v-model="link" @reload="getLink" v-else/>
      </div>
      <trieste-dialog title="Reject reason" :show.sync="showRejectReasons">
        <div class="p-4">
          <el-form label-position="left" label-width="120px">
            <el-form-item label="Reason">
              <el-select v-model="link.RejectReason" class="w-full">
                <el-option v-for="(opt, i) in reject_reasons" :key="i" :value="opt.value" :label="opt.label"/>
              </el-select>
            </el-form-item>
          </el-form>
        </div>
        <template v-slot:footer-left>
          <el-button type="info" @click="showRejectReasons = false" size="medium">Cancel</el-button>
        </template>
        <template v-slot:footer-right>
          <el-button type="danger" @click="() => { showRejectReasons = false; updateLink() }" size="medium">Reject
          </el-button>
        </template>
      </trieste-dialog>

      <trieste-dialog title="Approve link" :show.sync="show_mass_update_confirm">
        <div class="p-4" v-loading="loading">
          Would you like to create a contact for this lead now?
          <el-form :model="mass_update_contact" ref="mass_update_contact_form" label-width="120px" label-position="left"
                   :rules="mass_update_rules" class="mt-4">
            <el-form-item label="First name" prop="firstname">
              <el-input v-model="mass_update_contact.firstname"/>
            </el-form-item>
            <el-form-item label="last name" prop="lastname">
              <el-input v-model="mass_update_contact.lastname"/>
            </el-form-item>
            <el-form-item label="Email" prop="email">
              <el-input v-model="mass_update_contact.email"/>
            </el-form-item>
          </el-form>
        </div>
        <template v-slot:footer-right>
          <el-button @click="show_mass_update_confirm = false" icon="el-icon-close" type="danger" size="small">Cancel
          </el-button>
          <el-button
            type="success"
            icon="el-icon-edit-outline" @click="acceptPrompt" size="small">Confirm
          </el-button>
        </template>
      </trieste-dialog>
    </div>
  </div>
</template>

<script>
import Preview from './Tabs/Preview'
import Lead from './Tabs/Lead'
import Payments from "../../../../../components/views/links/Payments";
import NotesWidget from "../../../../../components/notes/index";
import Info from "@/components/views/links/Info";
import Timer from "@/utils/timer";
import RejectReasons from '@/utils/link/reject_reasons.json'

export default {
  name: 'SiteLink',
  components: {
    Preview,
    Lead
  },
  metaInfo() {
    return {
      title: () => `${this.$route.name === 'Link' ? 'Link' : 'Lead'} ${this.$route.params.linkId}`,
    }
  },
  data() {
    return {
      link: {
        LeadStatus: 'not_reviewed',
        RejectReason: null,
      },
      auto_responder: {
        enabled: false,
        has_error: true,
        email_account_id: null,
        default_link_type: null,
        sequence: []
      },
      reject_reasons: RejectReasons,
      lead_status: null,
      status: null,
      next_link: null,
      previous_link: null,
      loading: true,
      ready: false,
      lead_component_key: 0,
      batch_review_status: {},
      next_email_link: null,
      prev_email_link: null,
      batch_email_stats: {},
      first_load: true,
      keybindings_is_set: false,
      showRejectReasons: false,
      show_mass_update_confirm: false,
      bulk_emailing_state: this.$store.state.batch.bulk_mailing.active,
      mass_update_rules: {
        email: [
          {type: 'email', message: 'Please enter a valid email address'}
        ]
      },
      mass_update_type: null,
      mass_update_contact: {
        firstname: null,
        lastname: null,
        email: null
      },
    }
  },
  computed: {
    getFullName() {
      return this.link && this.link.User ? [this.link.User.firstname, this.link.User.lastname].join(' ') : '';
    },
    isInRedAlertMode() {
      return this.link && !!this.$store.state.site.site.red_alert_mode
    },
    canReview() {
      if (!this.isOwner && this.$store.getters['auth/hasPermission']('other.batch.review')) {
        return true
      } else if (this.isOwner && this.$store.getters['auth/hasPermission']('own.batch.review')) {
        return true
      }

      return false
    },
    assignProjectWarning() {
      return this.link && !this.link.project_id && ['awaiting_fulfillment', 'active', 'waiting_for_tier_3_approval'].includes(this.link.status)
    },
    isLead() {
      return this.link && this.link.lead_status && this.link.lead_status === 'accepted'
    },
    canUpdateStatus() {
      if(['active', 'active_renewal'].includes(this.link.status) && !this.$store.getters['auth/hasPermission']('payment.update-status')) return false
      return !!(this.$store.getters['auth/hasPermission']('other.lead.status.update') ||
        (this.$store.getters['auth/hasPermission']('own.lead.status.update') && this.$store.state.auth.user.id === this.link.owner_id));
    },
    isOwner() {
      if (this.link.owner_id) {
        return this.$store.state.auth.user.id === this.link.owner_id
      } else {
        return this.$store.state.auth.user.id === this.$store.state.batch.batch.owner_id
      }
    },
  },
  created() {
    this.getLink();
  },
  methods: {
    updateBulkMailingState(state) {
      //console.log('The state', state)
      this.$store.dispatch('batch/setBulkStatus', state);
    },
    linkType(isCapital) {
      const t = this.isLead;
      if (isCapital) {
        return t[0].toUpperCase() + t.substr(1);
      }
      return t;
    },
    checkStatusBeforeUpdate() {
      if (this.link.status !== this.status && ['rejected'].includes(this.link.status)) {
        this.showRejectReasons = true;
      } else {
        this.updateLink()
      }
    },
    checkLnkIsValid () {
      let redirect = null
      if(this.$route.name === 'Link' && this.link.lead_status === 'accepted') {
        redirect = `company/${this.$store.state.company.company.id}/leads/${this.link.id}`;
      } else if (this.$route.name === 'Leads' && this.link.lead_status !== 'accepted') {
        redirect = `company/${this.$store.state.company.company.id}/links/${this.link.id}`;
      }
      if(redirect) window.location = `${window.location.origin}/${redirect}`
    },
    getLink() {
      this.loading = true;
      const params = {};
      if (this.$route.query.bulk) {
        this.prepBulkParams(params)
      }



      this.$http.get('/v1/companies/' + this.$store.state.company.company.id + '/links/' + this.$route.params.linkId, {
        params
      })
        .then((response) => {

          console.log(response.data)
          // safely check the url.
          this.link = response.data.data;
          this.auto_responder = response.data.auto_responder;
          this.checkLnkIsValid()
          this.next_link = response.data.next_link || null;
          this.link.next_link = this.next_link;
          this.previous_link = response.data.previous_link || null;
          this.lead_status = this.link.lead_status;
          this.status = this.link.status;
          this.batch_review_status = response.data.batch_reviewed_status;
          this.next_email_link = response.data.next_emailable_link;
          this.prev_email_link = response.data.prev_emailable_link;
          this.batch_email_stats = response.data.initial_email_status;

          if(this.auto_responder && this.auto_responder.default_type && this.auto_responder.enabled === true){
            if(this.auto_responder.has_sent_initial_email === false){
              //set the link type to the default state
               this.link.link_type = this.auto_responder.default_type;
            }
            this.link.auto_responder = this.auto_responder;
          }

          if (
            !this.$route.query.bulk &&
            this.$store.state.batch.bulk_mailing.active &&
            this.$store.state.batch.bulk_mailing.defaultLinkType &&
            this.$store.state.batch.bulk_mailing.defaultLinkType !== this.link.link_type
          ) {
            this.link.link_type = this.$store.state.batch.bulk_mailing.defaultLinkType
            this.updateLink()
          }

          if (this.first_load) {
            this.prepareBreadcrumbs();
            Timer.setTimer('Link', this.link.id);
            if (this.link.lead_status !== 'declined' && this.link.lead_status !== 'accepted' && this.$store.getters['auth/hasPermission']('link.update')) {
              this.setKeyBindings();
            }
          }
          this.first_load = false

          this.ready = true

        })
        .catch(() => {
          this.$message.error('An error occurred loading the link.');
        })
        .finally(() => {
          this.loading = false
        })


    },
    goTo(link) {
      window.location.href = link
    },
    async prepareBreadcrumbs() {

      await Promise.all([
        this.$store.dispatch('company/getCompany', this.$route.params),
          this.$store.dispatch('site/getSite', {siteId: this.link.site_id})
      ]).then(([company, site]) => {
        this.$store.dispatch('client/getClient', {clientId: site.client_id}).then((client) => {
          if (this.link.bulk_link_leads_batch_id) {
            this.$store.dispatch('batch/getBatch', {batchId: this.link.bulk_link_leads_batch_id}).then((batch) => {
              this.$store.dispatch('campaign/getCampaign', {campaignId: batch.campaign_id}).then((campaign) => {
                this.setBreadcrumbs(company, client, site, campaign, batch)
              })
            })
          } else {
            this.setBreadcrumbs(company, client, site)
          }
        })
      })


    },
    setBreadcrumbs(company, client, site, campaign, batch) {
      const linkType = this.isLead ? 'Leads' : 'Links';
      const breadcrumbs = [
        {
          path: '/',
          label: company.name
        },

        {
          path: `/company/${company.id}/clients`,
          label: 'clients'
        },
        {
          path: `/company/${company.id}/clients/${client.id}`,
          label: client.name
        },
        {
          path: `/company/${company.id}/clients/${client.id}?tab=sites`,
          label: 'sites'
        },
        {
          path: `/company/${company.id}/sites/${site.id}`,
          label: site.domain
        },
      ]
      if (campaign) {
        breadcrumbs.push(
          {
            path: `/company/${company.id}/sites/${site.id}?tab=campaigns`,
            label: 'campaigns'
          },
          {
            label: this.$store.state.campaign.campaign.name,
            path: `/company/${company.id}/campaigns/${campaign.id}`,
          }
        )
      }
      if (batch) {
        const batchUrl = `/company/${company.id}/batch/${batch.id}?tab=` + linkType.toLowerCase();
        breadcrumbs.push(
          {
            label: 'Batches',
            path: `/company/${company.id}/campaigns/${campaign.id}?tab=batches`,
          },
          {
            label: batch.legacy_id ? `${batch.name} (legacy: ${batch.legacy_id})` : batch.name,
            path: `/company/${company.id}/batch/${batch.id}`,
          },
          {
            label: linkType,
            path: batchUrl
          }
        )
      }
      breadcrumbs.push(
        {
          label: (linkType === 'Leads' ? 'Lead' : 'Link') + ` #${this.$route.params.linkId}`
        }
      )
      this.$store.commit('global/setBreadcrumbs', breadcrumbs);
    },
    setKeyBindings() {
      if (!this.keybindings_is_set) {
        const vm = this;
        window.addEventListener('keyup', function (e) {
          if (e.target.tagName !== 'INPUT' && e.target.tagName !== 'TEXTAREA') {
            let status = null
            let nice_name = null
            switch (e.key.toLowerCase()) {
              case 'a':
                status = 'accepted';
                nice_name = 'accept';
                break;
              case 'd':
                status = 'deferred';
                nice_name = 'defer';
                break;
              case 'p':
                status = 'declined_parked';
                nice_name = 'park';
                break;
              case 'r':
                status = 'declined';
                nice_name = 'reject';
                break;
              case 'o':
                if(vm.link.from_url) {
                  window.open(vm.getOutboundSafeUrl(vm.link.from_url))
                }
                break;
              default:
                break;
            }

            if (status) {
              vm.link.lead_status = status
              if (status !== 'accepted') {
                vm.updateLink()
              } else {
                vm.openAcceptPrompt()
              }
            }
          }
        })
        this.keybindings_is_set = true;
      }
    },
    resetContactForm() {
      this.mass_update_contact.firstname = null;
      this.mass_update_contact.lastname = null;
      this.mass_update_contact.email = null;
    },
    openAcceptPrompt() {
      this.resetContactForm();
      if (this.$store.state.batch.show_contact_form_on_accept && false) {
        this.show_mass_update_confirm = true;
      } else {
        this.updateLink()
      }
    },
    acceptPrompt() {
      this.$refs.mass_update_contact_form.validate((valid) => {
        if (valid) {
          this.updateLink()
        }
      })
    },
    updateLink() {
      this.loading = true;
      if (this.$store.state.batch.automatically_go_to_next_link && this.next_link && this.link.lead_status !== this.lead_status) {
        this.ready = false;
      }
      if (this.link.lead_status !== 'accepted') {
        this.resetContactForm();
      }
      this.$http.put(
        `/v1/companies/${this.$store.state.company.company.id}/links/${this.$route.params.linkId}`,
        {
          status: this.canUpdateStatus ? this.link.status : undefined,
          type: this.link.link_type,
          lead_status: this.link.lead_status,
          paypal_tx_id: this.link.paypal_tx_id,
          date_paid: this.link.date_paid,
          payment_status: this.link.payment_status,
          payment_address: this.link.paypal_account,
          payment_price: this.link.price,
          payment_currency: this.link.price_currency,
          reject_reason: ['rejected', 'dropped'].includes(this.link.status) ? this.link.RejectReason : undefined,
          payment_instructions: this.link.payment_additional_instructions || undefined,
          contact_firstname: this.mass_update_contact.firstname || undefined,
          contact_lastname: this.mass_update_contact.lastname || undefined,
          contact_email: this.mass_update_contact.email || undefined
        }
      ).then(() => {
        this.show_mass_update_confirm = false;
        this.$message.success('Successfully updated the link.');
        this.lead_component_key++;

        if (this.$store.state.batch.automatically_go_to_next_link && (this.next_link || this.link.bulk_link_leads_batch_id) && this.link.lead_status !== this.lead_status) {
          const redirect = this.next_link ? `/company/${this.$route.params.companyId}/links/${this.next_link.id}` : `/company/${this.$route.params.companyId}/batch/${this.link.bulk_link_leads_batch_id}`
          setTimeout(() => {
            window.location.href = redirect;
          }, 600)
        } else {
          setTimeout(() => {
            this.getLink()
            this.ready = false;
          }, 600);
        }
      })
        .catch((e) => {
          console.error(e)
          this.$message.error('An error occurred updating the link.');
          this.ready = false;
        })
        .finally(() => {
          this.loading = false
        })
    },
    updateAutoNextLink(val) {
      this.$store.commit('batch/update', ['automatically_go_to_next_link', val])
    },
    updateAddContactForm(val) {
      this.$store.commit('batch/update', ['show_contact_form_on_accept', val])
    },
    canSetThisStatus(value) {
      if (['awaiting_payment', 'active', 'active_renewal'].includes(value) && !this.$store.getters['auth/hasPermission']('payment.update-status')) {
        return true;
      }

      if (['client_approved', 'client_declined'].includes(value) && !this.$store.getters['auth/hasPermission']('lead.update-client-status')) {
        return true;
      }
      return false;
    },
    generateNavigationLinks(id) {
      let baseUrl = `/company/${this.$route.params.companyId}/${this.isLead ? 'leads' : 'links'}/${id}`
      if(this.$route.query.bulk) {
        baseUrl += '?' + new URLSearchParams(this.prepBulkParams({})).toString()
      }
      return baseUrl;
    },
    prepBulkParams(params) {
      params.bulk = true
      if(this.$route.query.bulk_status) params.bulk_status = this.$route.query.bulk_status
      if(this.$route.query.bulk_client_ids) params.bulk_client_ids = this.$route.query.bulk_client_ids
      if(this.$route.query.bulk_site_ids) params.bulk_site_ids = this.$route.query.bulk_site_ids
      if(this.$route.query.bulk_project_ids) params.bulk_project_ids = this.$route.query.bulk_project_ids
      if(this.$route.query.bulk_target_ids) params.bulk_target_ids = this.$route.query.bulk_target_ids
      if(this.$route.query.bulk_campaign_ids) params.bulk_campaign_ids = this.$route.query.bulk_campaign_ids
      if(this.$route.query.bulk_batch_ids) params.bulk_batch_ids = this.$route.query.bulk_batch_ids
      if(this.$route.query.bulk_email_accounts) params.bulk_email_accounts = this.$route.query.bulk_email_accounts
      if(this.$route.query.bulk_type) params.bulk_type = this.$route.query.bulk_type
      return params;
    }
  }
}
</script>

<style scoped>

</style>
