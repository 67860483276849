<template>
  <div v-loading="loading"
       :class="[
         loading ? 'h-10' : ''
       ]"
       class="lead_relationship_container">
    <div v-for="(type, i) in ['email', 'domain']" :key="i">
      <trieste-card :title="`Related by ${type}`" :collapsable="true" :collapsed="collapsed" v-if="getRelationType(type).length">
      <div class="p-0">
        <table>
          <thead>
          <tr>
            <th class="p-1 text-sm-r text-left">
               <span class="flex gap-x-1">
              <span>ID</span>
                <span  class="text-black text-sm" @click="setSort('id', 'asc')">
                            <i class="el-icon-arrow-up"></i>
                </span>
                <span class="text-black text-sm" @click="setSort('id', 'desc')">
                            <i class="el-icon-arrow-down"></i>
                </span>
              </span>
            </th>
            <th class="p-1 text-sm-r text-left">
              <span class="flex gap-x-1">
              <span>Emails</span>
                <span  class="text-black text-sm" @click="setSort('TotalEmails', 'asc')">
                            <i class="el-icon-arrow-up"></i>
                </span>
                <span class="text-black text-sm" @click="setSort('TotalEmails', 'desc')">
                            <i class="el-icon-arrow-down"></i>
                </span>
              </span>
            </th>
            <th class="p-1 text-left text-sm-r w-20">
              <span class="flex gap-x-1">
              <span>Status</span>
                <span  class="text-black text-sm" @click="setSort('status', 'asc')">
                            <i class="el-icon-arrow-up"></i>
                </span>
                <span class="text-black text-sm" @click="setSort('status', 'desc')">
                            <i class="el-icon-arrow-down"></i>
                </span>
              </span>
            </th>
            <th class="p-1 text-sm-r text-left">
               <span class="flex gap-x-1">
              <span>Client</span>
                <span  class="text-black text-sm" @click="setSort('project_name', 'asc')">
                            <i class="el-icon-arrow-up"></i>
                </span>
                <span class="text-black text-sm" @click="setSort('project_name', 'desc')">
                            <i class="el-icon-arrow-down"></i>
                </span>
              </span>
            </th>
            <th class="p-1 text-sm-r text-right">
               <span class="flex gap-x-1">
              <span>Updated</span>
                <span  class="text-black text-sm" @click="setSort('last_email_sent', 'asc')">
                            <i class="el-icon-arrow-up"></i>
                </span>
                <span class="text-black text-sm" @click="setSort('last_email_sent', 'desc')">
                            <i class="el-icon-arrow-down"></i>
                </span>
              </span>
            </th>
          </tr>
          </thead>
          <tbody>
          <tr
            :class="[
              i % 2 ? 'bg-gray-100': '',
              'cursor-pointer'
            ]"
            @click="onRowClick(link.id)"
            :key="link.id" v-for="(link,i) of getRelationType(type)">
            <td class="p-1 text-sm-r">
             <div class="flex items-center">
               <span class="font-semibold">
                           <a :href="`/company/${$route.params.companyId}/leads/${link.id}`">{{link.id}}</a>

               </span>
             </div>
            </td>
            <td class="p-1 text-sm-r">
              <div class="flex items-center">
                <span class="ml-2 flex items-center">
              <i class="el-icon-message text-lg" /> <span class="ml-1">({{ link.TotalEmails }})</span>
               </span>
              </div>
            </td>
            <td class="p-1 text-sm-r w-20">
              <span class="px-1 rounded truncate inline-block" :class="['dropped', 'rejected'].includes(link.status) ? 'bg-red-200 text-red-700' : 'bg-green-200 text-green-700'">
              {{ getNiceStatusName(link.status) }}
              </span>
            </td>
            <td class="p-1 text-sm-r">
              <div class="truncate" v-if="link.client">
                <a class="font-bold" :href="`/company/${$route.params.companyId}/clients/${link.client.id}`">{{ link.client.name }}</a>
                <span v-if="link.project"> | {{ link.project.name }}</span>
                <span class="block -mt-1" v-if="link.site">{{ link.site.domain }}</span>
              </div>
            </td>
            <td class="p-1 text-sm-r text-right">
              <span v-if="link.LastSentEmail && link.LastSentEmail.date">
                <span>{{ getMonthDifference(link.LastSentEmail.date)}} Month(s)</span>
                </span>
              <span v-else>
                  <span>{{ getMonthDifference(link.updated_at || link.created_at)}} Month(s)</span>
                </span>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      </trieste-card>
    </div>
  </div>
</template>
<script>
  import moment from 'moment';
  import collect from 'collect.js'
  export default {
    props: {
      value: {
        type: Object,
        default: () => {}
      },
      collapsed: {
        type: Boolean,
        default: false
      },
      id: {
        type: [String, Number]
      }
    },
    data() {
      return {
        data: [],
        loading: true,
        same_domain_active_links: [],
        sort: {
          field: 'last_email_sent',
          order: 'asc'
        }
      }
    },
    mounted() {
      this.getData()
    },
    methods: {
      setSort(field, order){
        this.sort.field = field;
        this.sort.order = order;
      },
      onRowClick(id){
       // window.location
       window.location = `/company/${this.$route.params.companyId}/leads/${id}`;
      },
      getMonthDifference(d) {
        return Math.round(moment().diff(d, 'months', true))
      },
      getNiceStatusName(status) {
        const find = this.$store.state.link.statuses.find(s => s.value === status)
        if(find) {
          return find.label
        }
        return status
      },
      getRelationType (type) {
        const key = type === 'email' ? 'related_by_email' : 'related_by_domain'
        let items = this.data.filter(d => !!d[key])
        return items.sort(( a, b )  => {


          if(['status', 'project_name'].indexOf(this.sort.field) > -1){
            if(this.sort.order === 'asc'){
              return a.status.localeCompare(b.status)
            }
            return b.status.localeCompare(a.status)
          }

          if(this.sort.order === 'asc'){
            return a[this.sort.field] - b[this.sort.field]
          }
          return b[this.sort.field] - a[this.sort.field]
        })
      },
      getSortedFields(items){
        let c = collect(items);
        if(this.sort.order === 'desc'){
          c.sortByDesc(this.sort.field)
        }else{
          c.sortBy(this.sort.field)
        }
        return c.toArray();
      },

      setSameActiveDomains() {
        this.same_domain_active_links = this.getRelationType('domain').filter(d => d.status === 'active' && d.client_id === this.value.client_id)
        this.$emit('same-domains', this.same_domain_active_links)
      },
      getData() {
        this.loading = true
        const path = `/v1/companies/${this.$store.state.company.company.id}/links/${this.id}/relationship`
        this.$http.get(path).then((r) => {
          this.data = r.data.data
          this.data = this.data.map((x) => {
            console.log(x)
            x.project_name = x.project && x.project.name ? x.project.name : null;
           x.last_email_sent = x.LastSentEmail && x.LastSentEmail.date ? moment(x.LastSentEmail.date).unix() : 0;
            return x;
          })
          this.setSameActiveDomains();
        })
        .catch(() => {
          this.$message.error('Failed to get the latest stats.')
        })
        .finally(() => {
          this.loading = false
        })
      }
    }
  }
</script>
<style lang="scss">
.text-sm-r {
  font-size: 10px;
}
.lead_relationship_container {
  .el-badge__content {
    &.is-fixed {
      top: initial;
      bottom: -10px;
    }
  }
}
</style>
