<template>
  <div class="create_content_component" v-loading="loading" ref="top">
    <div class="page-title">
      <h1>
        <span v-if="!$store.state.content.content.id">Create content</span>
        <span v-else>
            {{
            !!$store.state.content.content.title && !!$store.state.content.content.title.length ? $store.state.content.content.title : 'untitled'
          }}
            <span class="faded-text">
              <span class="faded-strike">/</span>
              Content
            </span>
          </span>
      </h1>
    </div>
    <div class="columns">
      <div class="column w-full xl:w-3/5">
        <trieste-card title="Content" v-if="ready" :heiader-height="null">
          <template v-slot:header-right>
            <div class="p-r-0 -mb-6 -mr-2 text-right">
              <project-and-target-form form-label-width="110px" :project-id.sync="new_content.project_id" inline
                                       :target-id.sync="new_content.site_target_id" @target-updated="updateTarget"
                                       :show-project-select="false"
                                       show-site-select
                                       @project-updated="updateProject" ref="patform" :is-project-enabled="false"
                                       @loaded="loadedProjectAndTargetForm"/>
            </div>
          </template>
          <div class="p-4">
            <el-form label-width="150px">
              <el-form-item label="ID" prop="id">
                {{ new_content.id || 'N/A' }}
              </el-form-item>
            </el-form>
            <el-form :rules="isSubmittingForReview ? full_rules : basic_rules" :model="new_content" label-width="150px"
                     ref="contentForm">
              <el-form-item label="Linkdev" prop="created_by_id">
                <el-select v-model="new_content.created_by_id" filterable class="w-full" :disabled="!canEditContent">
                  <el-option v-for="(cu, i) in company_users" :key="'cu'+i" :value="cu.id"
                             :label="`${cu.firstname} ${cu.lastname}`"/>
                </el-select>
              </el-form-item>
              <el-form-item label="Owner" prop="owner_id">
                <el-select v-model="new_content.owner_id" filterable class="w-full" :disabled="!canEditContent">
                  <el-option v-for="(cu, i) in company_users" :key="'cu'+i" :value="cu.id"
                             :label="`${cu.firstname} ${cu.lastname}`"/>
                </el-select>
              </el-form-item>
              <el-form-item v-for="(reviewer, rKey) in getReviewers()" :label="'Reviewer ' + (rKey > 0 ? rKey + 1 : '')"
                            :key="rKey">
                {{ `${reviewer.firstname} ${reviewer.lastname} (${reviewer.email})` }}
              </el-form-item>
              <el-form-item label="Review date" prop="review_date" v-if="new_content.review_date && false">
                <el-date-picker v-model="new_content.review_date" format="dd MMM yyyy" value-format="yyyy-MM-dd"
                                :disabled="!canEditContent"/>
              </el-form-item>
              <el-form-item label="Title" prop="title">
                <el-input type="text" v-model="new_content.title" :disabled="!canEditContent"/>
              </el-form-item>
              <el-form-item label="Anchor" prop="anchor">
                <el-input type="text" v-model="new_content.anchor" :disabled="!canEditContent"/>
              </el-form-item>
              <el-form-item label="Body" prop="body">
                <div class="flex flex-row justify-end items-center">
                  <el-switch v-model="html_mode" active-text="WYSIWYG Editor" inactive-text="HTML Mode"></el-switch>
                  <div class="inline-block mb-1 ml-1" v-if="currentTarget && new_content.anchor">
                    <el-button size="mini" type="primary" @click="appendDoNotSendPlaceholder"> Add DO NOT SEND
                      placeholder
                    </el-button>
                  </div>
                  <div v-if="site_targets.length" class="ml-2">
                    <el-select size="mini" placeholder="Targets" v-model="selectedTargetInput"
                               @change="addTargetLinkShortcut" filterable>
                      <el-option v-for="(opt, i) in site_targets" :key="i" :value="opt.id" :label="`${opt.name} (${opt.status})`"/>
                    </el-select>
                  </div>
                </div>
                <div class="mb-2" v-if="failed_target_in_content">
                  <el-alert type="error" :closable="false">
                    The site target url {{ failed_target_in_content }} is suspended and was found in the body.
                  </el-alert>
                </div>

                <textarea v-model="new_content.body" :readonly="!canEditContent" v-show="!html_mode"
                          class="border w-full" style="height: 400px; outline: none; padding: 0.5em 1em;" ref="plainContentEditor"></textarea>

                <vue-editor v-if="html_mode" v-model="new_content.body"
                            @ready="(quill) => { this.setEditor(quill); this.textChanged() }"
                            @selection-change="quillTextPositionChanged"
                            @text-change="textChanged"
                            :editor-options="{ }"
                            ref="editor"
                            :disabled="!canEditContent" :editorToolbar="getEditorToolbar()" />
                <div class="flex flex-row items-start my-4">
                  <div>
                    <span class="text-xs text-gray-600">
                      Words count: <span class="font-bold">{{ (character_count.words || 0).toLocaleString() }}</span>
                      Letter count: <span class="font-bold">{{ (character_count.letters || 0).toLocaleString() }}</span>
                    </span>
                  </div>
                  <div class="ml-auto">
                    <a :href="`/company/${$route.params.companyId}/content/${$route.params.contentId}?full_screen=true`" target="_blank" class="inline-block mr-1">
                      <el-button type="primary" size="mini">
                        Preview
                      </el-button>
                    </a>
                    <el-button type="primary" size="mini" @click.prevent="downloadContent">Download attachment file
                    </el-button>
                  </div>
                </div>
              </el-form-item>
              <el-form-item label="Type" prop="item_type">
                <el-select v-model="new_content.item_type" class="w-full" :disabled="!canEditContent">
                  <el-option v-for="(item, a) in item_types" :key="'item_types_' + a" :label="item.label"
                             :value="item.value"/>
                </el-select>
              </el-form-item>
              <el-form-item label="Source" prop="source">
                <el-select v-model="new_content.source" class="w-full" :disabled="!canEditContent">
                  <el-option v-for="(item, a) in source_types" :key="'source_type_' + a" :label="item.label"
                             :value="item.value"/>
                </el-select>
              </el-form-item>
              <el-form-item label="status" prop="status">
                <el-select v-model="new_content.status" class="w-full"
                           :disabled="!$store.getters['auth/hasPermission']('content.manual-status.update')">
                  <el-option v-for="(item, a) in status_types" :key="'status_type_' + a" :label="item.label"
                             :value="item.value"/>
                </el-select>
              </el-form-item>
              <el-form-item label="Client note" prop="client_note"
                            v-if="new_content.status === 'awaiting_client_approval'">
                <el-input type="textarea" v-model="new_content.client_note" resize="none" rows="5"/>
              </el-form-item>
              <el-form-item label="Declined reason" prop="declined_reason" v-if="new_content.client_reject_reason">
                {{ new_content.client_reject_reason }}
              </el-form-item>
              <el-form-item label="urgent" prop="urgent">
                <el-switch v-model="new_content.urgent" :disabled="!canEditContent"/>
              </el-form-item>
              <el-form-item label="Billing count" prop="urgent"
                            v-if="$store.getters['auth/hasPermission']('content.billing_count.update')">
                <el-select v-model="new_content.billing_count" :disabled="!canEditContent">
                  <el-option :value="1" label="1"/>
                  <el-option :value="2" label="2"/>
                </el-select>
              </el-form-item>
            </el-form>
          </div>
          <template v-slot:footer v-if="canAtleastEditStatus || canEditContent">
            <div class="flex flex-row items-center">
              <el-button type="danger" size="small" @click="deleteContent" icon="el-icon-delete"
                         v-if="$store.getters['auth/hasPermission']('content.delete')">Delete
              </el-button>
              <div class="ml-auto">
                <el-button type="primary" size="small"
                           icon="el-icon-edit-outline"
                           @click="openReviewForm"
                           v-if="canAtleastEditStatus && (canPublish || (!canPublish && new_content.status !== 'editor_review'))">
                  Submit for review
                </el-button>
                <el-button type="primary" size="small"
                           icon="el-icon-edit-outline"
                           @click="showReturnDialog = true"
                           v-if="canAtleastEditStatus && canPublish && new_content.status === 'editor_review'">Return
                </el-button>
                <el-button type="primary" size="small"
                           icon="el-icon-s-custom"
                           @click="quickContentStatusSave('awaiting_client_approval', true)"
                           v-if="canAtleastEditStatus && canPublish && new_content.status !== 'awaiting_client_approval'">
                  Submit for Client Approval
                </el-button>
                <el-button type="primary" size="small"
                           icon="el-icon-check"
                           @click="quickContentStatusSave('published', true)"
                           v-if="canAtleastEditStatus && canPublish && new_content.status !== 'published'">Publish
                </el-button>
                <el-button type="primary" size="small" icon="el-icon-edit-outline"
                           @click="quickContentStatusSave(new_content.status, false)">Save
                </el-button>
              </div>
            </div>
          </template>
        </trieste-card>
      </div>
      <div class="column w-full  xl:w-2/5">
        <div class="columns">
          <div class="column w-full" v-if="new_content.site_link">
            <trieste-card title="Project Requirements" collapsable collapsed>
              <requirements :project="currentProject" :site-target="currentTarget" v-if="new_content.site_link"/>
            </trieste-card>
          </div>
          <div class="column w-full">
            <revisions v-if="new_content.id" :content-id="new_content.id" ref="revision" @set="setRevisionContent"
                       :current-title="new_content.title" :current-body="new_content.body"/>
            <trieste-card title="Attachments">
              <div class="p-4">
                <div v-if="mode === 'create'">
                  <p class="text-sm"><i class="el-icon-warning"/> You will be able to upload attachments once you save
                    the content</p>
                </div>
                <div v-else>
                  <p class="text-sm mb-4 text-gray-500" v-if="!currentAttachments.length">The are currently no
                    attachments for this content. You can upload one or more below. </p>
                  <div class="-mx-4 -mt-4" v-else>
                    <el-table :data="currentAttachments" class="mb-4">
                      <el-table-column label="Filename" prop="original_filename" width="250px">
                        <template slot-scope="scope">
                          <i class="el-icon-download mr-1"></i><a @click.prevent="downloadAttachment(scope.row)"
                                                                  class="cursor-pointer">{{
                            scope.row.original_filename
                          }}</a>
                        </template>
                      </el-table-column>
                      <el-table-column width="250px" fixed="right">
                        <template slot-scope="scope">
                          <div class="text-right">
                            <el-button size="mini" type="primary" icon="el-icon-download"
                                       @click="downloadAttachment(scope.row)"
                                       v-if="$store.getters['auth/hasPermission']('content.read')">Download
                            </el-button>
                            <el-button size="mini" type="danger" icon="el-icon-delete"
                                       @click="deleteAttachment(scope.row)" v-if="canEditContent && canDeleteFile">
                              Delete
                            </el-button>
                          </div>
                        </template>
                      </el-table-column>
                    </el-table>
                  </div>
                  <el-upload
                    class="upload-demo"
                    ref="upload"
                    action=""
                    :on-change="watchFileChange"
                    :auto-upload="false">
                    <el-button slot="trigger" size="small" type="primary" v-if="canEditContent">select file</el-button>
                    <el-button style="margin-left: 10px;" size="small" type="success" @click="uploadContentAttachments"
                               v-if="canEditContent && false">upload to server
                    </el-button>
                  </el-upload>
                </div>
              </div>
            </trieste-card>
          </div>
          <div class="column w-full">
            <trieste-card title="Content Information">
              <div class="p-4">
                <trieste-info-column label="description"
                                     :value="replaceLineBreaks($store.state.content.content.description)" raw/>
                <trieste-info-column label="Guidelines"
                                     :value="replaceLineBreaks($store.state.content.content.guidelines)" raw/>
                <trieste-info-column label="Guidelines keywords"
                                     :value="replaceLineBreaks($store.state.content.content.guidelines_keywords)" raw/>
                <trieste-info-column label="Guidelines link allowed in"
                                     :value="replaceLineBreaks($store.state.content.content.guidelines_link_allowed_in)"
                                     raw/>
                <trieste-info-column label="Guidelines url"
                                     :value="replaceLineBreaks(getOutboundSafeUrl($store.state.content.content.guidelines_url))"
                                     raw/>
                <trieste-info-column label="Guidelines word count" :value="$store.state.content.content.words_count"
                                     number/>
                <trieste-info-column label="Last saved word count"
                                     :value="$store.state.content.content.actual_word_count" number/>
                <trieste-info-column label="Owner">
                  <template v-if="findOwner.firstname">
                    <span class="text-sm font-light">{{ `${findOwner.firstname} ${findOwner.lastname}` }}</span>
                  </template>
                  <template v-else>
                    <span class="text-gray-400 text-sm">N/A</span>
                  </template>
                </trieste-info-column>
                <trieste-info-column label="Lead">
                  <a
                    :href="`/company/${$route.params.companyId}/leads/${new_content.site_link.id}`" target="_blank"
                    v-if="new_content.site_link"><span class="text-sm font-bold">{{
                      new_content.site_link.id
                    }}</span></a>
                  <span class="text-gray-400 text-sm" v-else>N/A</span>
                </trieste-info-column>
                <trieste-info-column label="Lead status">
                  <a
                    :href="`/company/${$route.params.companyId}/leads/${new_content.site_link.id}`" target="_blank"
                    v-if="new_content.site_link"><span class="text-sm font-bold">{{
                      new_content.site_link.status.replace(/_/g, ' ')
                    }}</span></a>
                  <span class="text-gray-400 text-sm" v-else>N/A</span>
                </trieste-info-column>
                <trieste-info-column label="Site URL">
                  <a
                    :href="getOutboundSafeUrl(new_content.site_link.from_url)" target="_blank"
                    v-if="new_content.site_link"><span class="text-sm font-bold">{{
                      new_content.site_link.from_url
                    }}</span></a>
                  <span class="text-gray-400 text-sm" v-else>N/A</span>
                </trieste-info-column>
              </div>
            </trieste-card>
          </div>
          <div class="column w-1/2 xl:w-full" v-if="new_content.id">
            <notes-widget resource-type="ContentItem" :resource-id="new_content.id" v-if="new_content.id"
                          :default-assignee-id="new_content.owner_id"/>
          </div>
          <div class="column w-1/2 xl:w-full" v-if="new_content.attach_events && new_content.attach_events.length">
            <trieste-card title="Attach History" collapsable collapsed>
              <el-table :data="new_content.attach_events">
                <el-table-column label="Date" prop="created_at">
                  <template slot-scope="scope">
                    {{ scope.row.created_at | momentLocal("MMMM DD, YYYY hh:mm A Z") }}
                  </template>
                </el-table-column>
                <el-table-column label="Action" prop="attached">
                  <template slot-scope="scope">
                    {{ scope.row.attached ? 'attached' : 'detached' }}
                  </template>
                </el-table-column>
                <el-table-column label="Lead">
                  <template slot-scope="scope">
                    <a
                      :href="`/company/${$route.params.companyId}/leads/${scope.row.site_link.id}`"
                      v-if="scope.row.site_link"
                      target="_blank"
                    >{{ scope.row.site_link.id }}</a>
                    <span class="text-gray-400 text-sm" v-else>N/A</span>
                  </template>
                </el-table-column>
                <el-table-column label="User">
                  <template slot-scope="scope">
                    <span v-if="scope.row.user">{{ `${scope.row.user.firstname} ${scope.row.user.lastname}` }}</span>
                    <span class="text-gray-400 text-sm" v-else>N/A</span>
                  </template>
                </el-table-column>
              </el-table>
            </trieste-card>
          </div>
          <div class="column w-1/2 xl:w-full" v-if="new_content.review_events && new_content.review_events.length">
            <trieste-card title="Review History">
              <el-table :data="new_content.review_events">
                <el-table-column label="Date" prop="created_at">
                  <template slot-scope="scope">
                    <div v-if="scope.row.created_at">
                      {{ scope.row.created_at | momentLocal("MMMM DD, YYYY hh:mm A Z") }}
                    </div>
                    <div v-else>
                      -
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="status" prop="to_status">
                  <template slot-scope="scope">
                    {{ scope.row.to_status.replace(/_/g, ' ') }}
                  </template>
                </el-table-column>
                <el-table-column label="User">
                  <template slot-scope="scope">
                    <span v-if="scope.row.user">{{ `${scope.row.user.firstname} ${scope.row.user.lastname}` }}</span>
                    <span class="text-gray-400 text-sm" v-else>N/A</span>
                  </template>
                </el-table-column>
              </el-table>
            </trieste-card>
          </div>
        </div>
      </div>
    </div>
    <trieste-dialog :custom-class="'content-preview-dialog ' +  (showContentPreview ? ' full' : '')" :show.sync="showContentPreview" width="100%" height="100%"
                    title="Preview" max-body-height="90vh">
      <div class="ql-snow" v-if="showContentPreview">
        <div class="bg-white p-4 text-black ql-editor break-normal"
             style="max-height: initial;height: calc(100vh - 9.5em) " v-html="cleanedContentPreview()"></div>
      </div>
      <template v-slot:footer-right v-if="!forced_preview">
        <el-button type="danger" size="small" icon="el-icon-close" @click="showContentPreview=false">Close</el-button>
      </template>
    </trieste-dialog>
    <trieste-dialog :show.sync="showSubmitReviewForm" title="Submit for review">
      <div class="bg-white p-4 text-black">
        <el-form :rules="submit_review_rules" :model="submit_review" label-width="150px" ref="reviewForm">
          <el-form-item label="Owner" prop="reviewer">
            <el-select v-model="submit_review.reviewer" filterable class="w-full" multiple>
              <el-option v-for="(cu, i) in company_users" :key="'cu'+i" :value="cu.id"
                         :label="`${cu.firstname} ${cu.lastname} (${cu.email})`"/>
            </el-select>
          </el-form-item>
          <el-form-item label="Review date" prop="date" v-if="false">
            <el-date-picker v-model="submit_review.date" format="dd MMM yyyy" value-format="yyyy-MM-dd"/>
          </el-form-item>
        </el-form>
      </div>
      <template v-slot:footer-left>
        <el-button type="danger" size="small" icon="el-icon-close" @click="showSubmitReviewForm=false">Cancel
        </el-button>
      </template>
      <template v-slot:footer-right>
        <el-button type="danger" size="small" icon="el-icon-edit-outline" @click="submitReviewRequest">Submit
        </el-button>
      </template>
    </trieste-dialog>
    <trieste-dialog :show.sync="showReturnDialog" title="Return content">
      <div class="bg-white p-4 text-black">
        <el-form :rules="submit_return_rules" :model="returnForm" label-width="150px" ref="returnForm">
          <el-form-item label="Content Writer" prop="user">
            <el-select v-model="returnForm.user" filterable class="w-full">
              <el-option v-for="(cu, i) in company_users" :key="'cu'+i" :value="cu.id"
                         :label="`${cu.firstname} ${cu.lastname} (${cu.email})`"/>
            </el-select>
          </el-form-item>
          <el-form-item label="Note" prop="note">
            <el-input type="textarea" v-model="returnForm.note"/>
          </el-form-item>
        </el-form>
      </div>
      <template v-slot:footer-left>
        <el-button type="danger" size="small" icon="el-icon-close" @click="showReturnDialog=false">Cancel</el-button>
      </template>
      <template v-slot:footer-right>
        <el-button type="danger" size="small" icon="el-icon-edit-outline" @click="submitReturnRequest">Submit
        </el-button>
      </template>
    </trieste-dialog>
  </div>
</template>
<script>
import Requirements from "@/components/views/links/Requirements";

const FileDownload = require('js-file-download');
import Timer from '@/utils/timer';
import NotesWidget from "@/components/notes";
import Revisions from "@/components/views/Content/Revisions";
import ProjectAndTargetForm from "@/components/views/global/ProjectAndTargetForm";
import { formatResponseErrorMessage } from "@/utils/helpers";

let failed_target_timer_check = null;
export default {
  components: {ProjectAndTargetForm, Revisions, Requirements, NotesWidget},
  metaInfo() {
    return {
      title: () => `Content ${this.$route.params.contentId}`
    }
  },
  data() {
    const targetValidation = (rule, value, callback) => {
      if (this.next_content_status === 'published') {
        const st = this.$refs.patform.getSelectedTarget();
        if (st) {
          if (value.indexOf(`href='${st.to_url}'`) === -1 && value.indexOf(`href="${st.to_url}"`) === -1) {
            return callback(new Error('The target URL was not found in the content body.'))
          }
        } else {
          return callback(new Error('Please select a site target before you can publish this content item.'))
        }

        // check for blank urls
        if (/<a[^>]+>\s*<\/a>/g.test(value)) {
          return callback(new Error('A link tag without an anchor was detected.'))
        }
      }
      return callback();
    }
    return {
      mode: 'create',
      first_quill_load: false,
      html_mode: true,
      loading: false,
      ready: false,
      showReturnDialog: false,
      forced_preview: this.$route.query.full_screen,
      returnForm: {
        user: null,
        note: ''
      },
      company_users: [],
      showContentPreview: false,
      isSubmittingForReview: false,
      next_content_status: null,
      character_count: {
        words: 0,
        letters: 0
      },
      new_content: {
        id: null,
        title: '',
        body: '',
        description: '',
        item_type: null,
        source: null,
        status: null,
        anchor: '',
        billing_count: 1
      },
      submit_review: {
        reviewer: null,
        date: null
      },
      site_targets: [],
      submit_review_rules: {
        reviewer: [
          {required: true, trigger: 'blur', message: 'Please select a reviewer'}
        ],
        date: [
          {required: true, trigger: 'blur', message: 'Please select a review by date'}
        ]
      },
      submit_return_rules: {
        user: [
          {required: true, trigger: 'blur', message: 'Please select a writer'}
        ]
      },
      basic_rules: {
        description: [
          {required: true, min: 3, trigger: 'blur', message: 'Please enter a description'}
        ],
        item_type: [
          {required: true, trigger: 'blur', message: 'Please select a type'}
        ],
        source: [
          {required: true, trigger: 'blur', message: 'Please select a source'}
        ],
        status: [
          {required: true, trigger: 'blur', message: 'Please select a status'}
        ]
      },
      full_rules: {
        title: [
          {required: true, min: 3, trigger: 'blur', message: 'Please enter a title'}
        ],
        anchor: [
          {required: true, min: 3, trigger: 'blur', message: 'Please enter a anchor'}
        ],
        body: [
          {required: true, min: 3, trigger: 'blur', message: 'Please enter a body'},
          {required: true, validator: targetValidation, trigger: 'blur'}
        ],
        description: [
          {required: true, min: 3, trigger: 'blur', message: 'Please enter a description'}
        ],
        item_type: [
          {required: true, trigger: 'blur', message: 'Please select a type'}
        ],
        source: [
          {required: true, trigger: 'blur', message: 'Please select a source'}
        ],
        status: [
          {required: true, trigger: 'blur', message: 'Please select a status'}
        ]
      },
      status_types: [
        {
          value: 'assigned',
          label: 'Assigned'
        },
        {
          value: 'cancelled',
          label: 'Cancelled'
        },
        {
          value: 'draft',
          label: 'Draft'
        },
        {
          value: 'published',
          label: 'Published'
        },
        /*{
          value: 'waiting_for_tier_2_approval',
          label: 'Waiting For Tier 2 Approval'
        },*/
        {
          value: 'new',
          label: 'New'
        },
        /*{
          value: 'waiting_for_tier_1_approval',
          label: 'Waiting For Tier 1 Approval'
        },*/
        {
          value: 'awaiting_client_approval',
          label: 'Awaiting Client Approval'
        },
        /*{
          value: 'pending',
          label: 'Pending'
        },*/
        {
          label: 'Editor Review',
          value: 'editor_review'
        },
        {
          label: 'On Hold',
          value: 'on_hold'
        },
        /*{
          label: 'Revision',
          value: 'revision'
        },*/
        {
          label: 'Returned',
          value: 'returned'
        },
        {
          label: 'Client approved',
          value: 'client_approved'
        },
        {
          label: 'Client declined',
          value: 'client_declined'
        }
      ],
      source_types: [
        {
          value: 'internal',
          label: 'Internal'
        },
        {
          value: 'textbroker',
          label: 'Textbroker'
        },
        {
          value: 'internal_not_validate_link',
          label: 'Internal Not Validate Link'
        }
      ],
      item_types: [
        {
          value: 'title_and_content',
          label: 'Title and Content'
        },
        {
          value: 'title_only',
          label: 'Title Only'
        },
        {
          value: 'content_only',
          label: 'Content Only'
        }
      ],
      currentAttachments: [],
      editorQuill: null,
      showSubmitReviewForm: false,
      currentProject: null,
      currentTarget: null,
      selectedTargetInput: null,
      quillCursorPosition: null,
      quillLastSelectedText: '',
      quillLastSelectedTextRange: {index: 0, length: 0},
      plainCursorPosition: null,
      failed_target_in_content: null,
    }
  },
  computed: {
    canPublish() {
      return this.$store.getters['auth/hasPermission']('content.can.publish')
    },
    findCreator() {
      if (this.new_content.created_by_id && this.company_users.length) {
        return this.company_users.find(i => i.id === this.new_content.created_by_id) || {}
      }
      return {}
    },
    findOwner() {
      if (this.new_content.owner_id && this.company_users.length) {
        return this.company_users.find(i => i.id === this.new_content.owner_id) || {}
      }
      return {}
    },
    findReviewer() {
      if (this.new_content.review_user && this.company_users.length) {
        return this.company_users.find(i => i.id === this.new_content.review_user) || null
      }
      return null
    },
    canEditContent() {
      return this.$store.getters['auth/hasPermission']('content.update')
    },
    canAtleastEditStatus() {
      const can = this.$store.getters['auth/hasPermission']('other.content.status.update')
      if (!can && this.$store.getters['auth/hasPermission']('own.content.status.update')) {
        if (this.$store.state.auth.user.id === this.new_content.owner_id) {
          return true
        }
      }
      return can === true
    },
    canDeleteFile() {
      return this.$store.getters['auth/hasPermission']('can.delete.all.content.files') ||
        this.$store.state.auth.user.id === this.new_content.owner_id
    }
  },
  mounted() {
    this.loading = true;
    this.$store.dispatch('company/getCompany', this.$route.params).then((company) => {
      const breadcrumbs = [
        {
          path: '/',
          label: company.name
        },
        {
          path: `/company/${company.id}/content`,
          label: 'Content'
        }
      ];

      if (this.$route.params.contentId) {
        this.$store.dispatch('content/getContent', this.$route.params).then(() => {
          breadcrumbs.push({
            label: this.$store.state.content.content.title || 'untitled'
          });
          this.$store.commit('global/setBreadcrumbs', breadcrumbs);
          this.mode = 'edit';
          this.setContentFromState();
          this.getContentAttachments();
          Timer.setTimer('Content', this.new_content.id);
          this.getCompanyUsers();
          this.ready = true;
          this.loading = false;
          this.bindPlainCursorPosition();
          if(this.forced_preview) {
            this.showContentPreview = true;
          }
          this.setFailedTargetTimerCheck();
        })
      } else {
        breadcrumbs.push({label: 'Create content'});
        this.$store.commit('global/setBreadcrumbs', breadcrumbs);
        this.ready = true;
        this.loading = false;
        this.bindPlainCursorPosition();
      }
    })
  },
  methods: {
    loadedProjectAndTargetForm() {
      this.currentTarget = this.$refs.patform.getTarget();
      this.site_targets = this.$refs.patform.getTargetList();
    },
    appendDoNotSendPlaceholder() {
      if (this.currentTarget && this.new_content.anchor) {
        const content = `<p>===\nDO NOT SEND: <a href='${this.currentTarget.to_url}'>${this.new_content.anchor}</a></p>`

        this.new_content.body += content;
      }
    },
    replaceLineBreaks(text = '') {
      return (text + '').replace(/\n/g, '<br />')
    },
    cleanedContentPreview() {
      return this.new_content.body.replace(/(?:(<p>)|(<br\s*?>)|\s)*===[(<p>)(<br\s*\/?>)(<\\p>)\s]*DO NOT SEND: .*\/a(?:>|&gt;)(?:(<\/p>)|(<br\s*?>))*/gmis, '')
    },
    watchFileChange() {
      if (this.$refs.upload.uploadFiles.length && this.canEditContent) {
        this.uploadContentAttachments()
      }
    },
    removeDoNotSend(text) {
      const text_lines = text.split('\n');
      let i = text_lines.length - 1;
      let found_do_not_send = false;
      let found_do_not_end_lines = false;
      while (i >= 0) {
        const line = text_lines[i].toLowerCase().replace(/[^\w\s:=]/g, '').trim();
        i--;
        //console.log({ line })
        if(line !== '') {
          if(line.startsWith('do not send:')) {
            found_do_not_send = true;
            text_lines.splice(i, 1);
            if(found_do_not_end_lines) { break; } else continue;
          }
          if(line.match(/^=*$/)) {
            found_do_not_end_lines = true;
            text_lines.splice(i, 1);
            if(found_do_not_send) { break; } else continue;
          }
        }
      }
      //console.log(text_lines.join('\n'))
      return  text_lines.join('\n');
    },
    updateProject(project) {
      this.currentProject = project
    },
    bindPlainCursorPosition() {
      setTimeout(() => {
        ['keyup', 'click'].forEach(ev => {
          this.$refs.plainContentEditor.addEventListener(ev, () => {
            this.plainCursorPosition = this.$refs.plainContentEditor.selectionEnd || this.$refs.plainContentEditor.selectionStart || 0
            this.setFailedTargetTimerCheck();
          })
        })
      }, 200)
    },
    addTargetLinkShortcut(id) {
      const st = this.site_targets.find(st => st.id === id)
      if (this.html_mode) {
        this.editorQuill.insertText(this.quillCursorPosition, (this.new_content.anchor || st.name || '').trim(), 'link', st.to_url)
      } else {
        const additionalText = `<a href='${st.to_url}'>${(this.new_content.anchor || '').trim()}</a>`
        const contentStart = this.new_content.body.substring(0, this.plainCursorPosition);
        const contentEnd = this.new_content.body.substring(this.plainCursorPosition + 1);
        this.new_content.body = contentStart + additionalText + contentEnd;
      }
      this.textChanged()
      this.selectedTargetInput = null
    },
    updateTarget(target) {
      this.currentTarget = target
      this.site_targets = this.$refs.patform.getTargetList()
    },
    quickContentStatusSave(status, fullRules) {
      this.next_content_status = status
      const fr = typeof fullRules === 'boolean' ? fullRules : true
      this.isSubmittingForReview = fr;
      setTimeout(() => {
        this.$refs.contentForm.validate((valid) => {
          if (valid) {
            this.new_content.status = status;
            this.saveContent();
          } else {
            this.$scrollTo(this.$refs.top)
          }
        })
        this.next_content_status = null
      }, 400)
    },
    openReviewForm() {
      this.isSubmittingForReview = true;
      setTimeout(() => {
        this.$refs.contentForm.validate((valid) => {
          if (valid) {
            this.showSubmitReviewForm = true
          } else {
            this.$scrollTo(this.$refs.top)
          }
        })
      }, 400)
    },
    saveContent() {
      this.$refs.contentForm.validate((valid) => {
        if (valid) {
          //console.log('form is valid?', valid)
          this.loading = true;
          let method = this.$http.post;
          const body = Object.assign({}, this.new_content)
          let url = `/v1/companies/${this.$store.state.company.company.id}/content`;
          if (this.new_content.id) {
            url += `/${this.new_content.id}`;
            body.actual_word_count = this.character_count.words;
            method = this.$http.put;
          }

          method(url, body)
            .then(r => {
              this.$message.success('Successfully ' + (this.new_content.id ? 'updated' : 'created') + ' the content.');
              this.isSubmittingForReview = false;
              if (!this.new_content.id) {
                this.mode = 'edit';
                this.$router.push(`/content/${r.data.id}`);
              } else {
                this.loading = true;
                if (this.$refs.revision && typeof this.$refs.revision.getData === 'function') {
                  this.$refs.revision.getData()
                }
                this.$store.dispatch('content/getContent', this.$route.params).then(() => {
                  this.setContentFromState()
                  this.loading = false;
                })
              }
            })
            .catch((err,) => {
              let message = formatResponseErrorMessage(err)
              this.$message.error(message)
              console.error(err.toJSON(), message)
            })
            .finally(() => {
              this.loading = false;
            })
        } else {
          this.$scrollTo(this.$refs.top)
        }
      })
    },
    setContentFromState() {
      const content = Object.assign({}, this.$store.state.content.content);
      content.review_user = [
        content.review_user, content.review_user_2, content.review_user_3,
        content.review_user_4, content.review_user_5
      ].filter(i => !!i)
      this.new_content = content
      if(!this.new_content.body) this.new_content.body = '';
      this.submit_review.reviewer = content.review_user
    },
    getCompanyUsers() {
      this.$store.dispatch('users/loadAll', {})
        .then(r => {
          this.company_users = r;
        })
        .catch((e) => {
          this.$message.error('Failed to load company users');
        })

    },
    getContentAttachments() {
      const url = `/v1/companies/${this.$store.state.company.company.id}/content/${this.new_content.id}/attachments`;
      this.$http.get(url)
        .then(r => {
          this.currentAttachments = r.data.data;
        })
        .catch(() => {
          this.$message.error('Failed to load content attachments')
        })
    },
    uploadContentAttachments() {
      //console.log('hello')
      if (!this.$refs.upload.uploadFiles.length) {
        return this.$message.error('Please select one or more files to upload.')
      } else {
        //console.log('hi?')
        this.loading = true;
        const formData = new FormData();
        this.$refs.upload.uploadFiles.forEach((file, index) => {
          formData.append(`files[${index}]`, file.raw);
        });
        this.$http.post(`/v1/companies/${this.$store.state.company.company.id}/content/${this.new_content.id}/attachments`, formData, {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data'
          }
        }).then(() => {
          this.$message.success('Successfully uploaded content attachments');
          this.$refs.upload.clearFiles();
          this.getContentAttachments();
        }).catch(e => {
          this.$message.error('uploading content was unsuccessful.');
          console.error('The error', e);
        })
          .finally(() => {
            this.loading = false;
          })
      }
    },
    downloadAttachment(attachment) {
      const url = `/v1/companies/${this.$store.state.company.company.id}/content/${this.new_content.id}/attachments/${attachment.id}`
      const options = {
        responseType: 'blob'
      };

      let downloadName = attachment.original_filename;

      this.loading = true;
      this.$http.get(url, options).then(r => {
        FileDownload(r.data, downloadName)
      })
        .finally(() => {
          this.loading = false;
        })
      return true;
    },
    deleteAttachment(attachment) {
      this.$confirm(`Are you sure you want to delete the attachment '${attachment.original_filename}'?`, 'Are you sure?', {
        confirmButtonClass: 'el-button--danger'
      })
        .then(() => {
          const url = `/v1/companies/${this.$store.state.company.company.id}/content/${this.new_content.id}/attachments/${attachment.id}`
          this.$http.delete(url)
            .then(() => {
              this.$message.success('Successfully deleted the attachment.')
              this.getContentAttachments()
            })
            .catch(() => {
              this.$message.error('An error occurred trying to delete that attachment')
            })
        }).catch(() => {
      })
    },
    downloadContent() {
      const title = this.new_content.title && !!this.new_content.title.length ? this.new_content.title : 'untitled'
      const content = this.new_content.body;
      //FileDownload(content, `${title}.docx`)
      this.$http.post(`/v1/doc-parser`, {body: content}, {
        responseType: 'blob'
      }).then(r => {
        FileDownload(r.data, `${title}.docx`)
      })
    },
    deleteContent() {
      this.$confirm('Are you sure you want to permanently delete this content item?', 'Are you sure?', {
        confirmButtonClass: 'el-button--danger'
      }).then(() => {
        this.$http.delete(`/v1/companies/${this.$store.state.company.company.id}/content/${this.$route.params.contentId}`)
          .then(() => {
            this.$message.success('Successfully deleted the content item.');
            this.$router.push(`/company/${this.$store.state.company.company.id}/content`)
          })
          .catch(() => {
            this.$message.error('Failed to delete the content item. Please try again later.')
          })
      })
        .catch(() => {
        })
    },
    setEditor(quill) {
      this.editorQuill = quill
      // quill.clipboard.addMatcher(Node.ELEMENT_NODE, (node, delta) => {
      //   delta.ops = delta.ops.map(op => {
      //     // if(op?.attributes?.link) op.attributes = { link: op.attributes.link, color: '#1155cc' }
      //     if(op?.attributes?.color) Reflect.deleteProperty(op.attributes, 'color')
      //     return op
      //   })
      //   return delta
      // })
    },
    textChanged() {
      if (!this.first_quill_load) {
        setTimeout(() => {
          this.new_content.body = this.new_content.body.replace(/<\/p><p>(<a .*?<\/a>)<\/p><p>/g, '$1')
          this.first_quill_load = true;
        }, 1000)
      }
      let text = this.editorQuill.getText();
      // console.log('Hello the text has changed!', text)
      this.quillCursorPosition = this.editorQuill.getSelection() ? this.editorQuill.getSelection().index + this.editorQuill.getSelection().length : 0;
      if (text.length) {
        text = text.replace(/\s{2,}/g, ' ')
        this.character_count.words = text.split(' ').filter(i => i.trim().length > 0).length;
        this.character_count.letters = text.replace(/\s/g, '').length
      } else {
        this.quillCursorPosition = 0;
        this.character_count = {
          words: 0,
          letters: 0
        }
      }
      this.setFailedTargetTimerCheck();
    },
    setRevisionContent(history) {
      if (history.title) {
        this.new_content.title = history.title
      }
      if (history.body) {
        this.new_content.body = history.body
      }
    },
    quillTextPositionChanged(a) {
      if (a) {
        console.log(a)
        this.quillCursorPosition = a.index + a.length
        this.quillLastSelectedText = this.$refs.editor.quill.getText(a.index, a.length);
        this.quillLastSelectedTextRange = a;



      //  let content = this.$refs.editor.quill.getText();


          //navigator.clipboard.writeText(copyText.value);

        //console.log(this.editorQuill)
        //console.log('on select' + this.quillCursorPosition)
      }
    },
    submitReviewRequest() {
      this.$refs.reviewForm.validate(valid => {
        if (valid) {
          this.new_content.review_date = this.submit_review.date
          this.new_content.review_user = this.submit_review.reviewer
          this.new_content.status = 'editor_review'
          this.showSubmitReviewForm = false
          this.saveContent()
        }
      })
    },
    getReviewers() {
      return this.new_content.review_user
        .map(i => this.company_users.find(c => c.id === i))
        .filter(i => !!i)
    },
    submitReturnRequest() {
      this.$refs.returnForm.validate(valid => {
        if (valid) {
          // good, lets go!
          const note = 'The content has been returned' + (this.returnForm.note ? ': ' + this.returnForm.note : '.')
          const noteParams = {
            resource_type: 'ContentItem',
            resource_id: this.new_content.id,
            note_type: 'Note',
            assigned_user_id: this.returnForm.user,
            body: note
          }
          this.loading = true
          this.$http.post(`/v1/companies/${this.$store.state.company.company.id}/notes`, noteParams)
            .then(() => {
              this.new_content.status = 'returned';
              this.showSubmitReviewForm = false;
              this.$refs.returnForm.resetFields();
              return this.saveContent();
            }).catch(e => {
            console.error(e)
            this.$message.error('An error occurred. Please try again later.')
            this.loading = false
          })
        }
      })
    },
    handleEditorPaste(v) {
      //console.log('On pasete', v)
    },
    getEditorToolbar() {
      return [
        [ { header: [false, 1, 2, 3, 4, 5, 6] } ],
        ["bold", "italic", "underline", "strike"],
        [ { align: "" }, { align: "center" }, { align: "right" }, { align: "justify" } ],
        ["blockquote", "code-block"],
        [ { list: "ordered" }, { list: "bullet" }, { list: "check" }], [{ indent: "-1" }, { indent: "+1" } ],
        ["link", "image"],
      ];
    },
    setFailedTargetTimerCheck() {
      if(failed_target_timer_check) {
        clearTimeout(failed_target_timer_check)
      }
      failed_target_timer_check = setTimeout(this.checkForSuspendedLinks, 1000)
    },
    checkForSuspendedLinks() {
      if(!this.new_content.body) return
      const links = [...this.new_content.body.matchAll(/href=["'](.*?)['"]/gmis)].map(i => i[1])

      if(links) {
        let failed_target = null;
        this.failed_target_in_content = links.find(l => failed_target = this.site_targets.find(st => st.to_url === l && st.status === 'suspended'))
      } else {
        this.failed_target_in_content = null;
      }
    }
  },
  watch: {
    html_mode(){

      const vm = this;
      if(this.html_mode){
        this.quillLastSelectedText = ''
      }
      if(!this.html_mode){
        console.log('html_mode!')
        this.$nextTick(() => {

          if(this.quillLastSelectedText && this.quillLastSelectedText.length > 0){

            let string = this.quillLastSelectedText;
            console.log(string)
            let find = this.new_content.body.match(new RegExp(string), this.new_content.body)
            console.log(find)

            if(find && find.index){
              console.log('selecting....', find.index)
              setTimeout(() => {
                console.log(this.$refs.plainContentEditor)

                  vm.$refs.plainContentEditor.addEventListener('focus', () => {
                    vm.$refs.plainContentEditor.scrollTo(0,0);
                    console.log(find.index+find[0].length)
                  vm.$refs.plainContentEditor.setSelectionRange(find.index, find.index+find[0].length);
                });

                vm.$refs.plainContentEditor.focus();
              }, 100)
            }
          }

        })
      }
    },
    showContentPreview: {
      handler(val) {
        window.document.body.style.overflowY = val ? 'hidden' : 'auto';
      }
    }
  }
}
</script>
<style lang="scss">
.create_content_component {
  label.el-form-item__label {
    text-align: left;
  }

  .ck-editor__editable_inline {
    height: 400px;
  }

  .ql-snow {
    .ql-tooltip {
      input[type=text] {
        //width: 50vw;
      }
    }
  }
}

body {
  .el-dialog__wrapper {
    .content-preview-dialog {
      margin-top: 0 !important;
      margin-bottom: 0 !important;

      .el-dialog__header {
        padding: 0 !important;
      }

      .el-dialog__body {
        padding: 0 !important;
        margin: 0 !important;
      }

      &.full {
        background: #fff !important;
        height: 100%;

        .header {
          display: none;
        }

        .el-dialog__body {
          .bg-white {
            max-height: initial !important;
            min-height: 100% !important;
          }
        }

        .ql-editor {
          height: 100% !important;
        }
      }
    }
  }
}
</style>
