<template>
  <trieste-dialog :show.sync="show_bulk_emailing_form" title="Start bulk emailing">
    <div class="p-6 pb-2">
      <el-form :model="bulk_emailing_options" label-position="left" label-width="150px" ref="bulkForm" v-loading="loading">
        <el-form-item label="Email account" prop="defaultEmailAccount">
          <el-select v-model="bulk_emailing_options.defaultEmailAccount" class="w-full" filterable>
            <el-option
              v-for="(e, i) in email_accounts" :key="'email_account_' + i"
              :value="e.id"
              :label="`${e.email}${e.status === 'failed' ? ' (failed)' : ''}`"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="Default template" prop="defaultTemplate">
          <el-select v-model="bulk_emailing_options.defaultTemplate" :disabled="!bulk_emailing_options.defaultEmailAccount" class="w-full" filterable>
            <el-option
              v-for="(e, i) in allowedEmailTemplates" :key="'email_templates_' + i"
              :value="e.id"
              :label="e.name"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="Default Type" prop="defaultLinkType">
          <el-select
            v-model="bulk_emailing_options.defaultLinkType" :disabled="!bulk_emailing_options.defaultEmailAccount"  class="w-full" filterable
          >
            <el-option
              v-for="(o, i) in $store.state.link.types"
              :key="i"
              :value="o.value"
              :label="o.label"
            />
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <template v-slot:footer-right class="dialog-footer">
      <el-button @click="show_bulk_emailing_form = false" type="danger" icon="el-icon-close" size="small">Cancel</el-button>
      <el-button type="success" size="small" icon="el-icon-edit-outline" @click="startBulkEmailing">Start</el-button>
    </template>
  </trieste-dialog>
</template>
<script>
export default {
  props: {
    bulk_link: {
      type: Object
    },
    site_id: {
      type: [String, Number]
    },
  },
  data () {
    return {
      loading: false,
      bulk_emailing_options: {
        loadedResources: false,
        defaultEmailAccount: null,
        defaultTemplate: null,
        defaultLinkType: null
      },
      email_accounts: [],
      email_templates: [],
      show_bulk_emailing_form: false,
    }
  },
  computed: {
    allowedEmailTemplates() {
      if(this.bulk_emailing_options.defaultEmailAccount) {
        const emailAccount = this.email_accounts.find(e => e.id === this.bulk_emailing_options.defaultEmailAccount)
        if(Array.isArray(emailAccount.allowed_templates) && emailAccount.allowed_templates.length) {
          return this.email_templates.filter(et => emailAccount.allowed_templates.includes(et.id))
        }
      }
      return this.email_templates;
    }
  },
  methods: {
    reloadResources() {
      this.bulk_emailing_options.loadedResources = false
    },
    getResources(callback = () => {}) {
      const site_id = this.site_id || this.$store.state.site.site.id
      const requests = [
        this.$http.get(`/v1/companies/${this.$store.state.company.company.id}/user/email-accounts`, {
          params: {
            all: true,
            include_allowed_templates: true,
            site_id
          }
        }),
        this.$http.get(`/v1/companies/${this.$store.state.company.company.id}/email-templates`, {
          params: {
            all: true,
            site_id,
            in_outreach_group: true
          }
        }),
      ]

      this.$http.all(requests).then(this.$http.spread((emailAccountsRequest, EmailTemplatesRequest) => {
        this.email_accounts = emailAccountsRequest.data.data;
        this.email_templates = EmailTemplatesRequest.data.data;
      }))
        .finally(() => {
          this.loading = false;
          this.ready = true;
          callback()
        })
    },
    toggleBulkEmailingForm() {
      if(!this.show_bulk_emailing_form && !this.bulk_emailing_options.loadedResources) {
        return this.getResources(() => {
          this.show_bulk_emailing_form = true
        })
      }

      this.show_bulk_emailing_form = !this.show_bulk_emailing_form
    },
    startBulkEmailing() {
      if (this.bulk_link) {
        this.$store.dispatch('batch/startBulkEmailing', this.bulk_emailing_options)
        const link = this.bulk_link
        this.$router.push(`/company/${this.$route.params.companyId}/leads/${link.id}`)
      }
    },
  }
}
</script>
