<template>
  <div>
    <trieste-dialog :show="show" title="Create batch" width="420px">
      <div class="p-4">
        <el-form label-width="120px" label-position="left" ref="form">
          <project-and-target-form form-label-width="120px" inline
                                   :target-id.sync="batch.site_target_id"
                                   :ignore-suspended-targets="true"
                                   @target-updated="getCampaignsList"
                                   :show-project-select="false" ref="patform"
                                   allow-all-sites show-site-select allow-suspended-selection
          />

          <!--   <el-form-item label="Client" prop="client" v-if="false">
            <el-select v-model="batch.client" :loading="$store.state.client.loading_all_clients" filterable class="w-full" @change="clientChanged">
              <el-option v-for="(client, i) in $store.state.client.clients" :value="client.id" :label="client.name" :key="i" />
            </el-select>
            <div class="text-right" v-if="$store.getters['auth/hasPermission']('client.create') && false">
              <el-button size="mini" type="primary" icon="el-icon-edit-outline" @click="showClientCreate = true">Create client</el-button>
            </div>
          </el-form-item>
        Not sure why this was set to false?
          <el-form-item label="Site" prop="site" v-if="false">
            <el-select v-model="batch.site" :loading="$store.state.site.loading_all_sites" :disabled="!batch.client" filterable class="w-full" @change="siteChanged">
              <el-option v-for="(site, i) in getSites" :key="i" :value="site.id" :label="site.base_url" />
            </el-select>
            <div class="text-right" v-if="$store.getters['auth/hasPermission']('site.create') && false">
              <el-button size="mini" type="primary" icon="el-icon-edit-outline" v-if="batch.client" @click="showSiteCreate = true">Create site</el-button>
            </div>
          </el-form-item>
          -->
          <el-form-item label="Campaign" prop="campaign">
            <el-select v-model="batch.campaign" :loading="loading_campaigns" :disabled="!batch.site_target_id" filterable class="">
              <el-option v-for="(campaign, i) in campaigns" :key="i" :value="campaign.id" :label="campaign.name" />
            </el-select>
            <div class="text-right" v-if="$store.getters['auth/hasPermission']('campaign.create') && false">
              <el-button size="mini" type="primary" icon="el-icon-edit-outline" v-if="batch.client && batch.site" @click="showCampaignCreate = true">Create campaign</el-button>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <template v-slot:footer-right class="dialog-footer">
        <el-button @click="$emit('update:show', false)" type="danger" icon="el-icon-close" size="small">Cancel</el-button>
        <el-button type="success" size="small" icon="el-icon-edit-outline" @click="createBatch" :disabled="!batch.campaign || !batch.site_target_id">Create batch</el-button>
      </template>
    </trieste-dialog>
    <client-quick-create :show.sync="showClientCreate" @success="setNewClient" />
    <site-create-widget :show.sync="showSiteCreate" @updated="setNewSite" :client-id="batch.client" v-if="batch.client" />
    <campaign-create-widget :show.sync="showCampaignCreate" @success="setNewCampaign" :client-id="batch.client" :site-id="batch.site" v-if="batch.client && batch.site" />
  </div>
</template>
<script>
  import ClientQuickCreate from '@/components/views/client/CreateEdit';
  import SiteCreateWidget from '@/components/views/Sites/CreateEdit';
  import CampaignCreateWidget from '@/components/views/Campaign/CreateEdit';
  import ProjectAndTargetForm from '@/components/views/global/ProjectAndTargetForm';
  export default {
    components: { ProjectAndTargetForm, ClientQuickCreate, SiteCreateWidget, CampaignCreateWidget },
    name: 'batch-quick-create',
    props: {
      show: {
        type: Boolean,
        default: false
      },
      clientId: {
        type: [String, Number],
        default: null
      },
      siteId: {
        type: [String, Number],
        default: null
      },
    },
    data() {
      return {
        batch: {
          client: this.clientId,
          site: this.siteId,
          campaign: null,
          site_target_id: null,
        },
        loading_campaigns: false,
        campaigns: [],
        form_rules: {
          client: [
            { required: true, message: 'Please select a client' }
          ],
          site: [
            { required: true, message: 'Please select a client' }
          ],
          campaign: [
            { required: true, message: 'Please select a client' }
          ]
        },
        showClientCreate: false,
        showSiteCreate: false,
        showCampaignCreate: false
      }
    },
    computed: {
      getSites() {
        if(!this.batch.client) {
          return this.$store.state.site.sites;
        }
        return this.$store.state.site.sites.filter(site => site.client_id === this.batch.client)
      }
    },
    mounted() {
      this.$store.dispatch('client/loadAllClients', {}).then(() => {
        this.$store.dispatch('site/loadAllSites', {
          status: 'active'
        }).then(() => {
          if(this.clientId && this.siteId) {
            this.getCampaignsList()
          }
        })
      })
    },
    methods: {
      clientChanged() {
        this.batch.site = null
        this.batch.campaign = null
      },
      siteChanged() {
        this.batch.campaign = null
        if(this.batch.client) {
          this.getCampaignsList()
        }
      },
      setNewClient(client) {
        this.$store.dispatch('client/loadAllClients', { force: true });
        this.batch.client = client.id
        this.batch.site = null
        this.batch.campaign = null
      },
      setNewSite(site) {
        this.$store.dispatch('site/loadAllSites', {
          status: 'active',
          force: true });
        this.batch.site = site.id
        this.batch.campaign = null
      },
      setNewCampaign(campaign) {
        this.batch.campaign = campaign.id;
        this.getCampaignsList();
      },
      createBatch() {
        this.$router.push(`/company/${this.$store.state.company.company.id}/campaigns/${this.batch.campaign}/batch/create?target_id=${this.batch.site_target_id}`);
      },
      getCampaignsList(target) {
        this.batch.campaign = null;
        this.loading_campaigns = true;
        this.$http.get(`/v1/companies/${this.$store.state.company.company.id}/campaigns`, {
          params: {
            all: true,
            site_id: target.site_id
          }
        }).then(response => {
          this.campaigns = response.data.data;
          this.batch.campaign = this.campaigns[0] ? this.campaigns[0].id : null
        })
          .catch(() => {
            this.$message.error('Failed to load the campaigns list.')
          })
          .finally(() => {
            this.loading_campaigns = false
          })
      },
      create() {
        this.$refs.form.validate(valid => {
          if(valid) {
            // Go to the batch configuration.
          }
        })
      }
    }
  }
</script>
