import api from './../../utils/api';
import router from "@/router";
import timezoneList from '@/json/timezones.json'
const initialState = {
  access_token: null,
  refresh_token: null,
  api: process.env.VUE_TRIESTE_API,
  user: {
    id: null,
    firstname: null,
    lastname: null,
    email: null,
    permissions: [],
    roles: [],
    last_viewed_company_id: null,
    timezone: null
  },
  timer: {
    last_page: {
      type: null,
      id: null
    },
    timestamp: 0
  },
  alert_count: 0,
  show_login_model: false,
}

const checkLoginState = (state) => {
  if (state.access_token && state.refresh_token && state.user) {
    state.show_login_model = false
  }
}

export default {
  namespaced: true,
  state: initialState,
  mutations: {
    reset (state, force_reload) {
      Object.keys(initialState).forEach(key => {
        state[key] = initialState[key]
      })

      if(window.location.pathname !== '/login' && router.currentRoute.meta.requiresAuth) {
        localStorage.removeItem('trieste')
        if(force_reload) window.location.href = '/login?redirect=' + encodeURIComponent(new URL(window.location.href).pathname)
        else state.show_login_model = true;
      }
    },
    loginModelSuccess(state) {
      state.show_login_model = false
      checkLoginState(state)
    },
    setAccessToken: (state, access_token) => {
      state.access_token = access_token
      checkLoginState(state)
    },
    setRefreshToken: (state, token) => {
      state.refresh_token = token
      checkLoginState(state)
    },
    setUser (state, payload) {
      if(payload.user.timezone) {
        const actualTimezone = timezoneList.find(t => t.value === state.user.timezone)
        if(actualTimezone && actualTimezone.zone) {
          payload.user.timezone = actualTimezone.zone
        }
      }
      state.user = payload.user
     // checkLoginState(state)
    },
    addTime: (state, time) => state.timer.timestamp += time,
    resetTimer: (state) => {
      state.timer = {
        last_page: {
          type: null,
          id: null
        },
        timestamp: 0
      }
    },
    setTimer: (state, { type, id }) => {
      state.timer.last_page = {
        type,
        id
      }
    }
  },
  actions: {
    setLastViewedCompany({commit, state}, {companyId}) {
      return new Promise((resolve, reject) => {
        api.post(`/v1/user/last-viewed-company`, { company_id: companyId }).then(() => {
          const currentUser = Object.assign({}, state.user)
          currentUser.last_viewed_company_id = companyId;
          commit('setUser', {user: currentUser})
          resolve()
        }).catch(e => {
          reject(e)
        })
      })
    },
    getAlertCount({commit, state, rootState, dispatch}, { companyId, alert_tasks }) {
      const cID = companyId || rootState.company.company.id
      if(!cID) {
        return false // Do not to this unless we have a company id.
      }

      return new Promise(((resolve, reject) => {
        api.get('/v1/user/alerts', {
          params: {
            company_id: cID,
            count_only: true
          }
        }).then((r) => {
          state.alert_count = r.data.total

          if(alert_tasks && r.data.tasks_count) {
            let message = `You have ${r.data.tasks_count} pending task notes.`;
            if(r.data.tasks_urgent_count) {
              message += ` (${r.data.tasks_urgent_count} are urgent.)`;
            }

            dispatch('global/addNotification', {
              type: 'warning',
              message,
              closable: true
            }, {root: true})
          }

          resolve(r.data.data)
        })
        .catch((e) => {
          reject(e)
        })
      }))
    },
    getUserDetails({commit, state}) {
      return new Promise((resolve, reject) => {
        api.get('/v1/user').then(res => {
          const userData = Object.assign({}, state.user, res.data.data)
          commit('setUser', {user: userData })
          resolve()
        }).catch(e => { reject(e) })
      })
    },
    resetTimer: ({commit}) => commit('resetTimer'),
    addTime: ({commit}, time = 15) => commit('addTime', time),
    setTimer: ({commit}, payload) => commit('setTimer', payload)
  },
  getters: {
    hasPermission: (state) => (permission) => {
      return state.user.permissions.indexOf(permission) > -1
    },
    isExternalUser: (state) => () => {
      return state.user.permissions.includes('external.approver')
    },
    hasRole: (state) => (role) => {
      return state.user.roles.indexOf(role.toLowerCase()) > -1
    },
    accessToken: (state) => {
      return state.access_token
    },
    lastRecordedTimer: (state) => {
      if(state.timer.last_page.type && state.timer.last_page.id && state.timer.timestamp) {
        return Object.assign({}, state.timer.last_page, { timestamp: state.timer.timestamp });
      }
      return null;
    },
    tableHeaders: context => {
      return {
        baseURL: context.api,
        headers: {
          'Authorization': 'Bearer ' + context.access_token
        }
      }
    },
  }
}
