module.exports = {
  methods: {
    createGoogleAuthenticationLink(emailAddress, id, companyId, client, return_uri = '/google-sign-in', host = process.env.VUE_APP_URL) {
      console.log(process.env)
      const baseUrl = 'https://accounts.google.com/o/oauth2/v2/auth'
      const params = {
        scope: 'https://mail.google.com/',
        include_granted_scopes: true,
        response_type: 'code',
        redirect_uri: host + return_uri,
        client_id: client || process.env.VUE_APP_GOOGLE_CLIENT_ID,
        prompt: 'select_account',
        access_type: 'offline',
        state: companyId || this.$store.state.company.company.id
      }

      if (emailAddress) {
        params.login_hint = emailAddress
      }

      if (id) {
        params.state += '_' + id
      }

      // build the URL
      const finalUrl = new URL(baseUrl)
      for(const key in params) {
        finalUrl.searchParams.append(key, params[key])
      }

      return finalUrl.href
    },
    createMicrosoftAuthenticationLink(emailAddress, companyId, id, redirect_uri = 'https://app.trieste.io/modern-auth-sign-in') {
      const baseUrl ='https://login.microsoftonline.com/common/oauth2/v2.0/authorize'

      const params = {
        client_id:'6715a155-ab47-48bc-81d7-f8c97a993fe1',
        response_type: 'code',
        response_mode: 'query',
        scope: 'smtp.send mail.read mail.read.shared mail.readwrite mail.readwrite.shared mail.send mail.send.shared mailboxsettings.read mailboxsettings.readwrite offline_access user.read',
        state: companyId || this.$store.state.company.company.id,
        prompt: 'select_account'
      }

      console.log(params)

      if (id) {
        params.state += '_' + id
      }

      const finalUrl = new URL(baseUrl)
      for(const key in params) {
        finalUrl.searchParams.append(key, params[key])
      }

      console.log(finalUrl)

      return finalUrl.href + '&redirect_uri=' + redirect_uri;
    }
  }
}
