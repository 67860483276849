<template>
<div v-loading="loading">
  <el-form
    ref="projectForm"
    :model="form"
    :rules="getRules"
    :label-width="formLabelWidth"
    :label-position="formLabelPosition"
    :inline="inline"
  >
    <el-form-item label="Site" prop="site" v-if="showSiteSelect">
      <div class="columns">
        <div class="column w-full">
          <div class="flex flex-row">
            <span v-if="first_load">Loading....</span>
            <el-select v-model="form.site" class="w-full" @change="siteChanged" :disabled="!isEnabled || !isSiteEnabled" filterable v-else>
              <el-option :value="null" label="-" />
              <el-option v-for="(site, i) in sites" :key="i" :label="`${site.domain} - ${site.status}`"  :disabled="site.status === 'suspended' && !allowSuspendedSelection" :value="site.id" />
            </el-select>
          </div>
        </div>
      </div>
    </el-form-item>
    <el-form-item label="Site / Target" prop="target" v-if="showSiteTargetSelect">
      <div class="columns">
        <div class="column w-full">
          <div class="flex flex-row justify-between">
            <span v-if="first_load">Loading....</span>
            <el-select
              v-model="form.target"
              class="w-full"
              @change="siteTargetChanged"
              :disabled="!isEnabled || !isTargetEnabled"
              filterable
              v-else
            >
              <el-option :value="null" label="-" />
              <el-option v-for="(target, i) in allowedSiteTargets"  :disabled="target.status === 'suspended' && !allowSuspendedSelection" :key="'target_'+i" :value="target.id" :label="`${target.name} - ${target.to_url} - ${target.status}`">
                <div>
                  <div>{{target.name}} - {{target.status}}</div>
                  <div class="-mt-4"><small>{{target.to_url}}</small></div>
                </div>
              </el-option>
            </el-select>
            <slot name="target-slot" :targets="site_targets" :sites="sites" :projects="projects"></slot>
          </div>
        </div>
      </div>
    </el-form-item>
    <el-form-item label="Project" prop="project" v-if="showProjectSelect">
      <div class="columns">
        <div class="column w-full">
          <div class="flex flex-row justify-between">
            <span v-if="first_load">Loading....</span>
            <el-select v-model="form.project" class="w-full" @change="projectChanged" :disabled="!isEnabled || !isProjectEnabled" filterable v-else>
              <el-option :value="null" label="-" />
              <el-option v-for="(project, i) in allowedProjects" :key="i" :label="`${project.name} - ${project.status}`" :disabled="project.status === 'suspended' && !allowSuspendedSelection"  :value="project.id" />
            </el-select>
            <slot name="project-slot"></slot>
          </div>
        </div>
      </div>
    </el-form-item>
  </el-form>
</div>
</template>
<script>
  export default {
    props: {
      projectId: {
        type: [String, Number]
      },
      targetId: {
        type: [String, Number]
      },
      targetSiteId: {
        type: [String, Number]
      },
      required: {
        type: Boolean,
        default: true
      },
      formLabelWidth: {
        type: String,
        default: '120px'
      },
      formLabelPosition: {
        type: String,
        default: 'left'
      },
      siteId: {
        type: [Number, String],
        default: null
      },
      inline: {
        type: Boolean,
        default: false
      },
      isEnabled: {
        type: Boolean,
        default: true
      },
      isProjectEnabled: {
        type: Boolean,
        default: true
      },
      isSiteEnabled: {
        type: Boolean,
        default: true
      },
      isTargetEnabled: {
        type: Boolean,
        default: true
      },
      showSiteSelect: {
        type: Boolean,
        default: false
      },
      showProjectSelect: {
        type: Boolean,
        default: true
      },
      showSiteTargetSelect: {
        type: Boolean,
        default: true
      },
      ignoreSuspendedTargets: {
        type: Boolean,
        default: false
      },
      requireProject: {
        type: Boolean,
        default: false
      },
      autoSetProjects: {
        type: Boolean,
        default: false
      },
      allowAllSites: {
        type: Boolean,
        default: false
      },
      allowAllTargets: {
        type: Boolean,
        default: false
      },
      activeOnly: {
        type: Boolean,
        default: true
      },
      activeOnlyProject: {
        type: Boolean,
        default: true
      },
      allowSuspendedSelection: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        projects: [],
        site_targets: [],
        sites: [],
        loading: false,
        first_load: true,
        form: {
          project: this.projectId,
          target: this.targetId,
          site: this.targetSiteId || this.siteId,
        },
        last_form: {
          project: this.projectId,
          target: this.targetId,
          site: this.targetSiteId || this.siteId,
        },
        rules: {
          project: [
            {required: this.requireProject, message: 'Please select a project.', trigger: 'change'},
          ],
          target: [
            {required: true, message: 'Please select a target.', trigger: 'change'},
          ],
          site: [
            {required: true, message: 'Please select a site.', trigger: 'change'},
          ],
        }
      }
    },
    computed: {
      getRules() {
        if(this.required) {
          return this.rules
        }
        return {}
      },
      allowedProjects () {
        if(!this.showSiteSelect && !this.form.target && !this.form.site) {
          return this.projects
        }
        const t = this.form.target ? this.site_targets.find(st => st.id === this.form.target) : null
        const siteId = t ? t.site.id : this.siteId
        return this.projects.filter(p => p.allowed_sites.includes(siteId)).sort((a, b) => a.name > b.name ? 1 : -1)
      },
      allowedSiteTargets () {
        if(this.form.site && !this.allowAllTargets) {
          return this.site_targets.filter(st => st.site_id === this.form.site).sort((a, b) => a.name > b.name ? 1 : -1)
        }

        return this.site_targets;
      }
    },
    mounted() {
      this.loadResources()
    },
    methods: {
      getTargetList() {
        return this.allowedSiteTargets
      },
      getProjectlist() {
        return this.projects
      },
      getSelectedTarget() {
        if(this.form.target) {
          return this.getTargetList().find(t => t.id === this.form.target)
        }
        return null
      },
      loadResources() {
        this.loading = true;
        const params = {
          all: true
        };
        if (this.activeOnly){
          params.status = 'active'
        }
        if(this.siteId && !this.allowAllSites) {
          params.site_id = this.siteId
        }
        if(this.ignoreSuspendedTargets){
          params.ignore_suspended_targets = true;
        }

        const requests = [
          this.$http.get(`/v1/companies/${this.$store.state.company.company.id}/projects`, { params }),
          this.$http.get(`/v1/companies/${this.$store.state.company.company.id}/targets`, { params })
        ];
        if(!this.siteId || this.allowAllSites) {
          requests.push(
            this.$http.get(`/v1/companies/${this.$store.state.company.company.id}/sites`, { params })
          )
        }
        this.$http.all(requests).then(this.$http.spread((projects, targets, sites) => {
          this.projects = projects.data.data.map(p => ({
            ...p,
            allowed_sites: (p.site_targets || []).filter(st => st.site).map(st => st.site.id)
          }))
          this.site_targets = targets.data.data
          this.sites = sites ? sites.data.data : (this.site_targets.length ? [this.site_targets[0].site] : [])

          // if(this.first_load && !this.form.target && this.site_targets.length) {
          //   const targets = this.allowedSiteTargets
          //   this.form.target = targets[0] ? targets[0].id : null
          // }

          if(this.form.target) this.siteTargetChanged()
        }))
        .finally(() => {
          this.first_load = false;
          this.loading = false;
          this.$emit('loaded')
        })
      },
      getSite() {
        return this.form.site ? this.sites.find(s => s.id === this.form.site) : null
      },
      getTarget() {
        return this.form.target ? this.site_targets.find(s => s.id === this.form.target) : null
      },
      getProject() {
        return this.form.project ? this.projects.find(s => s.id === this.form.project) : null
      },
      getSiteList () {
        return this.sites
      },
      getProjectList () {
        return this.projects
      },
      siteChanged() {
        if(this.form.project) {
          this.form.project = null;
          this.$emit('update:projectId', null)
          this.$emit('project-updated', null)
        }
        if(this.form.target) {
          this.form.project = null;
          this.$emit('update:targetId', null)
          this.$emit('target-updated', null)
        }

        this.$emit('update:targetSiteId', this.form.site)
        this.$emit('site-updated', this.sites.find(s => s.id === this.form.site))
      },
      projectChanged() {
        this.$emit('update:projectId', this.form.project)
        this.$emit('project-updated', this.projects.find(p => p.id === this.form.project))
      },
      siteTargetChanged() {
        // okay the site target has changed.
        let target = null
        if(this.form.target) {
          target = this.site_targets.find(st => st.id === this.form.target);
          if(target) {
            if(this.form.project) {
              const project = this.projects.find(p => p.id === this.form.project)
              if(!project || !project.allowed_sites.includes(target.site.id)) {
                let eligible
                if(this.autoSetProjects) eligible = this.projects.find(p => p.allowed_sites.includes(target.site.id))
                this.form.project = eligible ? eligible.id : null
                if(this.last_form.project !== this.form.project) {
                  this.$emit('update:projectId', this.form.project)
                  this.$emit('project-updated', this.projects.find(p => p.id === this.form.project))
                  this.last_form.project = this.form.project
                }
              }
            }
          }
        }
        if(this.last_form.target !== this.form.target) {
          this.$emit('update:targetId', this.form.target)
          this.$emit('target-updated', target)
          this.last_form.target = this.form.target
        }
        //if(this.inline) {
          const siteId = target ? target.site.id : null;
          if (this.form.site !== siteId) {
            this.form.site = siteId;
            this.$emit('update:targetSiteId', siteId)
            this.$emit('site-updated', siteId ? this.sites.find(s => s.id === siteId) : null)
          }
        //}
      },
      async validate(callback) {
        if(this.$refs.projectForm) {
          return this.$refs.projectForm.validate(callback)
        }
        return false
      }
    },
    watch: {
      projectId: function(val) {
        const exists = this.projects.find(p => p.id === val)
        this.form.project = exists ? val : null
      },
      targetId: function(val) {
        this.form.target = val
      }
    }
  }
</script>
