<template>
  <div>
    <trieste-dialog :show.sync="template.show" width="500px" title="Add Template">
      <div class="p-4">

        <p class="mb-2 text-blue-800 font-bold">Select a template</p>

        <el-select v-model="bulk_emailing_options.defaultTemplate"
                   :disabled="!bulk_emailing_options.defaultEmailAccount" class="w-full" filterable>

          <el-option-group
            v-for="group in groupedEmailTemplates"
            :key="group.label"
            :label="group.label">
            <el-option
              v-for="(e, i) in group.options" :key="'email_templates_' + i"
              :value="e.id"
              :label="e.name"
            />
          </el-option-group>

          <!--
          <el-option
            v-for="(e, i) in groupedEmailTemplates" :key="'email_templates_' + i"
            :value="e.id"
            :label="e.name"
          />
          -->
        </el-select>
      </div>
      <template #footer-right>
        <el-button @click="() => template.show = false">Cancel</el-button>
        <el-button
          @click="addSequence()"
          :disabled="bulk_emailing_options.defaultTemplate === null"
          type="success">Add
        </el-button>
      </template>
    </trieste-dialog>
    <trieste-dialog :show.sync="show_bulk_emailing_form" width="1000px" title="Auto Responder Settings">

      <template #footer-right>
        <div  v-loading="loading">
          <el-button
            :disabled="loading"
            @click="() => show_bulk_emailing_form = false">
            Close
          </el-button>
          <el-button type="success"
                     :disabled="loading"
                     :loading="loading"
                     @click="() => update()">
            Save Settings
          </el-button>
        </div>
      </template>

      <div class="p-3">
        <div>
          <div class="mr-auto mb-3 flex justify-end" v-loading="loading">
            <el-switch
              v-model="bulk_emailing_options.active"
              active-text="Enabled"
              active-color="#13ce66"
              inactive-color="#ff4949"
              inactive-text='Disabled'>
            </el-switch>
          </div>
        </div>
        <div class="w-full flex flex-wrap gap-4" v-if="!loading">
          <el-alert type="info"
                    v-if="!bulk_emailing_options.active"
                    :closable="false">
            Auto-responder is not enabled. Automated emails are currently not being sent
          </el-alert>
          <el-alert type="error"
                    v-if="bulk_emailing_options.active"
                    :closable="false">
            Auto-responder is enabled. Modifying these settings will change the sending schedule
          </el-alert>
          <el-alert type="warning"
                    v-if="!sequence.length && bulk_emailing_options.active"
                    :closable="false">
            Auto-response Emails cannot send as you have not selected created a sequence.
          </el-alert>

          <el-alert type="warning"
                    v-if="bulk_emailing_options.defaultEmailAccount === null && bulk_emailing_options.active"
                    :closable="false">
            Auto-response Emails cannot send as you have not selected an email address
          </el-alert>




        </div>
      </div>

      <div class="px-3 py-4" v-loading="loading">


        <el-tabs class="custom-tabs-v1"
                 v-model="selected_tab">
          <el-tab-pane
            :disabled="loading"
            label="Settings" name="settings">

            <el-form :model="bulk_emailing_options" label-position="left" label-width="200px" ref="bulkForm" v-loading="loading">
              <el-form-item label="Email account" prop="defaultEmailAccount">
                <el-select v-model="bulk_emailing_options.defaultEmailAccount" class="w-full" filterable>
                  <el-option
                    v-for="(e, i) in email_accounts" :key="'email_account_' + i"
                    :value="e.id"
                    :label="`${e.email}${e.status === 'failed' ? ' (failed)' : ''}`"
                  />
                </el-select>
              </el-form-item>

              <!---
                  <el-form-item label="Default template" prop="defaultTemplate">
                <el-select v-model="bulk_emailing_options.defaultTemplate" :disabled="!bulk_emailing_options.defaultEmailAccount" class="w-full" filterable>
                  <el-option
                    v-for="(e, i) in allowedEmailTemplates" :key="'email_templates_' + i"
                    :value="e.id"
                    :label="e.name"
                  />
                </el-select>
              </el-form-item>

              --->

              <el-form-item label="Default Type" prop="defaultLinkType">
                <el-select
                  v-model="bulk_emailing_options.defaultLinkType" :disabled="!bulk_emailing_options.defaultEmailAccount"  class="w-full" filterable
                >
                  <el-option
                    v-for="(o, i) in $store.state.link.types"
                    :key="i"
                    :value="o.value"
                    :label="o.label"
                  />
                </el-select>
              </el-form-item>
            </el-form>


          </el-tab-pane>
          <el-tab-pane
            :disabled="loading"
            label="Sending Sequence" name="sequence">


            <el-alert type="warning"
                      v-if="bulk_emailing_options.defaultEmailAccount === null"
                      :closable="false">
              You not create a sequence without selecting an email address
            </el-alert>


            <div class="gap-4 max-w-lg mx-auto m-5 relative">
              <div v-if="!sequence.length">
                <div class="p-5 bg-gray-100 text-gray-700 text-center">
                  <p class="py-3">To get started add your first template</p>
                  <p class="text-center">
                    <el-button
                      :disabled="bulk_emailing_options.defaultEmailAccount === null"
                      @click="openAddTemplate(0)"
                      type="info">Add Template
                    </el-button>
                  </p>

                </div>
              </div>
              <div v-else>
                <div style="left: 50%"
                     class="w-1 h-full left-2 top-0  border-l-2 border-dashed border-gray-600 absolute"></div>
                <div
                  :key="i"
                  v-for="(s, i) in sequence">


                  <div
                    v-if="i !== 0"
                    class="h-12 mb-6 z-20 relative w-full flex justify-center items-end">
                    <el-button
                      @click="openAddTemplate(i)"
                      :disabled="sequence.length === 3"
                      type="success" size="mini">Add</el-button>
                  </div>
                  <div
                    v-if="i !== 0"
                    @click="() => openDelay(i, s.delay)"
                    class="w-20 z-20 p-2 mt-6 select-none hover:bg-gray-300 hover:shadow cursor-pointer bg-gray-200 rounded shadow relative p-1 mx-auto flex items-center justify-center">
                    <div class="text-xs uppercase text-center">
                      <i class="el-icon-alarm-clock"></i>
                      {{ s.delay }} Days
                    </div>
                  </div>
                  <div
                    v-if="i !== 0"
                    class="h-12 mb-6 z-20 relative w-full flex justify-center items-end">
                    <el-button
                      @click="openAddTemplate(i)"
                      :disabled="sequence.length === 3"
                      type="success" size="mini">Add</el-button>
                  </div>

                  <div class="bg-gray-100 border-blue-800 border relative rounded ">
                    <div class="pl-10 relative">
                      <div class="absolute top-0 left-0 h-10 flex items-center justify-center bg-blue-800 w-10">
                        <svg xmlns="http://www.w3.org/2000/svg"
                             class="w-6 h-6 text-white"
                             viewBox="0 0 24 24"><path fill="currentColor" d="M22 6c0-1.1-.9-2-2-2H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2zm-2 0l-8 5l-8-5zm0 12H4V8l8 5l8-5z"/></svg>
                      </div>
                      <div class="absolute top-0 right-0 mt-1 mr-2 h-10">
                        <div>
                          <!--
                          <el-button size="mini"
                                     @click="openEdit(i)"
                                     type="info" icon="el-icon-edit"></el-button>
                                     -->
                          <el-button size="mini"
                                     @click="confirmDelete(i)"
                                     type="danger" icon="el-icon-delete"></el-button>
                        </div>
                      </div>
                      <span class="text-sm h-10 block p-1 pl-2">
                       <span
                         v-if="i === 0"
                         class="block text-xs">Initial Email</span>
          <span
            v-if="i === 1"
            class="block text-xs">Second Initial Email</span>

                               <span
                                 v-if="i === 2"
                                 class="block text-xs">Third Initial Email</span>

           <span class="font-bold">{{ getSubject(s.subject) }}</span>
            </span>
                    </div>
                    <div class="p-4 text-xs">
                      <div class="bg-gray-100 break-normal	leading-5 p-2 text-xs" v-html="getBody(s.body)">
                      </div>
                    </div>
                  </div>

                  <div
                    v-if="sequence.length < 3 && i === (sequence.length - 1)"
                    class="h-12 mb-6 z-20 relative w-full flex justify-center items-end">
                    <el-button
                      @click="openAddTemplate(i)"
                      :disabled="sequence.length === 3"
                      type="success" size="mini">Add</el-button>
                  </div>

                </div>
              </div>
            </div>

          </el-tab-pane>
          <el-tab-pane
            :disabled="loading"
            label="Schedule" name="params">
            <el-form label-position="left" label-width="200px">
              <el-form-item label="Send on these days">
                <el-checkbox-group v-model="schedule.days">
                  <el-checkbox label="mon">Monday</el-checkbox>
                  <el-checkbox label="tues">Tuesday</el-checkbox>
                  <el-checkbox label="weds">Wednesday</el-checkbox>
                  <el-checkbox label="thurs">Thursday</el-checkbox>
                  <el-checkbox label="fri">Friday</el-checkbox>
                  <el-checkbox label="sat">Saturday</el-checkbox>
                  <el-checkbox label="sun">Sunday</el-checkbox>
                </el-checkbox-group>
              </el-form-item>
              <el-form-item label="Timezone">

                <el-select v-model="schedule.timezone" class="w-full" filterable>
                  <el-option
                    v-for="(e, i) in getTimezones" :key="'email_account_' + i"
                    :value="e.value"
                    :label="e.label"
                  />
                </el-select>

              </el-form-item>
              <el-form-item label="Start time of Day">
                <el-time-select
                  :clearable="false"
                  :picker-options="{
    start: '00:00',
    step: '00:30',
    end: '23:30'
  }"
                  v-model="schedule.start">
                </el-time-select>
              </el-form-item>
              <el-form-item label="End time of Day">
                <el-time-select
                  :clearable="false"
                  :picker-options="{
    start: '00:00',
    step: '00:30',
    end: '23:30'
  }"
                  v-model="schedule.end">
                </el-time-select>
              </el-form-item>
              <el-form-item label="Max emails per day">
                <el-input-number :min="1" v-model="schedule.max_per_day"/>
              </el-form-item>
            </el-form>
          </el-tab-pane>
        </el-tabs>




        <div class="p-6">

          <div v-if="active_step === 1">



            <!---
                <el-form-item label="Default template" prop="defaultTemplate">
              <el-select v-model="bulk_emailing_options.defaultTemplate" :disabled="!bulk_emailing_options.defaultEmailAccount" class="w-full" filterable>
                <el-option
                  v-for="(e, i) in allowedEmailTemplates" :key="'email_templates_' + i"
                  :value="e.id"
                  :label="e.name"
                />
              </el-select>
            </el-form-item>

            --->

            <p class="text-right">
              <el-button
                @click="active_step--">Previous</el-button>
              <el-button
                @click="active_step++"
                type="primary">Next</el-button>
            </p>
          </div>

          <div v-if="active_step === 2">



            <p class="text-right">
              <el-button
                @click="active_step--">Previous</el-button>
              <el-button
                @click="active_step++"
                type="primary">Start Process</el-button>
            </p>

            <!---
                <el-form-item label="Default template" prop="defaultTemplate">
              <el-select v-model="bulk_emailing_options.defaultTemplate" :disabled="!bulk_emailing_options.defaultEmailAccount" class="w-full" filterable>
                <el-option
                  v-for="(e, i) in allowedEmailTemplates" :key="'email_templates_' + i"
                  :value="e.id"
                  :label="e.name"
                />
              </el-select>
            </el-form-item>

            --->

          </div>

        </div >

      </div>


    </trieste-dialog>

    <trieste-dialog :show.sync="delay.show" width="500px" title="Edit Delay (Days)">
      <div class="p-4">
        <el-input-number :min="1" v-model="delay.days"/>
      </div>
      <template #footer-right>
        <el-button @click="() => delay.show = false">Cancel</el-button>
        <el-button
          @click="updateDelay()"
          :disabled="delay.days === null"
          type="primary">Update
        </el-button>
      </template>
    </trieste-dialog>
  </div>
</template>
<script>
import emailPlaceholders from "@/json/email_placeholders.json";
import EmailPlaceholder from "@/utils/EmailPlaceholder";
import timezoneList from "@/json/timezones.json";
import collect from "collect.js";

export default {
  props: {
    bulk_link: {
      type: Object
    },
    site_id: {
      type: [String, Number]
    },
  },
  data () {
    return {
      schedule: {
        days: ['mon', 'tues', 'weds', 'thurs', 'fri'],
        timezone: 'London',
        max_per_day: 60,
        start: '07:00',
        end: '19:00',
      },
      delay: {
        show: false,
        days: 3,
        index: 0
      },
      selected_tab: 'settings',
      template: {
        index: 0,
        editor_cursor: 0,
        show: false,
        editing: false,
        selected_placeholder: null,
        showIfStatementDialog: true,
        placeholders: emailPlaceholders,
        subject: null,
        body: null,
      },
      active_step: 0,
      loading: false,
      bulk_emailing_options: {
        active: false,
        loadedResources: false,
        defaultEmailAccount: null,
        defaultTemplate: null,
        defaultLinkType: null
      },
      email_accounts: [],
      email_templates: [],
      show_bulk_emailing_form: false,
      sequence: []
    }
  },
  computed: {
    getFormObject(){
      return {
        active: this.bulk_emailing_options.active ? this.bulk_emailing_options.active : false,
        email_account_id: this.bulk_emailing_options.defaultEmailAccount,
        default_link_type: this.bulk_emailing_options.defaultLinkType,
        enabled_mon: this.schedule.days.indexOf('mon') > -1,
        enable_tues: this.schedule.days.indexOf('tues') > -1,
        enable_weds: this.schedule.days.indexOf('weds') > -1,
        enable_thurs: this.schedule.days.indexOf('thurs') > -1,
        enable_fri: this.schedule.days.indexOf('fri') > -1,
        enable_sat: this.schedule.days.indexOf('sat') > -1,
        enable_sun: this.schedule.days.indexOf('sun') > -1,
        timezone: this.schedule.timezone,
        start_time_of_day: this.schedule.start,
        end_time_of_day: this.schedule.end,
        max_per_day: this.schedule.max_per_day,
        sequence: this.sequence
      }
    },
    allowedEmailTemplates() {
      if(this.bulk_emailing_options.defaultEmailAccount) {
        const emailAccount = this.email_accounts.find(e => e.id === this.bulk_emailing_options.defaultEmailAccount)
        if(Array.isArray(emailAccount.allowed_templates) && emailAccount.allowed_templates.length) {
          return this.email_templates.filter(et => emailAccount.allowed_templates.includes(et.id))
        }
      }
      return this.email_templates;
    },
    groupedEmailTemplates(){
      let items = this.allowedEmailTemplates.map((x) => {
        let name = x.name.split('] ');
        return Object.assign({
          group: name[0] + ']',
        }, x)
      });
      return collect(items).groupBy('group').map((x) => {
        return {
          label: collect(x).pluck('group').first(),
          options: collect(x).toArray()
        }
      }).toArray();

    }
  },
  methods: {
    openDelay(index, value) {
      this.delay.index = index;
      this.delay.days = value;
      this.delay.show = true
    },
    updateDelay() {
      this.sequence[this.delay.index].delay = this.delay.days;
      this.delay.show = false
    },
    confirmDelete(index) {
      let vm = this;
      this.$confirm('Are you sure to delete this item?')
        .then(_ => {
          if (index > -1) { // only splice array when item is found
            vm.sequence.splice(index, 1); // 2nd parameter means remove one item only
          }
        })
        .catch(_ => {
        });
    },
    getMappings() {
      let keys = emailPlaceholders.map((x) => x.code.replace('<<<', '').replace('>>>', ''));
      let values = emailPlaceholders.map((x) => x.default), object = {};
      for (let i = 0; i <= keys.length; i++) {
        object[keys[i]] = values[i]
      }
      return object
    },
    getTimezones() {
      return timezoneList;
    },
    update(){
      const vm = this;
      vm.loading = true;
      this.$http.put(`/v1/companies/${this.$store.state.company.company.id}/batches/${this.$route.params.batchId}/bulk-emailing`, this.getFormObject)
        .then(() => {
          this.$message.success('Updated Successfully')
          vm.loading = false;
          vm.show_bulk_emailing_form = false
          vm.$emit('update')
        }).catch(() => {
        this.$message.error('Update Failed')
        vm.loading = false;
      })
    },
    openAddTemplate(index) {
      this.template.index = index;
      this.template.show = true
    },
    getSubject(subject) {
      let placeholderParser = new EmailPlaceholder(this.getMappings())
      let content = placeholderParser.parseIfStatments(placeholderParser.replaceLineBreaks(subject))
      return placeholderParser.parse(content)
    },
    getBody(body) {
      let placeholderParser = new EmailPlaceholder(this.getMappings())
      let content = placeholderParser.parseIfStatments(placeholderParser.replaceLineBreaks(body))
      return placeholderParser.parse(content).replaceAll('<br/>', '\n').replace(/\n\s*\n/g, '\n')
        .replace(/\n/g, "<br />")
        .replaceAll('<br/><br/>', '<br />').replaceAll('<br/> <br/>', '<br />')
    },
    addSequence() {
      let index = this.template.index, vm = this;

      let f = this.email_templates.find((x) => x.id === vm.bulk_emailing_options.defaultTemplate);
      if (f?.subject) {

        this.sequence.splice(index + 1, 0, {
          subject: f.subject,
          body: f.body,
          delay: !this.sequence.length ? 0 : 3
        });

      }

      this.template.show = false;
    },
    reloadResources() {
      this.bulk_emailing_options.loadedResources = false
    },
    getResources(callback = () => {}) {
      console.log('get resoirces...')
      this.loading = true;
      const site_id = this.site_id || this.$store.state.site.site.id
      const requests = [
        this.$http.get(`/v1/companies/${this.$store.state.company.company.id}/batches/${this.$route.params.batchId}/bulk-emailing`),
        this.$http.get(`/v1/companies/${this.$store.state.company.company.id}/user/email-accounts`, {
          params: {
            all: true,
            include_allowed_templates: true,
            site_id
          }
        }),
        this.$http.get(`/v1/companies/${this.$store.state.company.company.id}/email-templates`, {
          params: {
            all: true,
            site_id,
            in_outreach_group: true
          }
        }),
      ]

      this.$http.all(requests).then(this.$http.spread((
        settingsRequest,
        emailAccountsRequest, EmailTemplatesRequest) => {
        this.email_accounts = emailAccountsRequest.data.data;
        this.email_templates = EmailTemplatesRequest.data.data;


        this.schedule.days = settingsRequest.data.data.schedule.days;
        this.schedule.start = settingsRequest.data.data.schedule.start;
        this.schedule.end = settingsRequest.data.data.schedule.end;
        this.schedule.max_per_day = settingsRequest.data.data.schedule.max_per_day;
        this.schedule.timezone = settingsRequest.data.data.schedule.timezone;
        this.bulk_emailing_options.defaultEmailAccount = settingsRequest.data.data.email_account_id;
        this.bulk_emailing_options.defaultLinkType = settingsRequest.data.data.default_type;
        this.bulk_emailing_options.active = settingsRequest.data.data.enabled;


        if(settingsRequest.data.data && settingsRequest.data.data.sequence){
          this.sequence = settingsRequest.data.data.sequence;
        }
        console.log('here')

      }))
        .finally(() => {
          this.loading = false;
          this.ready = true;
          callback()
        })
    },
    reset(){
      this.selected_tab ='settings';
      this.bulk_emailing_options = {
        active: false,
          loadedResources: false,
          defaultEmailAccount: null,
          defaultTemplate: null,
          defaultLinkType: null
      };
        this.schedule = {
        days: ['mon', 'tues', 'weds', 'thurs', 'fri'],
        timezone: 'London',
        max_per_day: 60,
        start: '07:00',
        end: '19:00',
      }
    },
    toggleBulkEmailingForm() {
      this.reset();
      if(!this.show_bulk_emailing_form && !this.bulk_emailing_options.loadedResources) {
        this.show_bulk_emailing_form = true
        return this.getResources(() => {
         // this.show_bulk_emailing_form = true
        })
      }

      this.show_bulk_emailing_form = !this.show_bulk_emailing_form
    },
    startBulkEmailing() {
      if (this.bulk_link) {
        this.$store.dispatch('batch/startBulkEmailing', this.bulk_emailing_options)
        const link = this.bulk_link
        this.$router.push(`/company/${this.$route.params.companyId}/leads/${link.id}`)
      }
    },
  }
}
</script>
