<template>
  <div class="border border-solid border-gray-200 rounded shadow-sm custom-datatable-1 mb-4 bg-white"
       v-loading="loading" ref="container">
    <div class="p-4 border-b border-gray-200 rounded-tl rounded-tr h-auto">
      <div class="flex flex-row justify-between items-center" v-if="showTitle">
        <div class="flex flex-row items-center">
          <span class="text-xl font-bold text-blue-800">{{ title }}</span>
          <div class="h-8 mt-1 border-r border-gray-400 mx-2" v-if="showSubTitle"></div>
          <span class="text-sm text-gray-500 mt-1" v-if="showSubTitle">{{
              pagination.total.toLocaleString()
            }} {{ itemType || title.toLowerCase() }}</span>
        </div>
        <div class="ml-auto">
          <slot name="header-right"></slot>
          <el-button
            v-if="collapsable"
            size="small"
            :icon="is_collapsed ? 'el-icon-arrow-down' : 'el-icon-arrow-up'"
            @click="updateCollapse"
          />
        </div>
      </div>
      <div class="mb-0 flex flex-row items-start" :class="{'my-4': showTitle}"
           v-if="filters.length || this.$slots['header-right']">
        <div class="w-full" v-if="filters.length">
          <trieste-table-filters :filters="filters" :default-filters="parsedDefaultFilterData" v-model="filter_data" :get-table-data="getData"
                                 :item-type="itemType" :ready="!loading" v-if="filters_ready" :store-filter-state="storeFilterState" :store-filter-state-name="savedTableSortName" />
        </div>
        <div class="ml-auto pl-4 flex flex-row" v-if="!showTitle && this.$slots['header-right']">
          <slot name="header-right"></slot>
        </div>
        <div class="pl-2 flex flex-row items-start">
          <el-badge is-dot class="item" :hidden="!fieldOrder || !fieldOrder.length">
            <el-button type="primary" size="medium" icon="el-icon-sort" @click="showCustomSortOptions = true" />
          </el-badge>
          <div class="ml-2">
          <el-select v-model="pagination.rpp" v-if="showPaginationOptions" @change="getData" size="small">
            <el-option
              v-for="item in paginationValues"
              :key="item"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
          </div>
        </div>
        <div class="w-20" v-if="showRppSelect">
            <el-select
              placeholder="Results per page"
              :disabled="loading"
              v-model='pagination.rpp'>
              <el-option
                :key="i"
                v-for="(x, i) of [20,50,75,100]"
                :label="x" :value="x"/>
            </el-select>
        </div>
      </div>
    </div>
    <div class="overflow-x-auto">
      <div v-if="data.length">
        <table class="trieste-custom-table w-full" v-if="$refs.container" :key="table_key">
          <colgroup>
            <col v-for="(width, ci) in colspan_sizes" :key="ci" :width="width"/>
          </colgroup>
          <thead>
          <tr>
            <th v-for="(field, fi) in sorted_fields" :key="fi">
              <div class="flex items-center">
                <slot :name="(field.name || field.title) + '-header'">
                  {{ field.title || '' }}
                  <div v-if="field.isSelectionField">
                    <el-checkbox v-model="check_all_selection" @change='checkAllRows' />
                  </div>
                  <div v-if="!!field.sortField" class="ml-2 flex flex-col text-base">
                    <div class="el-table">
                      <div
                        :class="{
                    'ascending': sort.field === field.sortField && sort.direction === 'ascending',
                    'descending': sort.field === field.sortField && sort.direction === 'descending'
                    }"
                      >
                    <span class="caret-wrapper">
                    <i class="sort-caret ascending"
                       @click="sortChanged({prop: field.sortField, order: 'ascending'})"></i>
                    <i class="sort-caret descending"
                       @click="sortChanged({prop: field.sortField, order: 'descending'})"></i>
                  </span>
                      </div>
                    </div>
                  </div>
                </slot>
              </div>
            </th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(row, ri) in showData" :key="ri" :class="getAdditionalRowClasses(row, ri)">
            <td v-for="(field, fi) in sorted_fields" :key="fi" :class="renderFieldTitleClasses(field, row)">
              <el-checkbox-group v-model="realSelectedRows" v-if="field.isSelectionField">
                <el-checkbox :label="ri" true-label="" false-label="" @change="updateParentSelectedRows">
                  <span></span>
                </el-checkbox>
              </el-checkbox-group>
              <el-popover
                placement="left"
                width="250"
                trigger="hover"
                v-else-if="field.isMoreField"
                popper-class="table-popover"
                :visible-arrow="false"
              >
                <div>
                  <ul>
                    <li v-for="(moreField, mfkey) in field.fields" :key="mfkey">
                      <div>
                        <span class="font-medium text-gray-500">{{ moreField.title || '' }}</span>
                      </div>
                      <div class="mb-4">
                        <slot :name="moreField.name" :row="row" :rowField="field" :$index="ri">
                          <template v-if="moreField.formatter && typeof moreField.formatter === 'function'">
                            {{ moreField.formatter(moreField.name ? row[moreField.name] : row, row) }}
                          </template>
                          <template v-else-if="moreField.dateField && row[moreField.name]">
                            <span class="font-bold">{{ row[moreField.name] | momentLocal("ddd MMMM DD YYYY") }}</span><br/>
                            <span class="text-xs">
                            {{ row[moreField.name] | momentLocal("hh:mm A Z") }}
                          </span>
                          </template>
                          <template v-else-if="moreField.booleanField">
                            {{ row[moreField.name] === true ? 'Yes' : 'No' }}
                          </template>
                          <template v-else-if="moreField.urlField">
                            <a :href="row[moreField.name]" class="font-bold">{{ row[moreField.name] }}</a>
                          </template>
                          <template v-else-if="moreField.numberField">
                            {{ (row[moreField.name] || 0).toLocaleString() }}
                          </template>
                          <template v-else-if="!row[moreField.name] && row[moreField.name] !== 0">
                            <span class="text-gray-400 text-xs">N/A</span>
                          </template>
                          <template v-else>
                            {{ row[moreField.name] | shortenText(100) }}
                          </template>
                        </slot>
                      </div>
                    </li>
                  </ul>
                </div>
                <div class="actions-box text-right" slot="reference" style="width: initial">
                  <a class="action" href="#" @click.prevent="openTab(link.from_url)">MORE</a>
                </div>
              </el-popover>
              <div class="whitespace-normal" v-else
                   :class="{
                'text-right': field.name === 'actions-slot',
                'break-words': !field.breakAll && field.name !== 'actions-slot',
                'break-all': !!field.breakAll || field.name === 'actions-slot'
              }">
                <slot :name="field.name" :row="row" :rowField="field" :$index="ri">
                  <template v-if="field.formatter && typeof field.formatter === 'function'">
                    <div v-html="field.formatter(field.name ? row[field.name] : row, row)"></div>
                  </template>
                  <template v-else-if="field.dateField && row[field.name]">
                    <span class="font-bold">{{ row[field.name] | momentLocal("ddd MMMM DD YYYY") }}</span><br/>
                    <span class="text-xs">
              {{ row[field.name] | momentLocal("hh:mm A Z") }}
            </span>
                  </template>
                  <template v-else-if="field.booleanField">
                    {{ row[field.name] === true ? 'Yes' : 'No' }}
                  </template>
                  <template v-else-if="field.urlField">
                    <a :href="row[field.name]" class="font-bold">{{ row[field.name] }}</a>
                  </template>
                  <template v-else-if="field.numberField">
                    {{ (row[field.name] || 0).toLocaleString() }}
                  </template>
                  <template v-else-if="!row[field.name] && row[field.name] !== 0">
                    <span class="text-gray-400 text-xs">N/A</span>
                  </template>
                  <template v-else>
                    {{ row[field.name] | shortenText(100) }}
                  </template>
                </slot>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="p-4 border-b border-solid border-gray-200 bg-gray-100 text-center" v-else>
        <span
          class="text-sm text-gray-700 font-medium">No {{ itemType ? itemType.toLowerCase() : 'data is' }} available</span>
      </div>
    </div>
    <div>
      <div class="columns" v-if="showPagination">
        <div class="column flex items-center p-4">
          <div class="p-4 text-sm text-gray-600">
            <span class="font-bold">{{ pagination.total }}</span> total {{ itemType }}.
            <span v-if="pagination.total > pagination.rpp">
                      Showing page <span class="font-bold">{{ pagination.page }}</span>
                      of <span class="font-bold">{{ Math.ceil(pagination.total / pagination.rpp) }}</span>
                      </span>
          </div>
        </div>
        <div class="column ml-auto p-4 flex items-center">
          <el-pagination
            v-if="pagination.total > pagination.rpp && (!collapsable || (collapsable && !is_collapsed))"
            @current-change="(p) => handlePageChange(p)"
            layout="prev, pager, next"
            :current-page.sync="pagination.page"
            :page-size="pagination.rpp"
            :total="pagination.total"/>
        </div>
      </div>
    </div>
    <div>
      <trieste-table-sort-columns
        v-model="fieldOrder"
        :fields="fields"
        v-if="allowSortFields"
        :show.sync="showCustomSortOptions"
        :store-name="savedTableSortName"
        @changed="sortFields"
      />
    </div>
  </div>
</template>
<script>
import TriesteTableFilters from "./TriesteTableFilters";
import TriesteTableSortColumns from "@/components/incs/TriesteTableSortColumns";
import DefaultFilterPhaser from '@/components/mixins/default_filters';
import {formatResponseErrorMessage} from "@/utils/helpers";

export default {
  components: {TriesteTableSortColumns, TriesteTableFilters},
  mixins: [DefaultFilterPhaser],
  props: {
    showRppSelect: {
      type: Boolean,
      default: false
    },
    endPoint: {
      type: String
    },
    fields: {
      type: Array,
      default: () => []
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    dataPath: {
      type: String,
      default: 'data'
    },
    title: {
      type: String,
      default: ''
    },
    itemType: {
      type: String,
      default: 'items'
    },
    rpp: {
      type: Number,
      default: 20
    },
    params: {
      type: Object,
      default: () => {
      }
    },
    defaultSortProp: {
      type: String,
      default: null
    },
    defaultSortDirection: {
      type: String,
      default: null
    },
    allowSelection: {
      type: Boolean,
      default: false
    },
    selectedRows: {
      type: [Object, Array],
      default: () => []
    },
    filters: {
      type: Array,
      default: () => []
    },
    showTitle: {
      type: Boolean,
      default: true
    },
    showPagination: {
      type: Boolean,
      default: true
    },
    fixedFieldSize: {
      type: Number,
      default: null
    },
    smallFieldSize: {
      type: Number,
      default: 4
    },
    mediumFieldSize: {
      type: Number,
      default: 5
    },
    largeFieldSize: {
      type: Number,
      default: 7
    },
    showPaginationOptions: {
      type: Boolean,
      default: false
    },
    paginationValues: {
      type: Array,
      default: () => [20, 50, 100, 200, 500]
    },
    maxFieldSize: {
      type: Number,
      default: 15
    },
    forceAllFields: {
      type: Boolean,
      default: false
    },
    allowSortFields: {
      type: Boolean,
      default: true
    },
    savedTableSortName: {
      type: String,
      default: null
    },
    cacheTableFilters: {
      type: Boolean,
      default: false
    },
    showSubTitle: {
      type: Boolean,
      default: true
    },
    collapsable: {
      type: Boolean,
      default: false
    },
    collapsed: {
      type: Boolean,
      default: true
    },
    firstFieldTotal: {
      type: Boolean,
      default: false
    },
    addPageToUrl: {
      type: Boolean,
      default: false
    },
    rowClassFunction: {
      type: Function,
      default: (item, index) => {
        return index % 2 === 1 ? 'grey' : null
      }
    },
    minColumnWidth: {
      type: Number,
      default: 130
    },
    storeFilterState: {
      type: Boolean,
      default: true
    },
    requiredFieldNames: {
      type: Array,
      default: () => []
    },
    defaultFilterData: {
      type: Object,
      default: () => {}
    },
    refreshOnParamChange: {
      type: Boolean,
      default: true
    },
    isPost: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      data: [],
      loading: false,
      is_collapsed: this.collapsed,
      sort: {
        field: this.defaultSortProp,
        direction: this.defaultSortDirection
      },
      realSelectedRows: this.selectedRows,
      pagination: {
        page: this.$route.query.page ? this.$route.query.page : 1,
        total: 0,
        rpp: this.rpp
      },
      filter_data: {},
      containerWidth: window.innerWidth,
      sizingGuide: {
        averageColSpanSize: null,
        allowedColSpan: null
      },
      showCustomSortOptions: false,
      table_key: 0,
      sorted_fields: [],
      checkWidthTimer: null,
      watchResizeTimer: null,
      fieldOrder: [],
      check_all_selection: false,
      parsedDefaultFilterData: {},
      filters_ready: false,
      getting_data: false,
      last_params: null,
      colspan_sizes: []
    }
  },
  computed: {
    getDefaultSort() {
      if (this.sort.field) {
        const sort = {
          prop: this.sort.field
        }
        if (this.sort.direction && ['asc', 'desc'].includes(this.sort.direction)) {
          sort.order = this.defaultSortDirection === 'asc' ? 'ascending' : 'descending';
        } else {
          sort.order = 'ascending'
        }
        return sort;
      }
      return {}
    },
    showData() {
      if(this.collapsable && this.is_collapsed) {
        if(this.firstFieldTotal) {
          return this.data.slice(0, 1)
        } else {
          return []
        }
      }
      return this.data
    }
  },
  mounted() {
    this.setContainerWidth()
    if (this.$refs.container && this.$refs.container.getBoundingClientRect().width > 0) {
      this.sortFields()
      this.watchWidth()
    } else {
      this.watchForDisplay()
    }
    this.last_params = JSON.stringify(this.params)
    this.filters.length ? this.tryParseDefaultFilters() : this.getData()
  },
  methods: {
    handlePageChange(p){
      if(this.addPageToUrl){
        console.log('heres',  this.$route.query)
        //this.$router.replace({ path: $route.path, query: { plan: 'private' } })
        //q.page = p;
        let query = {page: p};
        if(this.$route.query){
          for(let x in this.$route.query){
            console.log(x)
            if(x !== 'page'){
              query[x] = this.$route.query[x]
            }
          }
        }
          this.$router.replace({path: this.$route.path, query})
      }
      this.pagination.page = p;
      this.getData()
    },
    getFilterData() {
      return this.filter_data;
    },
    watchForDisplay() {
      if (this.$refs.container && this.$refs.container.getBoundingClientRect().width > 0) {
        if (this.checkWidthTimer) {
          clearTimeout(this.checkWidthTimer)
          this.checkWidthTimer = null
        }
        this.setContainerWidth()
        this.sortFields()
        this.watchWidth()
      } else {
        setTimeout(this.watchForDisplay, 500)
      }
    },
    getAdditionalRowClasses(row, index) {
      if(typeof this.rowClassFunction === 'function') {
        return this.rowClassFunction(row, index)
      }
      return false;
    },
    customSortFields(fields, fieldOrder) {
      fields.sort((a, b) => {
        const aKey = a.name ? fieldOrder.findIndex(v => v === a.name) : -1;
        const bKey = b.name ? fieldOrder.findIndex(v => v === b.name) : -1;
        if (aKey > bKey) {
          return 1;
        } else if (aKey < bKey) {
          return -1;
        }

        return 0;
      })
    },
    checkAllRows(value) {
      if(value) {
        this.realSelectedRows = this.data.map((value, key) => key)
      } else {
        this.realSelectedRows = []
      }
      this.updateParentSelectedRows()
    },
    getDbFieldNames() {
      return this.fields
        .filter(f => (f.name && !f.name.endsWith('-slot')) || f.fieldName || f.sortField)
        .map(f => f.name && !f.name.endsWith('-slot') ? f.name : (f.fieldName || f.sortField))
        .concat(...this.requiredFieldNames);
    },
    sortFields() {
      if(!this.containerWidth) {
        return
      }
      const sorted = []
      const fields = this.fields.map(f => Object.assign({}, f)).filter(field => {
        if(field.check_slot && !this.$scopedSlots[field.name || field.title]) {
          return false
        }

        if(typeof field.validate === 'function' && !field.validate()) {
          return false
        }

        return field
      })
      if (this.fieldOrder.length) {
        this.customSortFields(fields, this.fieldOrder)
      }
      if (this.allowSelection) {
        fields.unshift({
          isSelectionField: true,
          size: 'mini',
          width: '30px'
        })
      }

      let actionsField = null
      let actionsFieldKey = fields.findIndex(field => field.name === 'actions-slot')
      if (actionsFieldKey !== -1) {
        actionsField = fields.splice(actionsFieldKey, 1)[0]
      }

      let moreField = null

      if (!this.forceAllFields) {

        let maxElements = null;
        let maxColWidth = null;

        const minColWidth = this.minColumnWidth / 1 ? this.minColumnWidth : 130;
        const moreFieldWidth = 100;
        const actionBarWidth = actionsField && typeof actionsField.width === "string" && actionsField.width.slice(-2) === "px" ? actionsField.width.slice(0, -2) / 1 : 0
        const fixedFields = fields.filter(f => f.width && typeof f.width === "string" && f.width.slice(-2) === "px")
        const totalFixedFieldWithoutMore = fixedFields.reduce((v, f) => v + (f.width.slice(0, -2) / 1), 0) + actionBarWidth;
        const averageDynamicFieldWidth = ((this.containerWidth) - totalFixedFieldWithoutMore) / (fields.length - fixedFields.length);
        if (averageDynamicFieldWidth < minColWidth) {
          // nope- not good enough so we need to know the limit
          maxElements = 0;
          let maxWidth = this.containerWidth - actionBarWidth - moreFieldWidth;
          let currentWidth = 0;
          let fixedWidth = 0;
          let dynamicFieldCount = 0;
          for (const f of fields) {
            let isFixed = false
            if (f.width && typeof f.width === "string" && f.width.slice(-2) === "px") {
              currentWidth += (f.width.slice(0, -2) / 1)
              fixedWidth += (f.width.slice(0, -2) / 1)
              isFixed = true
            } else {
              currentWidth += minColWidth
            }
            if (currentWidth > maxWidth) {
              break;
            }
            maxElements++;
            if (!isFixed) {
              dynamicFieldCount++
            }
          }
          maxColWidth = ((maxWidth - fixedWidth) / dynamicFieldCount)
        }

        for (const f of fields) {
          if (f.width && typeof f.width === "string" && f.width.slice(-2) === "px") {
            continue;
          }

          f.width = (maxColWidth ? maxColWidth : averageDynamicFieldWidth) + 'px'
        }

        if (maxElements && maxColWidth) {
          moreField = {
            isMoreField: true,
            fields: fields.splice(maxElements),
            width: moreFieldWidth + 'px'
          }
        }
      }

      sorted.push(...fields)
      if (moreField) {
        sorted.push(moreField)
      }

      if (actionsField) {
        sorted.push(actionsField)
      }

      this.sorted_fields = sorted
      this.getColspanSizes()
    },
    watchWidth() {
      window.addEventListener('resize', () => {
        this.setContainerWidth()
        if (this.watchResizeTimer) {
          clearTimeout(this.watchResizeTimer)
          this.watchResizeTimer = null
        }
        this.watchResizeTimer = setTimeout(() => {
          this.sortFields()
          this.refreshTableKey()
          clearTimeout(this.watchResizeTimer)
          this.watchResizeTimer = null
        }, 200)
      })
    },
    refreshTableKey() {
      this.table_key++;
      clearTimeout(this.watchResizeTimer)
      this.watchResizeTimer = null
    },
    setContainerWidth() {
      if (this.$refs.container) {
        this.containerWidth = this.$refs.container.getBoundingClientRect().width
      }
    },
    getColspanSizes() {
      // First, work out the size of an action bar.
      this.colspan_sizes = this.sorted_fields.map(field => {
        const isFixedValue = typeof field.width === 'string' && field.width.slice(-2) === 'px';
        if (isFixedValue) {
          return field.width;
        }

        return 100;
        switch (field.size) {
          case 'mini':
            return '50%';
          case 'small':
            return '75%';
          case 'medium':
            return '125%';
          case 'large':
            return '150%';
          default:
            return '100%';
        }
      })
    },
    getData() {
      if((this.filters.length && !this.filters_ready) || this.loading) {
        return;
      }

      this.loading = true;
      this.$emit('loading', true)
      const params = Object.assign({}, {
        rpp: this.pagination.rpp,
        page: this.$route.query.page || this.pagination.page,
        sort: this.buildSort(),
        filter_params: Object.keys(this.filter_data).length > 0 ? this.filter_data : undefined,
        req_fields: this.getDbFieldNames()
      }, this.params);

      this.$http.request({
        method: this.isPost ? 'POST' : 'GET',
        params: this.isPost? {} : params,
        data: this.isPost  ? params : {},
        url: this.endPoint
      }).then(r => {
        this.data = r.data[this.dataPath]
        this.pagination.total = r.data.meta && r.data.meta.totalResult ? r.data.meta.totalResult : this.data.length
        this.$emit('on-load', r.data)
        this.$emit('loading', false)
      })
        .catch((e) => {
          let message = formatResponseErrorMessage(e, 'getData')
          this.$message.error(message)
          console.error(e.toJSON(), message)
        })
        .finally(() => {
          this.loading = false;
          this.setContainerWidth();
          this.sortFields()
          this.refreshTableKey();
          if(this.allowSelection) {
            this.check_all_selection = false
            this.checkAllRows(false)
          }
        })
    },
    sortChanged(a) {
      if (this.sort.field === a.prop && this.sort.direction === a.order) {
        this.sort.field = null;
        this.sort.direction = null;
      } else {
        this.sort.field = a.prop;
        this.sort.direction = a.order;
      }
      this.pagination.page = 1;
      this.getData()
    },
    buildSort() {
      if (this.sort.field && this.sort.direction) {
        return `${this.sort.field}|${this.sort.direction === 'ascending' ? 'asc' : 'desc'}`;
      }
      return null;
    },
    updateParentSelectedRows() {
      const selected = [];
      this.realSelectedRows.forEach(id => {
        selected.push(this.data[id]);
      })
      this.$emit('update:selected-rows', selected)
    },
    getColumnLabel(field) {
      return field.title || '';
    },
    clearSelection() {
      this.realSelectedRows = []
      this.$emit('update:selected-rows', [])
    },
    getTable() {
      return this.$refs.table
    },
    handleSelectionChange(val) {
      this.$emit('update:selected-rows', val)
    },
    updateCollapse() {
      this.is_collapsed = !this.is_collapsed
      this.$emit('update:collapsed', this.is_collapsed)
    },
    openTab(url) {
      window.open(url)
    },
    cacheFilters() {
      if(this.cacheTableFilters && this.savedTableSortName) {
        this.$store.dispatch('global/setTableFilter', [this.savedTableSortName, this.filter_data])
      }
    },
    tryParseDefaultFilters() {
      this.parsedDefaultFilterData = {...this.defaultFilterData}
      if(this.cacheTableFilters && this.savedTableSortName  && this.$store.state.global.saved_table_filters[this.savedTableSortName]) {
        Object.assign(this.parsedDefaultFilterData, this.$store.state.global.saved_table_filters[this.savedTableSortName])
      }
      if (this.$route.query && this.$route.query.filters) {
        try {
          const parsedFilters = JSON.parse(this.$route.query.filters)
          if(typeof parsedFilters === "object" && Object.keys(parsedFilters).length > 0) {
            this.parsedDefaultFilterData = {...parsedFilters}
          }
        } catch(e) {
          console.error(e)
        }
      }
      this.filters_ready = true;
    },
    renderFieldTitleClasses(field, row) {
      if(field.titleClass) {
        if(typeof field.titleClass === 'function') {
          return field.titleClass(row)
        }
        return field.titleClass
      }
      return null
    }
  },
  watch: {
    'pagination.rpp'(){
      this.pagination.page = 1
      this.getData()
      this.cacheFilters()
    },
    'params': {
      handler: function(v) {
        const last = JSON.stringify(v)
        if(last !== this.last_params && this.refreshOnParamChange) {
          this.getData()
          this.last_params = last
        }
      },
      deep: true
    },
    'filter_data': function (v) {
      this.pagination.page = 1
      this.getData()
      this.cacheFilters()
    },
    '$store.state.global.sidebarCollapsed': function () {
      setTimeout(() => {
        this.setContainerWidth();
        this.sortFields();
        this.refreshTableKey();
      }, 300)
    }
  }
}
</script>
<style lang="scss">
table.trieste-custom-table {
  @apply table-fixed w-full;

  thead {
    tr {
      th {
        @apply text-left;
      }
    }
  }

  tbody {
    tr {
      td {
        @apply text-left;
      }
    }
  }
}
.el-popover {
  &.table-popover {
    padding-left: 0;
    padding-right: 0;

    > div {
      max-height: 400px;
      overflow-y: auto;
      padding: 5px 10px;
    }
  }
}
</style>
