<template>
  <div v-loading="loading">
    <trieste-card title="Content Items">
      <template v-slot:header-right v-if="!content_items.length">
        <el-button
          type="primary"
          size="small"
          icon="el-icon-edit-outline"
          v-if="canEditContent"
          @click="attachItem">Attach</el-button>
        <el-button
          type="primary"
          size="small"
          icon="el-icon-edit-outline"
          v-if="canEditContent"
          @click="requestItem">Request</el-button>
      </template>
      <div class="">
        <div v-if="!content_items.length">
          <p class="text-sm text-gray-500 p-4">There are currently no content items attached to this site link.</p>
        </div>
        <div v-if="content_items.length">
          <el-table :data="content_items">
            <el-table-column label="ID" width=90>
              <template slot-scope="scope">
                {{ scope.row.id }}
              </template>
            </el-table-column>
            <el-table-column label="Content item">
              <template slot-scope="scope">
                {{ (scope.row.title && scope.row.title.length ? scope.row.title : 'untitled') | shortenText(50) }}
              </template>
            </el-table-column>
            <el-table-column label="Status" width="100">
              <template slot-scope="scope">
                {{ scope.row.status }}
              </template>
            </el-table-column>
            <el-table-column :width="canEditContent ? '210px' : '100px'" fixed="right">
              <template slot-scope="scope">
                <el-button
                  type="primary"
                  size="mini"
                  icon="el-icon-view"
                  @click="openContentTab(scope.row)">
                  View
                </el-button>
                <el-button
                  type="danger"
                  size="mini"
                  icon="el-icon-delete"
                  v-if="canEditContent"
                  @click="detachContent(scope.row.id)">
                  Detach
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </trieste-card>
    <request-content-widget :show.sync="show_create_content_dialog" @success="onComplete" :link-id="value.id"
                            :site-target-id="value.site_target_id" :project-id="value.project_id" />
  </div>
</template>
<script>
  import RequestContentWidget from "@/components/incs/RequestContentWidget";
  export default {
    props: {
      value: {
        type: Object,
        default: () => {},
        required: true
      }
    },
    components: { RequestContentWidget },
    data() {
      return {
        content_items: [],
        show_create_content_dialog: false,
        loaded_content_items: false,
        loading: false
      }
    },
    mounted() {
      this.loadContentItems()
    },
    computed: {
      canEditContent() {
        return this.$store.getters['auth/hasPermission']('other.lead.content.attach') ||
          (
            this.$store.getters['auth/hasPermission']('own.lead.content.attach') &&
            this.$store.state.auth.user.id === this.value.owner_id
          )
      }
    },
    methods: {
      openContentTab(content) {
        const url = `/company/${this.$route.params.companyId}/content/${content.id}`;
        window.open(url);
      },
      onComplete() {
        window.location.reload();
        //this.loadContentItems();
        //this.$emit('success')
      },
      loadContentItems() {
        this.loading = true;
        this.$http.get(`/v1/companies/${this.$store.state.company.company.id}/links/${this.value.id}/content`)
        .then(res => {
          this.content_items = res.data.data;
          this.loaded_content_items = true;
        })
        .catch(() => {
          this.$message.error('Failed to load the content items for the link')
        })
        .finally(() => {
          this.loading = false
        })
      },
      checkSiteAndTarget() {
        if(/*!this.value.project_id ||*/!this.value.site_target_id) {
          this.$message.error('Please set a site target for this lead before requesting a content item.')
          return false;
        }
        return this;
      },
      attachItem() {
        if(this.checkSiteAndTarget()) {
          this.$router.push(`/company/${this.$route.params.companyId}/content/attach/${this.value.id}`)
        }
      },
      requestItem() {
        if(this.checkSiteAndTarget()) {
          this.show_create_content_dialog = true
        }
      },
      detachContent(id) {
        this.$confirm('Are you sure you want to detach this content item from this lead?', 'Are you sure?', {
          confirmButtonClass: 'el-button--danger'
        })
          .then(() => {
            this.loading = true;
            this.$http.post(`/v1/companies/${this.$store.state.company.company.id}/content/${id}/detach`)
              .then(() => {
                this.loadContentItems();
                this.$message.success('Successfully detached the content item.');
              })
              .catch(() => {
                this.$message.error('Failed to detach to the content item.')
              })
              .finally(() => {
                this.loading = false;
              })
          }).catch(() => {})
      },
    }
  }
</script>
